import {FinanceActItemType} from "../types/FinanceActItemType";
import {FinanceActItemType as APIFinanceActItemType} from "../types/api/FinanceActItemType";
import PriceListItemService from "./PriceListItemService";

class FinanceActItemService {
  static makeFinanceActItemFromApi(actItem: APIFinanceActItemType): FinanceActItemType {
    return {
      id: actItem.id,
      number: actItem.number,
      name: actItem.name,
      count: actItem.count,
      price: actItem.price,
      priceItem: actItem.priceItem ? PriceListItemService.makePriceListItemFromApi(actItem.priceItem) : null
    } as FinanceActItemType;
  }
}

export default FinanceActItemService;
