/* eslint-disable max-len */
import React, {FC, useEffect, useState} from 'react';
import {Col, Collapse, Input, Row, Select, Table, Tabs, Typography,} from 'antd';
import '../../assets/print.scss'
import {useParams} from "react-router-dom";
import {ContractType, emptyContract} from "../../types/ContractType";
import ContractService from "../../api/ContractService";
import ClientsService from "../../api/ClientsService";
import {PriceListItem} from "../../types/PriceListItem";
import PriceListService from "../../api/PriceListService";
import {priceColumns} from "./Columns";
import {emptyPriceList, PriceList} from "../../types/PriceList";
import PriceListItemService from "../../api/PriceListItemService";

const {Panel} = Collapse;
const {TextArea} = Input;
const {Option} = Select;
const {TabPane} = Tabs;

const {Title, Text, Paragraph} = Typography;

const ContractPrint: FC = () => {
  const {id} = useParams<any>();
  const [contract, setContract] = useState<ContractType>(emptyContract);
  const [price, setPrice] = useState<PriceList>(emptyPriceList);
  const [priceListItems, setPriceListItems] = useState<PriceListItem[]>([]);

  const loadData = async () => {
    const contract = ContractService.getById(id)
    const pricesAsync = PriceListService.getAll()

    setContract(await contract)

    const prices = await pricesAsync
    setPrice(prices[0])
    setPriceListItems(prices[0].items)
    window.print()
  }

  useEffect(() => {
    loadData()
  }, [id]);

  return (
    <div className={'print-page'}>
      <Title level={5} style={{textAlign: "center"}}>
        {`ДОГОВОР № ${contract.year}/${contract.number}`}
      </Title>
      <Row>
        <Col span={4}>г. Москва</Col>
        <Col span={4} offset={16} style={{textAlign: "right"}}>{contract.signedAt.format('DD.MM.yyyy')}</Col>
      </Row>
      <Text>
        Общество с ограниченной ответственностью «Научно-исследовательская независимая экспертиза имени
        П.М.Третьякова»,
        именуемое в дальнейшем «Исполнитель», в лице административного директора Попова А.Г., действующего на
        основании Доверенности,
        с одной стороны, {ClientsService.getClientName(contract.client)} именуемый в дальнейшем «Заказчик», совместно
        именуемые «Стороны», заключили настоящий Договор (далее «Договор»)
        о нижеследующем:
      </Text>
      <Title level={5}>
        1. ПРЕДМЕТ ДОГОВОРА
      </Title>
      <div>
        1.1. Исполнитель предоставляет Заказчику услуги по проведению экспертизы/исследований произведения искусства
        (далее-Произведение), а Заказчик оплачивает их по тарифам, утверждённым Исполнителем для данного договора,
        указанным в Приложении No1, являющемся неотъемлемой частью настоящего Договора.
      </div>
      <div>
        1.2. Исполнитель обеспечивает выполнение в рамках данного договора состава услуг, определяемого в соответствии
        с мнением эксперта:
      </div>
      <div>
        1.2.1. фотофиксация, макрофотосъемка в видимом спектре, ультрафиолетовом и инфракрасном излучении,
        рентгенографирование, сравнение рентгенограмм, микрохимический анализ состава наполнителя грунта и пигментов,
        рентгенофлуорисцентный анализ элементного состава, исследование красочной поверхности в микроскоп,
        исследование характера нанесения подписи, исследование основы, анализ полученных данных, сравнение с
        эталонными произведениями , библиографическое исследование, запросы материалов и сведений в музеях и архивах
        составление экспертного заключения и/или технологического отчета экспертом,
      </div>
      <div>
        .3. Произведения на исследование принимаются по Акту приёма, который составляется в двух экземплярах и
        подписывается обеими сторонами в момент передачи Произведения Исполнителю.
      </div>
      <Title level={5}>
        2. ПРАВА И ОБЯЗАННОСТИ СТОРОН
      </Title>
      <div>
        2.1. Исполнитель определяет и оказывает виды услуг: включая, но не ограничиваясь искусствоведческой,
        технико-технологической экспертизами. При необходимости Исполнитель принимает решение об оказании
        дополнительных услуг (химический анализ, привлечение соисполнителей, привлечение дополнительных материалов и
        т.д.). В таком случае Исполнитель ставит Заказчика в известность об оказываемых услугах и согласовывает с ним
        стоимость и объем дополнительно оказываемых услуг.
      </div>
      <div>
        2.2. В момент поступления Произведений от Заказчика Исполнителю по месту нахождения Исполнителя для оказания
        услуг и при возврате Произведений Заказчику, стороны оформляют (подписывают) Акт приема и Акт выдачи
        Произведений в соответствии с установленным Исполнителем порядком ( поступление произведений и их возврат
        регистрируются в «Системе учета поступлений и выдачи Произведений») , стороны фиксируют состояние
        полученных/возвращенных исследуемых Произведений: наличие/отсутствие замечаний к их состоянию.
      </div>
      <div>
        2.3. Исполнитель имеет право отказаться от оказания услуг на любом этапе в следующих случаях:
      </div>
      <div>
        - отсутствие или невозможность получения эталонного материала, необходимого для оказания услуг по
        Произведениям, представленным Заказчиком;
      </div>
      <div>
        - произведение не представляет художественной ценности;
      </div>
      <div>
        - плохая сохранность Произведения или наличия на нем значительных реставрационных записей, что может быть
        выявлено в процессе оказания услуг;
      </div>
      <div>
        - оказание давления на экспертов и Исполнителя со стороны владельцев Произведения или третьих лиц;
      </div>
      <div>
        - по усмотрению Исполнителя.
      </div>
      <div>
        2.5. Исполнитель, а также эксперты, проводящие исследования, (в соответствии с нормами законодательства РФ об
        авторском праве) имеют авторское право на публикацию материалов и результатов исследований Произведений,
        переданных Заказчиком.
        Заказчик не вправе публиковать результаты проведённых исследований, а также выдержки из них, без указания на
        авторство Исполнителя/эксперта. Заказчикне вправе публиковать результаты проведённых исследований, а также
        выдержки из них в контексте, который порочит честь, достоинство, профессиональную и деловую репутацию эксперта
        и/или Исполнителя.
        Эксперты/Исполнитель вправе публиковать результаты проведённых исследований, а также выдержки из них, без
        письменного разрешения Заказчика. При публикации результатов проведённых исследований, а также выдержек из
        них, Эксперты/Исполнитель не имеют права указывать личные данные Заказчика.
      </div>
      <div>
        2.6. Заказчик вправе в любое время отказаться от услуг Исполнителя и получить переданное Произведение после
        подписания Акта об оказании услуг по проведению экспертизы, а также оплаты суммы, соразмерной стоимости
        оказанных услуг, либо предъявления Исполнителю документа о произведенной оплате всего объема оказанных
        Исполнителем услуг. В этом случае Заказчик получает от Исполнителятолько Акт оказанных услуг.
      </div>
      <div>
        2.8. Заказчик обязуется подписать Акт об оказании услуг по получению его от Исполнителя.
      </div>
      <div>
        2.9. Заказчик обязуется своевременно оплатить оказанные услуги в соответствии с разделом 3 Договора.
      </div>
      <div>
        2.10. При наличии разногласий между Экспертами, Исполнитель может вынести обсуждение результатов услуг на
        Совет экспертов, состоящий не менее, чем из 4 (четырех) специалистов (Экспертов). Состав Совета экспертов
        определяет Исполнитель. При этом, в каждом конкретном случае, специалисту (Эксперту) произведение будет
        представлено индивидуально во избежание влияния мнений других членов Совета экспертов. Исполнитель вправе
        вынести решение не выдавать однозначный письменный итог (экспертное заключение). Оплата такой услуги
        осуществляется Заказчиком по утверждённым тарифам (Приложение No1) .
      </div>
      <div>
        2.11. Заказчик вправе запросить созыв Совета экспертов по обсуждению результатов, по переданному им
        Исполнителю Произведению. В таком случае Заказчик должен оплатить такую услугу Исполнителя по утверждённым
        тарифам.
      </div>
      <div>
        2.12. Исполнитель не несет какой-либо ответственности за ошибки, допущенные экспертом, связанные с его
        профессиональным мнением.
      </div>
      <div>
        2.13. Заказчик вправе выбрать оказание услуги несколькими экспертами и оплатить
        услугу по утверждённым тарифам (Приложение No1). Количество экспертов не ограничивается.
      </div>
      <Title level={5}>
        3. ПОРЯДОК РАСЧЕТОВ
      </Title>
      <div>
        3.1. Заказчик вносит аванс при передаче Произведения Исполнителю в размере, утверждённом Исполнителем
        (Приложение No1). Аванс учитывается при окончательной оплате Заказчиком оказанных услуг.
      </div>
      <div>
        3.2. Оплата может быть произведена Заказчиком, на основании представленного Исполнителем счета, путем
        перечисления денежных средств на счет Исполнителя не позднее трех банковских дней с момента выставления счета,
        по ценам, утверждённым Исполнителем (Приложение No1). Банковские расходы осуществляются за счет Заказчика.
        Оплата может быть осуществлена наличными денежными средствами в кассу Исполнителя в соответствии с действующим
        законодательством РФ при наличии подписанного обеими Сторонами Акта об оказании услуг Исполнителем.
      </div>
      <div>
        3.3. В течение 5-ти рабочих дней после окончания оказания услуг Исполнитель по телефону и/или иными средствами
        электронной связи уведомляет об этом Заказчика и передает ему для подписания Акт об оказании услуг и
        выставляет счет на оплату услуг. Заказчик обязан получить и подписать указанные документы в течение 5-ти дней
        с момента уведомления. Датой окончания оказания услуг стороны договорились считать дату выставления
        Исполнителем Заказчику Акта об оказании услуг, с указанной суммой оплаты.
      </div>
      <div>
        3.4. При перечислении средств на счет Исполнителя в графе «Назначение платежа» следует указывать следующую
        информацию: «Оплата за оказание услуг в соответствии с Договором № {contract.year}/{contract.number}.
        от {contract.signedAt.format('DD.MM.yyyy')}.
      </div>
      <div>
        3.5. После подписания Акта об оказании услуг и оплаты Заказчиком оказанных услуг, Исполнитель в трехдневный
        срок выдает Заказчику письменный результат оказанных услуг и переданные Исполнителю на оказание услуг
        произведения искусства (в случае их передачи Исполнителю).
      </div>
      <Title level={5}>
        4. СРОК ОКАЗАНИЯ УСЛУГ
      </Title>
      <div>
        4.1.Срок оказания услуг по проведению научно-исследовательской экспертизы составляет от двух недель и более в
        зависимости от сложности проводимого исследования.
      </div>
      <div>
        4.2. Срочное проведение научно-исследовательской экспертизы проводится по согласованию с Заказчиком в течение
        7 рабочих дней, по утвержденным у Исполнителя тарифам.
      </div>
      <div>
        4.3. При проведении сложных исследований, требующих сб ора дополнительной информации и/или проведения
        дополнительных исследований, срок оказания услуг может быть увеличен.
      </div>
      <div>
        4.4 Экспертиза обязуется предпринимать все возможные усилия для оптимизации сроков исполнения обязательств по
        договору.
      </div>
      <Title level={5}>
        5. ОТВЕТСТВЕННОСТЬ СТОРОН
      </Title>
      <div>
        5.1 Исполнитель несет материальную ответственность за повреждение и/или утрату произошедшую по вине
        Исполнителя переданного ей для оказания услуг Произведения.
      </div>
      <div>
        5.2 Исполнитель обязуется принять для сохранения, переданного ей Произведения меры, которые предусмотрены
        законом, иными правовыми актами (противопожарные, санитарные, охранные и т.п.).
      </div>
      <div>
        5.3 Исполнитель обязуется принять следующие дополнительные меры для того, чтобы обеспечить сохранность
        переданного ей Произведения:
      </div>
      <div>
        5.3.1 Размещать Произведения только в специализированном хранилище, где установлены исправные системы охранной
        сигнализации, пожарной сигнализации.
      </div>
      <div>
        5.3.2 Изымать Произведения из специализированного хранилища только для целей оказания услуг;
      </div>
      <div>x
        5.3.3 Не выносить Произведения за пределы офисного помещения, кроме случаев, когда отступление от этого
        требования диктуется необходимостью обеспечения сохранности или целостности Произведения;
      </div>
      <div>
        5.3.4 На территории офисного помещения не оставлять произведения без присмотра ответственных сотрудников
        (кроме времени, когда Произведения помещены в специализированный запасник);
      </div>
      <div>
        5.3.5 Проявлять в своих действиях при обращении с Произведениями и при их хранении ту же степень аккуратности,
        осторожности и предусмотрительности, какие Исполнитель проявляет по отношению к собственному имуществу того же
        рода, а также требовать аналогичного обращения с Произведениями от постоянных и временно нанятых сотрудников и
        иного персонала, имеющего (получающего временный) доступ к Произведениям.
      </div>
      <div>
        5.4. За задержку оплаты оказанных услуг по Договору, заказчик выплачивает Исполнителю штраф в размере 0.1% от
        просроченной суммы за каждый день просрочки, а также 200 рублей в календарный день за хранение каждого
        Произведения, через месяц после завершения оказания услуг.
      </div>
      <div>x
        5.5. Споры, которые могут возникнуть между Сторонами в ходе исполнения Договора, подлежат урегулированию путем
        переговоров. В случае если при переговорах не будет достигнуто согласие, споры рассматриваются в судебном
        порядке в соответствии с законодательством РФ, с подсудностью спора - по местонахождению Исполнителя.
      </div>
      <Title level={5}>
        6. ФОРС-МАЖОР
      </Title>
      <div>
        6.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему
        Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы. Под обстоятельствами
        непреодолимой силы понимаются обстоятельства, возникшие после заключения настоящего Договора в результате
        событий чрезвычайного характера, которые сторона не могла ни предвидеть, ни предотвратить разумными мерами,
        как-то: стихийные бедствия, пожары, наводнения, землетрясения, военные действия, забастовки, гражданские
        беспорядки, нетрудоспособность (болезнь и пр.) эксперта, изменения в законодательстве Российской Федерации.
      </div>
      <Title level={5}>
        7. ПРОЧИЕ УСЛОВИЯ
      </Title>
      <div>
        7.1. Договор вступает в силу с момента его подписания Сторонами и действует до ______г.
      </div>
      <div>
        7.2. Окончание срока действия Договора не освобождает стороны от выполнения взятых обязательств по ранее
        принятым произведениям, если Договор не был расторгнут в одностороннем порядке.
      </div>
      <div>
        7.3. Договор может быть расторгнут любой из Сторон в одностороннем порядке без объяснения причин при
        письменном
        предупреждении другой Стороны за 5 дней до даты предполагаемого расторжения. При этом стороны должны
        произвести
        взаиморасчеты на основании Акта об оказании услуг, подписанного с обеих сторон.
      </div>
      <div>
        7.4. Все дополнения и изменения к Договору действительны только при условии, что совершены в письменной форме
        при обоюдном согласии Сторон.
      </div>
      <div>
        7.5. Настоящий Договор составлен в двух экземплярах, имеющих одинаковую юридическую силу, по одному для каждой
        из Сторон.
      </div>
      <Title level={5}>
        8. ЮРИДИЧЕСКИЕ АДРЕСА И РЕКВИЗИТЫ СТОРОН
      </Title>
      <Row>
        <Col span={10}>
          <div>«Исполнитель»</div>
          <div>ООО «НИНЭ им. П.М.Третьякова»</div>
          <div>Юр.адрес : 119017, Москва,</div>
          <div>Большой Толмачевский пер, 16</div>
          <div>Факт.адрес : 119017 Москва,</div>
          <div>Большой Толмачевский пер.,16</div>
          <div>ОГРН 5087746058978</div>
          <div>ИНН / КПП 7706697241/770601001</div>
          <div>р/сч. 40702810140020001933</div>
          <div>Сбербанк России ОАОг.Москва</div>
          <div>БИК 044525225, ИНН/КПП 7707083893/775002002</div>
          <div>Телефон: (495) 953 00 53, (495) 953 74 93</div>
          <div style={{width: '100%', borderBottom: "black 1px solid"}}>&nbsp;</div>
          <div>Административный директор</div>
          <div>Попов А.Г.</div>
        </Col>
        <Col span={10} offset={4}>
          <div>«Заказчик»</div>
          <div>Фамилия: {contract.client.surname}</div>
          <div>Имя: {contract.client.name} {contract.client.patronymic}</div>
          <div>Паспортные данные: {contract.client.passportSeries} {contract.client.passportNumber}</div>
          <div>Выдан: {contract.client.passportIssueBy}</div>
          <div>Дата выдачи: {contract.client.passportIssueDate?.format("DD.MM.YYYY")}</div>
          <div>Адрес: {[contract.client.addressCountry, contract.client.addressCity, contract.client.address].join(", ")}</div>
          <div>Телефон: {contract.client.phoneNumber}</div>
          <div>{ClientsService.getClientName(contract.client)}</div>
          <div style={{width: '100%', borderBottom: "black 1px solid"}}>&nbsp;</div>
        </Col>
      </Row>
      <div className={"page-break"}></div>
      <Title level={5} style={{textAlign: "center"}}>
        Приложение No 1
      </Title>
      <Row>
        <Col span={4}>к Договору № {contract.year}/{contract.number}</Col>
        <Col span={4} offset={16} style={{textAlign: "right"}}>от {contract.signedAt.format('DD.MM.yyyy')}</Col>
      </Row>
      <div style={{textAlign: "center"}}>
        <b>ТАРИФЫ на {price.date.format("YYYY")} год</b>
      </div>
      <div style={{textAlign: "center"}}>
        <b>на проведение научно-исследовательских работ по произведениям искусства,</b>
      </div>
      <div style={{textAlign: "center"}}>
        <b>принадлежащим юридическим и физическим лицам</b>
      </div>
      <Table
        style={{marginTop: "10px", marginBottom: "10px"}}
        className={"print-table print-table-contract"}
        columns={priceColumns}
        showHeader={false}
        dataSource={priceListItems}
        pagination={false}
      />
      <Row>
        <Col span={10}>
          <div>Административный директор</div>
          <div>Попов А.Г.</div>
        </Col>
        <Col span={10} offset={4}>
          <div>Заказчик</div>
          <div>{ClientsService.getClientName(contract.client)}</div>
        </Col>
      </Row>
    </div>
  )
};

export default ContractPrint;
