import React from 'react';
import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import {ArtObject, ArtObjectType} from "../../types/ArtObject";
import {Link} from "react-router-dom";
import {authorsDemo, typesDemo} from "../../__mocks__/CommonsMock";
import {PriceListItem} from "../../types/PriceListItem";
import {Input} from "antd";

export const contractsSearchFields = [
  {name: 'number', label: 'Номер договора', type: 'number'},
  {name: 'clientSurname', label: 'Фамилия клиента', type: 'text'},
  {},
]

export const contractColumns = [
  {
    title: 'Год договора',
    dataIndex: 'year',
  },
  {
    title: 'Номер договора',
    dataIndex: 'number',
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    render: (client: any) =>
      `${client.surname} ${client.name[0]}. ${client.patronymic ? `${client.patronymic[0]}.` : ''}`,
  },
];

const authorTransform = (author: ArtObjectAuthor|null) => {
  return `${author?.name}`;
};

export const objectColumns = [
  {
    title: 'Объект',
    dataIndex: 'number',
    render: (number: string | number, artObject: ArtObject) => {
      return <Link target="_blank" to={`/object/${artObject.id}`}>{number}</Link>;
    },
    editable: true,
    type: 'number',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    editable: true,
    type: 'select',
    options: Object.keys(ArtObjectType),
    render: (type: string | number, author: ArtObjectAuthor|null) => {
      // @ts-ignore
      return ArtObjectType[type].toString()
    },
    optionRender: (type: string | number, object: ArtObject) => {
      // @ts-ignore
      return ArtObjectType[type].toString()
    },
  },
  {
    title: 'Автор',
    dataIndex: 'author',
    render: (author: ArtObjectAuthor|null) => authorTransform(author),
    editable: true,
    type: 'select',
    options: authorsDemo.map((author: any) => JSON.stringify(author)),
    optionRender: (author: any) => authorTransform(author),
  },
  {
    title: 'Наименование Объекта',
    dataIndex: 'name',
    editable: true,
    type: 'text',
  },
];

export const selectObjectColumns = [
  {name: 'number', label: 'Номер объекта', type: 'number'},
]


export const selectPriceListItemColumns = [
  {
    title: 'Порядковый номер',
    dataIndex: 'number',
    editable: false,
    type: 'text'
  },
  {
    title: 'Название',
    dataIndex: 'name',
    editable: false,
    type: 'text'
  },
  {
    title: 'Минимальная стоимость',
    dataIndex: 'minPrice',
    editable: false,
    type: 'text'
  },
  {
    title: 'Максимальная стоимость',
    dataIndex: 'maxPrice',
    editable: false,
    type: 'text'
  }
];

export const priceListItemColumns = [
  {
    title: 'Порядковый номер',
    dataIndex: 'number',
    editable: false,
    type: 'text'
  },
  {
    title: 'Тариф',
    dataIndex: 'name',
    editable: false,
    type: 'text'
  }
];
