import React, {FC, useEffect, useState} from 'react';
import {Table, Modal, Form} from 'antd';
import Search from '../Search';
import {defaultPagination, PaginationSettings} from "../../types/PaginationSettings";

const SelectedModalTable: FC<any> = ({
  modalTitle = '',
  type = 'checkbox',
  okButtonText = 'Добавить',

  modalTableColumns,
  modalTableData,
  isModalVisible,
  setIsModalVisible,

  selectedRows,
  selectedRowKeys,
  setSelectedRows,
  setSelectedRowKeys,

  setData,

  searchFields,
  searchAction,
}) => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState<PaginationSettings>({
    pageNumber: modalTableData.page.page - 1,
    size: modalTableData.page.pageSize,
    total: modalTableData.page.total,
  });

  const [searchParams, setSearchParams] = useState<any>({});
  const innerSearchAction = (params: any) => {setSearchParams(params)};

  useEffect(() => {
    searchAction(searchParams, pagination)
  }, [searchParams, pagination]);

  const onPaginationChange = async (page: number, size?: number) => {
    setPagination({
      pageNumber: page - 1,
      size: size ?? pagination.size,
      total: pagination.total,
    });
  };

  const onModalCancel = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setIsModalVisible(false);
  };

  const onModalOk = () => {
    setData((prevState: any) => Array.from(new Set([...selectedRows, ...prevState])));
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={modalTitle}
        open={isModalVisible}
        okText={okButtonText}
        cancelText='Отменить'
        onOk={onModalOk}
        onCancel={onModalCancel}
        width={1000}>
        {searchFields.length > 0 && <Search fields={searchFields} action={innerSearchAction} form={form}/>}
        <Table
          rowKey='id'
          columns={modalTableColumns}
          dataSource={modalTableData.items}
          pagination={{
            pageSize: modalTableData.page.pageSize,
            current: modalTableData.page.number,
            total: modalTableData.page.total,
            onChange: onPaginationChange,
          }}
          rowSelection={{
            type,
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRows(selectedRows);
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
        />
      </Modal>
    </>
  );
};

export default SelectedModalTable;
