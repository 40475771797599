import Api from '../utils/Api';
import { ClientsListResponse } from '../types/api/ClientType';
import { ClientType as APIClientType } from '../types/api/ClientType';
import { ClientType } from '../types/ClientType';
import moment from 'moment';
import LocalStorage from "../utils/LocalStorage";
import {STORE_USER_TOKEN} from "../constants";
import {AxiosResponse} from "axios";
import {ArtObject as APIArtObject} from "../types/api/ArtObject";
import {PagedList} from "../types/PagedList";
import {ArtObject} from "../types/ArtObject";
import {PaginationSettings} from "../types/PaginationSettings";
import UserService from "./UserService";

class ClientsService {
  private static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj)
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  static async getAll(searchParams: any,  pagination: PaginationSettings): Promise<PagedList<ClientType>> {
    return Api.call(
      '/client?' + ClientsService.serialize(searchParams) + `&page=${pagination.pageNumber}&size=${pagination.size}&`,
      {
        method: 'GET',
      },
    ).then((response: AxiosResponse<ClientsListResponse>) => {
        return {
          items: response.data.content.map<ClientType>((clientResponse) =>
            ClientsService.makeClientFromApi(clientResponse)
          ),
          page: {
            page: response.data.number + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          },
        } as PagedList<ClientType>;
      }
    );
  }

  static async getById(id: string): Promise<ClientType> {
    return Api.call('/client/' + id, {
      method: 'GET',
    }).then((response) => {
      return ClientsService.parseExtended(response.data)
    });
  }

  static async update(client: ClientType): Promise<ClientType> {
    return Api.call(`/client/${client.id}`, {
      data: {...client, createdBy: client?.createdBy?.id, modifiedBy: client?.modifiedBy?.id},
      method: 'PUT',
    }).then((response) => {
      return ClientsService.parseExtended(response.data)
    });
  }

  private static parseExtended(data: any): ClientType {
    const clientType = ClientsService.makeClientFromApi(data);
    return {
      ...clientType,
      createdBy: (data.createdBy ? UserService.makeUserFromApi(data.createdBy) : undefined),
      modifiedBy: (data.modifiedBy ? UserService.makeUserFromApi(data.modifiedBy) : undefined)
    };
  }

  static async add(client: ClientType): Promise<ClientType> {
    return Api.call(`/client`, {
      data: {...client, createdBy: client?.createdBy?.id, modifiedBy: client?.modifiedBy?.id},
      method: 'POST',
    }).then((response) => ClientsService.makeClientFromApi(response.data));
  }

  static makeClientFromApi(client: APIClientType): ClientType {
    return {
      id: client.id,
      surname: client.surname,
      name: client.name,
      patronymic: client.patronymic,
      phoneNumber: client.phoneNumber,
      email: client.email,
      isCompany: client.isCompany,
      addressCountry: client.addressCountry,
      addressCity: client.addressCity,
      address: client.address,
      passportType: client.passportType,
      passportSeries: client.passportSeries,
      passportNumber: client.passportNumber,
      passportIssueDate: moment(client.passportIssueDate),
      passportIssueBy: client.passportIssueBy,
      passportIssueCode: client.passportIssueCode,
      companyName: client.companyName,
      companyDetails: client.companyDetails,
      companyPersonTitle: client.companyPersonTitle,
      companyPersonTitle2: client.companyPersonTitle2,
      misc: client.misc,
      paymentDetails: client.paymentDetails,
      modifiedAt: moment(client.modifiedAt),
      createdAt: moment(client.createdAt),
    } as ClientType;
  }

  static getClientName(client: ClientType): string {
    const nameSymbol = client.name ? `${client.name[0]}.` : '';
    const surnameSymbol = client.patronymic ? `${client.patronymic[0]}.` : '';
    return `${client.surname} ${nameSymbol} ${surnameSymbol}`
  }
}

export default ClientsService;
