import {NotificationApi} from "antd/lib/notification";
import {AxiosError, AxiosResponse} from "axios";
import {notification} from "antd";

class Notifications {
  constructor(private notification: NotificationApi) {
  }

  notifyError(error: Error) {
    const axiosResponse = (error as AxiosError)?.response;
    const axiosStatus = axiosResponse?.status;
    if (axiosStatus === 403) {
      this.notification['error']({
        message: 'Ошибка доступа',
        description: 'Недостаточно прав для просмотра страницы',
      });
      return
    } else if (axiosStatus === 409) {
      if (axiosResponse?.data?.category == 'ArtObject') {
        if (this.objectErrors(axiosResponse)) {
          return
        }
      }
      if (axiosResponse?.data?.category == 'ReceivingAct') {
        if (this.receivingActErrors(axiosResponse)) {
          return
        }
      }
      if (axiosResponse?.data?.category == 'GivingAct') {
        if (this.givingActErrors(axiosResponse)) {
          return
        }
      }
      if (axiosResponse?.data?.category == 'FinanceAct') {
        if (this.financeActErrors(axiosResponse)) {
          return
        }
      }
    }

    this.notification['error']({
      message: 'Ошибка',
      description: 'Не удалось загрузить данные',
    });
  }

  private objectErrors(axiosResponse: AxiosResponse): boolean {
    if (axiosResponse?.data?.code === 'EXIST_REFERENCES') {
      this.notification['warn']({
        message: 'Ошибка при удалении',
        description: 'Необходимо удалить все связи в объекте а также удалить все ссылки в актах на объект',
      });

      return true
    }

    return false
  }

  private receivingActErrors(axiosResponse: AxiosResponse): boolean {
    if (axiosResponse?.data?.code === 'NOT_EMPTY_ITEMS') {
      this.notification['warn']({
        message: 'Ошибка при удалении',
        description: 'Акт приёма не должен содержать объектов при удалении',
      });

      return true
    }

    return false
  }

  private givingActErrors(axiosResponse: AxiosResponse): boolean {
    if (axiosResponse?.data?.code === 'NOT_EMPTY_ITEMS') {
      this.notification['warn']({
        message: 'Ошибка при удалении',
        description: 'Акт выдачи не должен содержать объектов при удалении',
      });

      return true
    }

    if (axiosResponse?.data?.code === 'INVALID_NUMBER') {
      this.notification['warn']({
        message: 'Ошибка при сохранении',
        description: 'Акт выдачи с таким номером уже существует',
      });

      return true
    }

    return false
  }

  private financeActErrors(axiosResponse: AxiosResponse): boolean {
    if (axiosResponse?.data?.code === 'NOT_EMPTY_ITEMS') {
      this.notification['warn']({
        message: 'Ошибка при удалении',
        description: 'Финансовый акт не должен содержать списка работ при удалении',
      });

      return true
    }

    return false
  }

  notifySuccess() {
    this.notification['info']({
      message: 'Успешно',
      placement: 'bottomRight'
    });
  }
}



export default new Notifications(notification);