import Api from '../utils/Api';
import moment, {isMoment} from 'moment';
import {
  ReceivingActListResponse,
  ReceivingActType as APIReceivingActType,
  ReceivingActShortType as APIReceivingActShortType
} from '../types/api/ReceivingActType';
import {ReceivingActShortType, ReceivingActType} from '../types/ReceivingActType';
import {PaginationSettings} from "../types/PaginationSettings";
import {PagedList} from "../types/PagedList";
import ContractService from "./ContractService";
import ArtObjectService from "./ArtObjectService";
import {ArtObject} from "../types/api/ArtObject";
import RequestUtils from "./RequestUtils";

class ReceivingActService {
  static async saveNew(contractId: string) {
    return Api.call(
      `/receiving-act`,
      {
        method: 'POST',
        data: {contractId},
      },
    ).then((response) => {
      return response.data;
    });
  }

  static async saveNewObject(actId: number, data: any) {
    return Api.call(
      `/receiving-act/${actId}/add-new-object`,
      {
        method: 'POST',
        data: data,
      },
    ).then((response) => {
      return response.data;
    });
  }

  static addObject(actId: number, objectId: string) {
    return Api.call(
      `/receiving-act/${actId}/add-object`,
      {
        method: 'POST',
        data: {actId, objectIds: [objectId]},
      },
    ).then((response) => {
      return ReceivingActService.makeReceivingActFromApi(response.data);
    });
  }

  static async getAll(
    searchParams: any,
    pagination: PaginationSettings,
  ): Promise<PagedList<ReceivingActType>> {
    return Api.call(
      `/receiving-act?page=${pagination.pageNumber}&size=${pagination.size}&` +
      RequestUtils.serialize(searchParams),
      {
        method: 'GET',
      },
    ).then((response) => {
      return {
        items: response.data.content.map((response: APIReceivingActType) =>
          ReceivingActService.makeReceivingActFromApi(response),
        ),
        page: {
          page: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        },
      } as PagedList<ReceivingActType>;
    });
  }

  static async getForContract(contractId: string): Promise<ReceivingActType[]> {
    const response: ReceivingActListResponse = await Api.call(
      `/contract/${contractId}/receiving-atc`,
      {
        method: 'GET',
      },
    ).then((response) => {
      return response.data as ReceivingActListResponse;
    });

    return response.map<ReceivingActType>((response: APIReceivingActType) =>
      ReceivingActService.makeReceivingActFromApi(response),
    );
  }

  static makeReceivingActFromApi(act: APIReceivingActType): ReceivingActType {
    return {
      id: act.id,
      number: act.number,
      date: moment(act.date),
      remarks: act.remarks,
      contractId: act.contractId,
      expertId: act.expertId,
      contract: act.contract ? ContractService.makeContractFromApi(act.contract) : undefined,
      experts: [],
      objects: act.objects ? act.objects.map((it: ArtObject) => ArtObjectService.makeObjectFromApi(it)) : [],
      objectNumbers: act.objectNumbers ?? []
    } as ReceivingActType;
  }

  static async getByObjectId(id: string): Promise<ReceivingActType[]> {
    const response: ReceivingActListResponse = await Api.call(
      `/art-object/${id}/receiving-acts`,
      {
        method: 'GET',
      },
    ).then((response) => {
      return response.data as ReceivingActListResponse;
    });

    return response.map<ReceivingActType>((response: APIReceivingActType) =>
      ReceivingActService.makeReceivingActFromApi(response),
    );
  }

  static async getById(actId: string): Promise<ReceivingActType> {
    const response: APIReceivingActType = await Api.call(
      `/receiving-act/${actId}`,
      {
        method: 'GET',
      },
    ).then((response) => {
      return response.data as APIReceivingActType;
    });

    return ReceivingActService.makeReceivingActFromApi(response);
  }

  static async update(actId: string, data: any): Promise<ReceivingActType> {
    const response: APIReceivingActType = await Api.call(
      `/receiving-act/${actId}`,
      {
        method: 'POST',
        data: data
      },
    ).then((response) => {
      return response.data as APIReceivingActType;
    });

    return ReceivingActService.makeReceivingActFromApi(response);
  }

  static async getByObjectIds(objectIds: string[]): Promise<{ [index: string]: ReceivingActShortType }> {
   const response = await Api.call(
      `/receiving-act/search-for-objects`,
      {
        method: 'POST',
        data: {objectIds: objectIds}
      },
      true
    ).then((response) => {
      return response.data as APIReceivingActShortType[]
    });

    const result: { [index: string]: ReceivingActShortType } = {}
    response.forEach(value => {
      result[value.objectId] = value
    })
    return result
  }

  static async deleteObject(actId: string, objectId: string): Promise<ReceivingActType> {
    const response: APIReceivingActType = await Api.call(
      `/receiving-act/${actId}/object/${objectId}`,
      {method: 'DELETE',},
    ).then((response) => {
      return response.data as APIReceivingActType;
    });

    return ReceivingActService.makeReceivingActFromApi(response);
  }

  static async delete(actId: string): Promise<void> {
    const response: APIReceivingActType = await Api.call(
      `/receiving-act/${actId}`,
      {method: 'DELETE',},
    ).then((response) => {
      return response.data as APIReceivingActType;
    });
  }
}

export default ReceivingActService;
