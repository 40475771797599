import React from 'react';
import { Link } from 'react-router-dom';
import { ArtObject } from '../../types/ArtObject';
import {Expert} from "../../types/Expert";
import ArtObjectService from "../../api/ArtObjectService";
import {Row, Typography} from "antd";
import {MaterialsItem} from "../../api/MaterialsService";
import {TechnicsItem} from "../../api/TechnicsService";
const { Text } = Typography;

export const objectListColumns = [
  {
    title: 'Номер',
    dataIndex: 'number',
    render: (number: string, artObject: ArtObject) => (
      <Link target="_blank" to={`/object/${artObject.id}`}>{number}</Link>
    ),
    width: 100,
  },
  {
    title: 'Договор',
    dataIndex: 'contract',
    width: 100,
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    width: 300,
  },
  {
    title: 'Автор',
    dataIndex: 'author',
    render: (author: any, artObject: ArtObject) => (
      <>
        <span>{`${artObject.author?.name}`}</span> <br />
      </>
    ),
    width: 200,
  },
  {
    title: 'Название',
    dataIndex: 'name',
    render: (name: string, artObject: ArtObject) => ArtObjectService.getObjectListName(artObject),
    width: 300,
  },
  {
    title: 'Материалы',
    dataIndex: 'materials',
    render: (materials: MaterialsItem[], artObject: ArtObject) =>  {
      return <>
        {materials.map(m => <Row key={m.id}><Text>{m.name}</Text></Row>)}
      </>
    },
    width: 150,
  },
  {
    title: 'Техники',
    dataIndex: 'techniques',
    render: (techniques: TechnicsItem[], artObject: ArtObject) =>  {
      return <>
        {techniques.map(t => <Row key={t.id}><Text>{t.name}</Text></Row>)}
      </>
    },
    width: 150,
  },
  {
    title: 'Размеры',
    dataIndex: 'sizes',
    render: (name: string, artObject: ArtObject) => ArtObjectService.getObjectSizes(artObject),
    width: 200,
  },
  {
    title: 'Эксперт',
    dataIndex: 'experts',
    width: 200,
  },
  {
    title: 'Акт приема',
    dataIndex: 'receivingAct',
    width: 100,
  },
  {
    title: 'Акт выдачи',
    dataIndex: 'givingAct',
    width: 100,
  }
];
