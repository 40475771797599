import React, {FC, useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Table, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import DashboardContainer from '../../components/DashboardContainer';
import Search from '../../widgets/Search';

import {financialActListColumns} from './Columns';
import {PagedList} from "../../types/PagedList";
import {defaultPagination, PaginationSettings} from "../../types/PaginationSettings";
import {emptyFinanceActPage, FinanceActType} from "../../types/FinanceActType";
import FinanceActService from "../../api/FinanceActService";
import {ContractType} from "../../types/ContractType";
import AuthService from "../../api/AuthService";
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import PageUtils from "../../utils/PageUtils";
import moment from "moment";

const searchData = [
  {name: 'number', label: 'Акт', type: 'text'},
  {name: 'year', label: 'Год', type: 'year'},
  {name: 'contract', label: 'Договор', type: 'text'},
];

const FinancialActList: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [financialActList, setFinancialActList] = useState<PagedList<FinanceActType>>(emptyFinanceActPage);
  const user = useSelector(selectUserState);

  const fetchActs = async (searchParams: any, pagination: PaginationSettings) => {
    setIsLoading(true);

    const preparedSearch = {...searchParams, year: searchParams.year ? moment(searchParams.year).year() : undefined}
    console.log(searchParams, preparedSearch)

    const financeActs = await FinanceActService.getAll(preparedSearch, pagination);

    setFinancialActList(financeActs);
    setIsLoading(false);
  };

  const {onSearchChange, onPaginationChange, form} = PageUtils('/expertise/financial-acts', fetchActs)

  return (
    <DashboardContainer
      title='Финансовые Акты'
      buttons={[
        <Link key='1' to={`/expertise/financial-acts/new`}>
          <Button disabled={!AuthService.checkPermission(user.permissions, "FINANCEACTS", "WRITE")}
                  icon={<PlusOutlined/>}>Новый Финансовый Акт</Button>
        </Link>,
      ]}>
      <Search fields={searchData} action={onSearchChange} form={form}/>

      <Table
        rowKey='id'
        loading={isLoading}
        pagination={{
          pageSize: financialActList.page.pageSize,
          current: financialActList.page.page,
          total: financialActList.page.total,
          onChange: onPaginationChange,
        }}
        columns={financialActListColumns.map((cell: any) => {
          if (cell.dataIndex === 'contract') {
            return {
              ...cell,
              render: (contract: ContractType) => (
                <Link target="_blank" to={`/expertise/contracts/${contract.id}`}>
                  {`${contract.number}/${contract.year}`}
                </Link>
              ),
            };
          } else {
            return cell;
          }
        })}
        dataSource={financialActList.items}
      />
    </DashboardContainer>
  );
};

export default FinancialActList;
