import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';

import { DATE_FORMAT } from '../../constants';
import {FinanceActType} from "../../types/FinanceActType";

export const financialActListColumns = [
  {
    title: 'Акт',
    dataIndex: 'number',
    render: (_: any, record: FinanceActType) => (
      <Link target="_blank" to={`/expertise/financial-acts/${record.id}`}>
        {`${record.date.year()} | ${record.number}`}
      </Link>
    ),
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
  {
    title: 'Договор',
    dataIndex: 'contract',
  },
];
