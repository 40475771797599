import {ChemicalTypeValue, ExpertiseChemical} from "../types/ExpertiseChemical";
import { ExpertiseChemical as ApiExpertiseChemical } from '../types/api/ExpertiseChemical';
import Api from '../utils/Api';
import moment from 'moment';
import ArtObjectService from './ArtObjectService';
import {ArtObjectStatusValue} from "../types/ArtObjectStatus";
import RequestUtils from "./RequestUtils";


class ExpertiseChemicalService {
  static async getAll(searchParams: any): Promise<ExpertiseChemical[]> {
    const response = await Api.call('/expertise-chemical?' + RequestUtils.serialize(searchParams), {
      method: 'GET',
    });

    return response.data.map((it: ApiExpertiseChemical) => ExpertiseChemicalService.makeFromApi(it));
  }

  static mapStatus(statusCode: string): string
  {
    for (const enumMember in ChemicalTypeValue) {
      if (enumMember == statusCode) {
        // @ts-ignore
        return ChemicalTypeValue[enumMember].toString()
      }
    }

    return '';
  }

  static makeFromApi(expertise: ApiExpertiseChemical): ExpertiseChemical {
    return {
      id: expertise.id,
      date: moment(expertise.date),
      result: expertise.result,
      type: expertise.type,
      expertName: expertise.expertName,
      object: expertise.object ? ArtObjectService.makeObjectFromApi(expertise.object) : undefined,
    } as ExpertiseChemical;
  }
}

export default ExpertiseChemicalService;
