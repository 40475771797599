import React, {FC, useState} from 'react';
import {Form, Row, Col, Space, DatePicker, Button, Card, Table} from 'antd';

import DashboardContainer from '../../components/DashboardContainer';
import { DATE_FORMAT } from '../../constants';
import ExpertiseXRAYService from "../../api/ExpertiseXRAYService";
import {ExpertiseXRAY} from "../../types/ExpertiseXRAY";
import moment from "moment/moment";
import {ExpertiseXRAYComparison} from "../../types/ExpertiseXRAYComparison";
import ExpertiseXRAYComparisonService from "../../api/ExpertiseXRAYComparisonService";
import artObjectExpertsHistoryService from "../../api/ArtObjectExpertsHistoryService";
import {ArtObjectExpertsHistory as ApiArtObjectExpertsHistory} from "../../types/api/ArtObjectExpertsHistory";
import {ArtObjectExpertsHistory} from "../../types/ArtObjectExpertsHistory";
import ResizableTable from "../../components/ResizableTable";

const { RangePicker } = DatePicker;

const RentgenComparison: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportRows, setReportRows] = useState<ExpertiseXRAYComparison[]>([])

  const reportColumns = [
    {
      title: 'Эксперт',
      width: 300,
      render: (reportRow: ExpertiseXRAYComparison) => {
        const artObjectExpertsHistory: ArtObjectExpertsHistory|null = reportRow.object.expertsHistory[reportRow.object.expertsHistory.length - 1] ?? null;
        if (!artObjectExpertsHistory) {
          return "";
        }

        return artObjectExpertsHistory.expert.surname + " "
            + artObjectExpertsHistory.expert.name[0] + '. '
            + artObjectExpertsHistory.expert.patronymic[0] + '. ';
      }
    },
    {
      title: 'Дата',
      width: 300,
      render: (reportRow: ExpertiseXRAYComparison) => reportRow.date.format("YYYY.MM.DD")
    },
    {
      title: '№ вещи',
      width: 300,
      render: (reportRow: ExpertiseXRAYComparison) => reportRow.object.number + " | "
        + reportRow.object.author?.name + " | "
        + reportRow.object.name
    },
    {
      title: 'Результат',
      width: 100,
      render: (reportRow: ExpertiseXRAYComparison) => reportRow.comparisonResult ? "Положительный" : "Отрицательный"
    },
  ];

  const fetchReportItems = async (searchParams: any) => {
    return ExpertiseXRAYComparisonService.getAll(searchParams, true)
  };

  const openReport = () => {
    setIsLoading(true)
    form.validateFields().then(() => {
      const formValues = form.getFieldsValue()
      fetchReportItems({
        dateFrom: formValues.dateRange[0].format("YYYY-MM-DD 00:00:00"),
        dateTo: formValues.dateRange[1].format("YYYY-MM-DD 00:00:00"),
      }).then((comparisons: ExpertiseXRAYComparison[]) => {
        setReportRows(comparisons)
        setIsLoading(false)
      })
    });
  };

  return (
      <DashboardContainer title='Сравнение рентгенограмм' className='text-align-left'>
        <Row>
          <Col>
            <Form form={form} name='objectForm' layout='vertical'>
              <Space align='end'>
                <Form.Item
                    label='Интервал'
                    name='dateRange'
                    rules={[{ required: true, message: 'Введите дату' }]}>
                  <RangePicker
                      className='full-width'
                      placeholder={['С:', 'По:']}
                      format={DATE_FORMAT}
                      onCalendarChange={(params: any) =>
                          form.setFieldsValue({
                            ...form.getFieldsValue(),
                            dateRange: [moment(params?.[0]), moment(params?.[0]).add(7, 'days')],
                          })
                      }
                  />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' className='full-width' onClick={openReport}>
                    Открыть отчёт
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
        <>
          {
            reportRows.length ? (
                <>
                  <Row>
                    <Col span={24}>
                      <Card title='Акты приема' bordered={true}>
                        <ResizableTable
                            rowKey='id'
                            columns={reportColumns}
                            dataSource={reportRows}
                            loading={isLoading}
                            pagination={false}
                        />
                      </Card>
                    </Col>
                  </Row>
                </>
            ) : (
                <>
                </>
            )
          }
        </>
      </DashboardContainer>
  );
};

export default RentgenComparison;