import Api from "../utils/Api";
import jwtDecode, {JwtPayload} from "jwt-decode";
import moment from "moment";
import LocalStorage from "../utils/LocalStorage";
import {STORE_USER_TOKEN} from "../constants";

export interface LoginRequest {
  login: string,
  password: string
}

export interface Token {
    accessToken: string
}

export interface UserData {
    token: string,
    fullName: string,
    id: string,
    roles: string[],
    permissions: { [s: string]: string }
}

const EXPIRED_THRESHOLD_SECONDS = 10 * 60 * 60;

class AuthService {
    static checkPermission(permissions: {[s: string]: string }, permission?: string, level?: string): boolean {
        if (!permissions)
            return false

        if (!permission)
            return true

        if (!permissions[permission])
            return false

        return (!level || level === 'READ' || permissions[permission] === level);
    }

    static async postSigning(authRequest: LoginRequest): Promise<UserData> {
        return Api.call(
            '/auth/signin',
            {
                method: 'POST',
                data: authRequest
            },
        ).then((response) => {
            const authData: UserData = jwtDecode(response.data.accessToken);
            LocalStorage.set(STORE_USER_TOKEN, response.data.accessToken);
            Api.recreateClient();

            return {
                ...authData,
                token: response.data.accessToken,
            } as UserData;
        });
    }

    static readToken() {
        try {
            return jwtDecode<UserData>(LocalStorage.get(STORE_USER_TOKEN) ?? '')
        } catch (e) {
            LocalStorage.delete(STORE_USER_TOKEN);
            throw new Error();
        }
    }

    static verifyToken(token: string) {
        try {
            const decodedToken = jwtDecode<JwtPayload>(token)
            if (moment((decodedToken.exp ?? 0) * 1000).diff(moment(), 'seconds') < EXPIRED_THRESHOLD_SECONDS) {
                LocalStorage.delete(STORE_USER_TOKEN);

                return false;
            }

            return true;
        } catch (e) {
            LocalStorage.delete(STORE_USER_TOKEN);

            return false;
        }
    }
}

export default AuthService;
