import {PriceListItem} from "../types/PriceListItem";
import {PriceListItem as APIPriceListItem} from "../types/api/PriceListItem";
import Api from "../utils/Api";

class PriceListItemService {
  static prepareItems = (items: PriceListItem[]) =>
    items.sort((a: PriceListItem, b: PriceListItem) => {
      const aNumbers = a.number.split(".")
      const bNumbers = b.number.split(".")

      for (let i = 0; i < aNumbers.length; i++) {
        const aNumber = parseInt(aNumbers[i]);
        const bNumberString = bNumbers[i];

        if (bNumberString) {
          const bNumber = parseInt(bNumberString)
          if (aNumber > bNumber) {
            return 1
          } else if (aNumber < bNumber) {
            return -1
          }
        }
      }

      return 0
    });


  static makePriceListItemFromApi(priceListItem: APIPriceListItem): PriceListItem {
    return {
      id: priceListItem.id,
      number: priceListItem.number,
      name: priceListItem.name,
      shortName: priceListItem.shortName,
      minPrice: priceListItem.minPrice,
      maxPrice: priceListItem.maxPrice,
    } as PriceListItem;
  }

  static add = (listItemId: any, data: any) => {
    return Api.call(`/price/${listItemId}`, {
      data: data,
      method: 'POST',
    }).then((response) => {
      return PriceListItemService.makePriceListItemFromApi(response.data);
    });
  }
  static edit = (listItemId: any, id: any, data: any) => {
    return Api.call(`/price/${listItemId}/item/${id}`, {
      data: data,
      method: 'POST',
    }).then((response) => {
      return PriceListItemService.makePriceListItemFromApi(response.data);
    });
  }
  static delete = (listItemId: any, id: any) => {
    return Api.call(`/price/${listItemId}/item/${id}`, {
      method: 'DELETE',
    }).then((response) => {});
  }
}

export default PriceListItemService;
