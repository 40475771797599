import React, { FC, useState } from 'react';
import {Form, Row, Col, Space, DatePicker, Select, Button, Card, Table, Image} from 'antd';
import moment, { Moment } from 'moment';
import DashboardContainer from '../../components/DashboardContainer';
import { DATE_FORMAT } from '../../constants';
import ExpertiseXRAYService from "../../api/ExpertiseXRAYService";
import {ExpertiseXRAY} from "../../types/ExpertiseXRAY";
import ResizableTable from "../../components/ResizableTable";

const { RangePicker } = DatePicker;

type XRAYReportItem = {
  id: string,
  yearWithMonth: string,
  date: Moment,
  count: number,
  dayStatText: string,
  first: boolean,
  xrayItem: ExpertiseXRAY
}

const RentgenReport: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportRows, setReportRows] = useState<XRAYReportItem[]>([])
  const [reports, setReports] = useState<Set<string>>(new Set)

  const reportColumns = [
    {
      title: 'Дата съемки/Итого',
      width: 300,
      render: (reportRow: XRAYReportItem) => reportRow.first ? reportRow.dayStatText : ""
    },
    {
      title: 'Художник',
      width: 300,
      render: (reportRow: XRAYReportItem) => reportRow.xrayItem.object.author?.name
    },
    {
      title: 'Учетное обозначение',
      width: 300,
      render: (reportRow: XRAYReportItem) => reportRow.xrayItem.object.number
    },
    {
      title: 'Количество',
      width: 100,
      render: (reportRow: XRAYReportItem) => reportRow.xrayItem.count
    },
  ];

  const fetchReportItems = async (searchParams: any) => {
    return ExpertiseXRAYService.getAll(searchParams, true)
  };

  const openReport = () => {
    setIsLoading(true)
    form.validateFields().then(() => {
      const formValues = form.getFieldsValue()
      fetchReportItems({
        dateFrom: formValues.dateRange[0].format("YYYY-MM-DD 00:00:00"),
        dateTo: formValues.dateRange[1].format("YYYY-MM-DD 00:00:00"),
      }).then((xrayItems: ExpertiseXRAY[]) => {
        let lastDay = null;
        const XRAYReportItems: XRAYReportItem[] = [];
        const dayCounter: Map<string, number> = new Map<string, number>();
        for (let i = 0; i < xrayItems.length; i++) {
          const xrayDay = xrayItems[i].date.format("DD")
          let isFirst = false;
          const currentCount: number = dayCounter.get(xrayItems[i].date.format("YYYY-MM-DD")) ?? 0;
          if (xrayDay !== lastDay) {
            lastDay = xrayDay;
            isFirst = true;
          }
          dayCounter.set(
              xrayItems[i].date.format("YYYY-MM-DD"),
              currentCount + xrayItems[i].count
          )
          XRAYReportItems.push({
            id: xrayItems[i].id,
            yearWithMonth: xrayItems[i].date.format("YYYY") + "/" + xrayItems[i].date.format("MM"),
            date: xrayItems[i].date,
            count: 0,
            first: isFirst,
            dayStatText: "",
            xrayItem: xrayItems[i]
          } as XRAYReportItem)
        }

        XRAYReportItems.forEach((it: XRAYReportItem) => {
          it.count = dayCounter.get(it.date.format("YYYY-MM-DD")) ?? 0
          it.dayStatText = it.date.format("YYYY-MM-DD") + "/" + it.count + "(шт.)"
        })

        setReports(new Set(XRAYReportItems.map((it: XRAYReportItem) => it.date.format("YYYY / MMMM"))))
        setReportRows(XRAYReportItems)
        setIsLoading(false)
      })
    });
  };

  return (
      <DashboardContainer title='Отчет по рентгенам' className='text-align-left'>
        <Row>
          <Col>
            <Form form={form} name='objectForm' layout='vertical'>
              <Space align='end'>
                <Form.Item
                    label='Интервал'
                    name='dateRange'
                    rules={[{ required: true, message: 'Введите дату' }]}>
                  <RangePicker
                      className='full-width'
                      placeholder={['С:', 'По:']}
                      format={DATE_FORMAT}
                      onCalendarChange={(params: any) =>
                          form.setFieldsValue({
                            ...form.getFieldsValue(),
                            dateRange: [moment(params?.[0]), moment(params?.[0]).add(7, 'days')],
                          })
                      }
                  />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' className='full-width' onClick={openReport}>
                    Открыть отчёт
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
        <>
          {
            Array.from(reports.values()).map((it: string) => (
                <>
                  <Row>
                    <Col span={24}>
                      <Card title={it} bordered={true}>
                        <ResizableTable
                            rowKey="id"
                            columns={reportColumns}
                            dataSource={reportRows.filter((item:XRAYReportItem) => item.date.format("YYYY / MMMM") === it)}
                            loading={isLoading}
                            pagination={false}
                        />
                      </Card>
                    </Col>
                  </Row>
                </>
            ))
          }
        </>
      </DashboardContainer>
  );
};

export default RentgenReport;
