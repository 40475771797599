import Api from '../utils/Api';
import {ReportWeeklyObjectsItem as ReportWeeklyObjectsItemApi} from "../types/api/ReportWeeklyObjectsItem";
import ArtObjectService from "./ArtObjectService";
import {ReportWeeklyObjectsItem} from "../types/ReportWeeklyObjectsItem";
import ReceivingActService from "./ReceivingActService";
import GivingActService from "./GivingActService";
import PhotoService from "./PhotoService";

class WeeklyReportService {
  private static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj)
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  static async getAll(searchParams: any): Promise<ReportWeeklyObjectsItem[]> {
    return Api.call('/report/weekly-report?' + WeeklyReportService.serialize(searchParams), {
      method: 'GET',
    }).then((response) => {
      return response.data.map((it: ReportWeeklyObjectsItemApi) => WeeklyReportService.makeFromApi(it));
    });
  }

  static makeFromApi(reportItem: ReportWeeklyObjectsItemApi): ReportWeeklyObjectsItem {
    return {
      id: reportItem.id,
      receivingAct: ReceivingActService.makeReceivingActFromApi(reportItem.receivingAct),
      artObject: ArtObjectService.makeObjectFromApi(reportItem.artObject),
      gibingAct: reportItem.givingAct ? GivingActService.makeGivingActFromApi(reportItem.givingAct) : null,
      image: reportItem.image ? PhotoService.makePhotoFromApi(reportItem.image) : undefined,
    } as ReportWeeklyObjectsItem;
  }
}

export default WeeklyReportService;
