import {
  ArtObjectAuthor as APIArtObjectAuthor,
  ArtObjectAuthorListResponse,
} from '../types/api/ArtObjectAuthor';
import { ArtObjectAuthor } from '../types/ArtObjectAuthor';
import Api from '../utils/Api';

class ArtObjectAuthorService {
  static async getAll(): Promise<ArtObjectAuthor[]> {
    const response: ArtObjectAuthorListResponse = await Api.call(`/art-object-authors/`, {
      method: 'GET',
    }).then((response) => {
      return response.data as ArtObjectAuthorListResponse;
    });

    return response.map<ArtObjectAuthor>((response: APIArtObjectAuthor) =>
      ArtObjectAuthorService.makeObjectFromApi(response),
    );
  }

  static makeObjectFromApi(author: APIArtObjectAuthor): ArtObjectAuthor {
    return {
      id: author.id,
      name: author.name,
    } as ArtObjectAuthor;
  }
}

export default ArtObjectAuthorService;
