import {ArtObjectStatus, ArtObjectStatusValue} from "../types/ArtObjectStatus";
import {ArtObjectStatus as ApiArtObjectStatus} from "../types/api/ArtObjectStatus";
import moment from "moment";
import Api from "../utils/Api";
import RequestUtils from "./RequestUtils";

class ArtObjectStatusService {
  static async getAll(searchParams: { objectIds: string[] }): Promise<ArtObjectStatus[]> {
    const response = await Api.call('/art-object/statuses?' + RequestUtils.serialize(searchParams), {
      method: 'GET',
    });

    return response.data.map((it: ApiArtObjectStatus) => ArtObjectStatusService.makeFromApi(it));
  }

  static mapStatus(statusCode: string): string {
    for (const enumMember in ArtObjectStatusValue) {
      if (enumMember == statusCode) {
        // @ts-ignore
        return ArtObjectStatusValue[enumMember].toString()
      }
    }

    return '';
  }

  static makeFromApi(object: ApiArtObjectStatus): ArtObjectStatus {
    return {
      id: object.id,
      code: object.code,
      date: moment(object.date),
      artObjectId: object.artObject ? object.artObject.id : undefined
    } as ArtObjectStatus;
  }
}

export default ArtObjectStatusService;