import Api from '../utils/Api';
import {AxiosResponse} from "axios";
import {UserListResponse, User} from "../types/User";

class UserService {
    static async getAll(): Promise<User[]> {
        return Api.call(
            '/user',
            {
                method: 'GET',
            },
        ).then((response: AxiosResponse<UserListResponse>) => {
            return response.data;
        });
    }

    static async getById(id: string): Promise<User> {
        return Api.call(
            '/user/' + id,
            {
                method: 'GET',
            },
        ).then((response: any) => {
            return UserService.makeUserFromApi(response.data);
        });
    }

    static async getByRoleId(id: string): Promise<User[]> {
        return Api.call(
            '/user-role/' + id + '/users',
            {
                method: 'GET',
            },
        ).then((response: AxiosResponse<UserListResponse>) => {
            return response.data;
        });
    }

    static async update(changedUser: User): Promise<User> {
        return Api.call(
            '/user/' + changedUser.id,
            {
                method: 'POST',
                data: changedUser,
            },
        ).then((response: AxiosResponse<User>) => {
            return UserService.makeUserFromApi(response.data);
        });
    }

    static add(changedUser: User) {
        return Api.call(
            '/user',
            {
                method: 'POST',
                data: changedUser,
            },
        ).then((response: AxiosResponse<User>) => {
            return UserService.makeUserFromApi(response.data);
        });
    }

    static makeUserFromApi(data: any) {
        return data as User;
    }
}

export default UserService;