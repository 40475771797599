import {Role} from "./Role";

export type User = {
  id: string;
  login: string;
  fullName: string;
  roles: Role[];
};

export type UserListResponse = User[];

export const emptyUser: User = {
  id: '',
  login: '',
  fullName: '',
  roles: [],
};
