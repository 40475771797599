import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Layout, PageHeader, Menu, MenuProps} from 'antd';
import {UserOutlined, WarningOutlined} from '@ant-design/icons';
import './styles.scss';

import {routes} from '../../constants/routes';
import {FOOTER_COPYRIGHT, STORE_USER_TOKEN} from '../../constants';
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import AuthService, {UserData} from "../../api/AuthService";
import {ItemType, MenuItemType, SubMenuType} from "antd/lib/menu/hooks/useItems";
import {selectAppEnv} from "../../redux/appEnv/appEnvGetters";

type MenuItemValue = {
  key: string,
  title?: string,
  target?: string,
  icon?: any,
  items?: MenuItemValue[],
  onClick?: () => any,
  permission?: string,
  permissionLevel?: string,
}

const DashboardContainer: FC<any> = (props) => {
  const userState = useSelector(selectUserState);
  const appEnv = useSelector(selectAppEnv);

  const {title, breadcrumbs, buttons, children} = props;
  const {Header, Content, Footer} = Layout;
  const {SubMenu} = Menu;
  const menuItems = [
    {
      key: '/expertise',
      title: "Экспертиза",
      items: [
        {
          key: routes.clientList,
          title: "Клиенты и договоры",
          permission: "CLIENTS"
        },
        {
          key: routes.acceptActNew,
          title: "Новый Акт приема",
          target: "_blank",
          permission: "ARTOBJECTS",
          permissionLevel: "WRITE"
        },
        {
          key: routes.findImage,
          title: "Поиск фотографий",
          target: "_blank",
          permission: "ARTOBJECTSIMAGES"
        },
        // {
        //   key: routes.uploadImage,
        //   title: "Загрузка фото",
        //   target: "_blank",
        // },
        {
          key: routes.acceptActList,
          title: "Поиск актов приема",
          target: "_blank",
          permission: "RECEIVINGACTS"
        },
        {
          key: routes.issuingActList,
          title: "Поиск актов выдачи",
          target: "_blank",
          permission: "GIVINGACTS"
        },
        {
          key: routes.financialActList,
          title: "Поиск финансовых актов",
          target: "_blank",
          permission: "FINANCEACTS"
        }
      ]
    },
    {
      key: routes.objectsList,
      title: "Поиск ОЭ",
    },
    {
      key: '/dictionary',
      title: "Служебные формы",
      items: [
        {
          key: routes.directory,
          title: "Общие справочники",
          permission: "DICTIONARIES"
        },
        {
          key: routes.priceLists,
          title: "Тарифы",
          permission: "DICTIONARIES"
        },
      ],
    },
    {
      key: '/report',
      title: "Отчёты",
      items: [
        {
          key: routes.dailyReport,
          title: "Ежедневный отчёт",
          target: "_blank",
        },
        {
          key: routes.expertWork,
          title: "Работы эксперта",
          target: "_blank",
        },
        {
          key: routes.weeklyReport,
          title: "Недельный отчёт",
          target: "_blank",
        },
        {
          key: routes.monthlyReport,
          title: "Месячный отчет по невыданным объектам",
          target: "_blank",
        },
        {
          key: routes.rentgenReport,
          title: "Отчёт по рентгенам",
          target: "_blank",
        },
        {
          key: routes.rentgenComparison,
          title: "Сравнение рентгенограмм",
          target: "_blank",
        },
        {
          key: routes.directorReport,
          title: "Отчет директора",
          target: "_blank",
        }
      ],
    },
    {
      key: '/users',
      title: 'Управление пользователями',
      items: [
        {
          key: routes.usersList,
          title: "Пользователи",
          permission: "USERS"
        },
        {
          key: routes.usersRoles,
          title: "Роли пользователей",
          permission: "USERS"
        },
      ],
    },
    {
      key: '/profile',
      title: userState.username,
      icon: <UserOutlined/>,
      items: [
        {
          key: 'logout',
          title: "Выйти",
          onClick: () => {
            localStorage.removeItem(STORE_USER_TOKEN);
            window.location.reload();
          }
        },
      ],
    }
  ];

  if (!appEnv.production) {
    menuItems.push({
      disabled: true,
      key: '/staging',
      title: 'STAGING',
      class: 'ant-menu-overflow-item ant-menu-item ant-menu-item-disabled ant-menu-item-env-label',
      icon: <WarningOutlined/>,
    })
  }

  const getMenuSubMenu = (menuItems: MenuItemValue[], level: number): ItemType[] => {
    return menuItems.map((itemValue: any) => {
      if (itemValue.items !== undefined) {
        return {
          key: itemValue.key,
          title: itemValue.title,
          icon: itemValue.icon,
          label: itemValue.title,
          style: itemValue.style,
          class: itemValue.class,
          disabled: itemValue.disabled,
          children: getMenuSubMenu(itemValue.items, level + 1)
        } as SubMenuType
      } else {
        if (!itemValue.permission || AuthService.checkPermission(userState.permissions, itemValue.permission, itemValue.permissionLevel))
          return {
            key: itemValue.key,
            icon: itemValue.icon,
            title: itemValue.title,
            onClick: itemValue.onClick,
            style: itemValue.style,
            class: itemValue.class,
            disabled: itemValue.disabled,
            label: <Link to={itemValue.key} target={itemValue.target}>{itemValue.title}</Link>
          } as MenuItemType
        else
          return null
      }
    });
  }

  return (
    <Layout className='root-layout'>
      <Header
        style={{position: 'fixed', zIndex: 1, width: '100%', paddingLeft: 0, paddingRight: 0}}>
        <Menu theme='dark' mode='horizontal' items={getMenuSubMenu(menuItems, 0)}/>
        {!appEnv.production ?
          <div style={{zIndex: 1, width: '100%', borderBottom: '5px red solid'}}>
          </div> : <></>}
      </Header>
      <Layout className='site-layout' style={{margin: '70px 16px 0'}}>
        <Content>
          <div className='site-layout-background' style={{padding: 24, textAlign: 'center'}}>
            {title ? (
              <PageHeader
                className='site-page-header'
                title={title}
                breadcrumb={breadcrumbs}
                extra={buttons}
              />
            ) : null}

            {children}
          </div>
        </Content>
      </Layout>
      <Footer style={{textAlign: 'center'}}>{FOOTER_COPYRIGHT}</Footer>
    </Layout>
  );
};

export default DashboardContainer;
