import {Moment} from "moment";
import {DATE_FORMAT} from "../../constants";

export const priceListColumns = [
  {
    title: 'Название',
    dataIndex: 'date',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
];

export const priceListItemColumns = [
  {
    title: 'Порядковый номер',
    dataIndex: 'number',
    editable: true,
    type: 'text'
  },
  {
    title: 'Название',
    dataIndex: 'name',
    editable: true,
    type: 'text'
  },
  {
    title: 'Сокращенное название',
    dataIndex: 'shortName',
    editable: true,
    type: 'text'
  },
  {
    title: 'Мин. цена',
    dataIndex: 'minPrice',
    editable: true,
    type: 'number'
  },
  {
    title: 'Макс. цена',
    dataIndex: 'maxPrice',
    editable: true,
    type: 'number'
  },
];
