import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import {ArtObject} from "../../types/ArtObject";
import ArtObjectService from "../../api/ArtObjectService";
import ExpertsService from "../../api/ExpertsService";

const objectTransform = (artObject: ArtObject) => {
  return ArtObjectService.getObjectDisplayName(artObject);
};

const expertsTransform = (artObject: ArtObject) => {
  return `${artObject.expertsHistory.map((expert) => ExpertsService.getExpertName(expert.expert))}`;
};

export const objectColumns = [
  {
    title: '№ п/п',
    dataIndex: 'index',
    type: 'number',
  },
  {
    title: 'Предполагаемое авторство и наименование, краткое описание. Материал, техника, размер',
    render: (column: any, artObject: ArtObject) => objectTransform(artObject),
    type: 'text',
  },
  {
    title: 'Учётное обозн.',
    dataIndex: 'number',
    type: 'text',
  },
  {
    title: 'Сохранность',
    dataIndex: 'damages',
    type: 'text',
  },
  {
    title: 'Эксперты',
    render: (column: any, artObject: ArtObject) => expertsTransform(artObject),
    type: 'text',
  },
];
