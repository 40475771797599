import React, {FC, useState} from 'react';
import {Form, Input, Button, Typography, notification, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingOutlined, WarningOutlined} from '@ant-design/icons';

import SystemContainer from '../../components/SystemContainer';
import {login} from "../../redux/user/userActions";
import AuthService from "api/AuthService";
import {selectAppEnv} from "../../redux/appEnv/appEnvGetters";

const Login: FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {Title} = Typography;
  const appEnv = useSelector(selectAppEnv);

  const onFinish = async (values: any) => {
    setIsLoading(true)

    AuthService.postSigning({
      login: values.login,
      password: values.password
    }).then((response) => {
      dispatch(login(response))
    })
      .catch((error) => {
        if (error.message === "Network Error") {
          notification['error']({
            message: 'Ошибка авторизации',
            description: 'Ошибка подключения к серверу',
          });
          return;
        } else {
          notification['error']({
            message: 'Ошибка авторизации',
            description: 'Введите корректную пару логин/пароль',
          });
        }
      }).finally(() => setIsLoading(false))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <SystemContainer>
      <Form name='basic' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Title>Войти в аккаунт</Title>

        {!appEnv.production ?
          <Row style={{width: '100%', justifyContent: 'center'}}>
            <Title level={4} style={{color: 'red'}}><WarningOutlined/> STAGING</Title>
          </Row>:
          <></>}

        <Form.Item
          label='Login'
          name='login'
          rules={[{required: true, message: 'Введите логин'}]}>
          <Input size='large' type='text'/>
        </Form.Item>

        <Form.Item
          label='Пароль'
          name='password'
          rules={[{required: true, message: 'Введите корректный пароль'}]}>
          <Input.Password size='large'/>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' size='large' className='submit'>
            {isLoading ? <LoadingOutlined style={{fontSize: 24}} spin/> : 'Войти'}
          </Button>
        </Form.Item>
      </Form>
    </SystemContainer>
  );
};

export default Login;
