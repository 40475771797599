import { Photo } from '../types/Photo';
import { PhotoListResponse, Photo as APIPhoto } from '../types/api/Photo';
import Api from '../utils/Api';
import moment, {Moment} from 'moment';
import {RcFile} from "antd/lib/upload";

class PhotoService {
  static getPhotoPreview(photos: Photo[]): Photo | undefined {
    if (photos.length === 0)
      return undefined

    const sortFun = (a: Photo, b: Photo) => {
      return (a.isInfraRed ? 1 : 0) - (b.isInfraRed ? 1 : 0) || b.date.unix() - a.date.unix()
    }

    let commonPhotos = photos.filter(f => f.type === '0')
      .sort((a, b) => sortFun(a, b))

    if (commonPhotos.length === 0)
      commonPhotos = photos.sort((a, b) => sortFun(a, b))

    if (commonPhotos.length > 0)
      return commonPhotos[0]

    return undefined
  }

  static async uploadImage(id: string, image: any,type: string, isInfraRed: boolean, date: Moment): Promise<string> {
    const formData = new FormData();
    formData.append('file', image as RcFile);
    formData.append('type', type);
    formData.append('isInfraRed', isInfraRed ? "true" : "false");
    formData.append('date', date.unix().toString());

    return Api.call(
      `/image?objectId=${id}`,
      {
        method: 'POST',
        data: formData,
      },
    ).then((response) => {
      return response.data as string;
    });
  }

  static async deleteImage(id: string, imageId: string): Promise<string> {
    return Api.call(
      `/image?objectId=${id}&imageId=${imageId}`,
      {
        method: 'DELETE',
      },
    ).then((response) => {
      return response.data as string;
    });
  }

  static async getByObjectId(id: string): Promise<Photo[]> {
    const response: PhotoListResponse = await Api.call(`/art-object/${id}/images`, {
      method: 'GET',
    }).then((response) => {
      return response.data as PhotoListResponse;
    });

    return response.map<Photo>((response: APIPhoto) => PhotoService.makePhotoFromApi(response));
  }

  static makePhotoFromApi(photo: APIPhoto): Photo {
    return {
      id: photo.id,
      name: photo.name,
      link: Api.getBaseUrl() + photo.link,
      thumbnailLink: Api.getBaseUrl() + photo.thumbnailLink,
      type: photo.type?.toString(),
      isInfraRed: photo.isInfraRed,
      date: moment(photo.date),
    } as Photo;
  }
}

export default PhotoService;
