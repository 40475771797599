import React, {FC, useEffect, useState} from 'react';
import DashboardContainer from '../../components/DashboardContainer';
import {Table, Col, Row, Button, Space, Popconfirm} from 'antd';
import {PriceList} from "../../types/PriceList";
import PriceListService from "../../api/PriceListService";
import {useHistory, useParams} from "react-router-dom";
import {priceListColumns, priceListItemColumns} from "./Columns";
import EditableTable from "../../widgets/EditableTable";
import {PriceListItem} from "../../types/PriceListItem";
import PriceListItemService from "../../api/PriceListItemService";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import AuthService from "../../api/AuthService";

const PriceLists: FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [priceLists, setPriceLists] = useState<PriceList[]>([]);
  const [priceListItems, setPriceListItems] = useState<PriceListItem[]>([]);
  const {id} = useParams<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([id]);
  const [priceListItemActiveKey, setPriceListItemActiveKey] = useState<string | number>('');
  const user = useSelector(selectUserState);

  const editDisabled = !AuthService.checkPermission(user.permissions, "PRICELIST", "WRITE")

  const fetchPriceLists = async (id: any) => {
    setIsLoading(true);

    await PriceListService.getAll().then((priceLists) => {
      if (id === undefined) {
        setPriceListItems(priceLists[0].items)
        setSelectedRowKeys([priceLists[0].id])
      } else {
        setPriceListItems(priceLists.filter((it: PriceList) => it.id === id)[0]?.items ?? []);
      }
      setPriceLists(priceLists);
    }).finally(() => {
      setIsLoading(false)
    })
  };

  useEffect(() => {
    setSelectedRowKeys([id]);
  }, [id]);

  useEffect(() => {
    fetchPriceLists(id).then();
  }, [id]);

  const addAction = (data: any, record: any) => {
    PriceListItemService.add(selectedRowKeys[0], data).then((addItem) => {
      setPriceListItems(PriceListItemService.prepareItems([...priceListItems, addItem]))
    })
  }
  const editAction = (data: any, record: any) => {
    PriceListItemService.edit(selectedRowKeys[0], record.id, data).then((editItem) => {
      setPriceListItems(PriceListItemService.prepareItems(priceListItems.map((src: PriceListItem) => src.id === record.id ? editItem : src)))
    })
  };
  const deleteAction = (data: any, record: any) => {
    PriceListItemService.delete(selectedRowKeys[0], data.id).then(() => {
      setPriceListItems(PriceListItemService.prepareItems(priceListItems.filter((src: PriceListItem) => src.id !== data.id)))
    })
  };

  const addNewPriceList = () => {
    PriceListService.add().then((newList) => {
      setPriceLists([...priceLists, newList])
      setSelectedRowKeys([newList.id]);
      history.push(`/dictionary/prices/${newList.id}`);
    })
  };

  const deletePriceList = () => {
    const removedId = selectedRowKeys[0];
    PriceListService.delete(removedId).then(() => {
      const newPriceLists = priceLists.filter((src: PriceList) => src.id !== removedId);
      setPriceLists(newPriceLists)
      const newPriceListId = newPriceLists.length > 0 ? [newPriceLists[0].id] : [];
      setSelectedRowKeys(newPriceListId);
      if (newPriceListId.length > 0) {
        history.push(`/dictionary/prices/${newPriceListId[0]}`);
      }
    })
  };

  const dataParams = {
    addAction,
    editAction,
    deleteAction,
    isLoading: isLoading,
  };

  return (
    <DashboardContainer title='Прайс листы'>
      <Row gutter={16}>
        <Col span={4} className='text-align-left'>
          <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Table
              rowKey='id'
              columns={priceListColumns}
              dataSource={priceLists}
              loading={isLoading}
              pagination={false}
              showHeader={false}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(selectedRowKeys);
                  history.push(`/dictionary/prices/${selectedRows[0].id}`);
                },
              }}
            />
            <Button onClick={addNewPriceList} icon={<PlusOutlined/>} disabled={editDisabled}>Новый</Button>
          </Space>
        </Col>
        <Col span={18} className='text-align-right'>
          <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Popconfirm
              disabled={editDisabled}
              title='Вы уверены, что хотите удалить этот объект?'
              placement='topRight'
              okText='Удалить'
              cancelText='Отменить'
              onConfirm={() => deletePriceList()}>
              <Button
                disabled={editDisabled}
                shape='circle'
                icon={<DeleteOutlined/>}
                danger
              />
            </Popconfirm>
            <EditableTable
              disableEdit={editDisabled}
              addable={!editDisabled}
              columns={priceListItemColumns}
              dataSource={priceListItems}
              dataPattern={{}}
              activeKey={priceListItemActiveKey}
              setActiveKey={setPriceListItemActiveKey}
              {...dataParams}
            />
          </Space>
        </Col>
      </Row>
    </DashboardContainer>
  );
};

export default PriceLists;
