import React, {FC, useEffect, useState} from 'react';
import {Form, Row, Col, Space, DatePicker, Button, Divider, Card, Table} from 'antd';

import DashboardContainer from '../../components/DashboardContainer';

import { DATE_FORMAT } from '../../constants';
import {Moment} from "moment/moment";
import ReportUnclaimedObjectService from "../../api/ReportUnclaimedObjectService";
import {ReportUnclaimedObjectsItem} from "../../types/ReportUnclaimedObjectsItem";
import ResizableTable from "../../components/ResizableTable";

const reportColumns = [
  {
    title: '№ вещи',
    width: "5%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow.artObject.number
  },
  {
    title: 'Автор',
    width: "10%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow.artObject.author?.name
  },
  {
    title: 'Название',
    width: "25%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow.artObject.name
  },
  {
    title: 'Материал / Техника',
    width: "25%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow.artObject?.techniques.map((it) => it.name).join(", ") + ";"  + reportRow.artObject.materials.map((it) => it.name).join(", ") + ";" + reportRow.artObject.width + "x" + reportRow.artObject.height + (reportRow.artObject.depth ? "x" + reportRow.artObject.depth : "")
  },
  {
    title: 'Номер договора',
    width: "5%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow?.receivingAct?.contract.year + "/" + reportRow?.receivingAct?.contract.number
  },
  {
    title: 'Подписан',
    width: "5%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow?.receivingAct?.contract.signedAt.format("YYYY-MM-DD")
  },
  {
    title: 'Клиент',
    width: "10%",
    render: (reportRow: ReportUnclaimedObjectsItem) =>
        `${reportRow?.receivingAct?.contract.client.surname}
              ${reportRow?.receivingAct?.contract.client.name ? reportRow.receivingAct.contract.client.name[0] + '.' : ''}
              ${reportRow?.receivingAct?.contract.client.patronymic ? reportRow.receivingAct.contract.client.patronymic[0] + '.' : ''}
          `
  },
  {
    title: 'Акт приема',
    width: "10%",
    render: (reportRow: ReportUnclaimedObjectsItem) => reportRow?.receivingAct?.number
  },
];

const MonthlyReport: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [date, setDate] = useState<Moment|undefined>()
  const [reportItems, setReportItems] = useState<ReportUnclaimedObjectsItem[]>([])

  const datePickerOnChange = ((value: any) => {
    if (value == null) {
      return;
    }
  })

  useEffect(() => {
    setIsLoading(true)
    if (date) {
      ReportUnclaimedObjectService.getAll({
        dateTo: date.format("YYYY-MM-DD 00:00:00")
      }).then((reportItems: ReportUnclaimedObjectsItem[] ) => {
        setReportItems(reportItems)
        setIsLoading(false)
      })
    }
  }, [date]);

  const openReport = () => {
    form.validateFields().then(() => setDate(form.getFieldsValue().date)).catch((e: any) => {});
  };

  return (
    <DashboardContainer title='Месячный отчет по невыданным объектам' className='text-align-left'>
      <Row>
        <Col>
          <Form form={form} name='objectForm' layout='vertical'>
            <Space align='end'>
              <Form.Item
                label='Дата'
                name='date'
                rules={[{ required: true, message: 'Введите дату' }]}>
                <DatePicker className='full-width' format={DATE_FORMAT} allowClear={false}/>
              </Form.Item>

              <Form.Item>
                <Button type='primary' className='full-width' onClick={openReport}>
                  Открыть отчёт
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Col>
      </Row>
      <Divider />
      <>
        {reportItems.length ? (
            <>
              <Row>
                <Col span={24}>
                  <Card title='Объекты' bordered={true}>
                    <ResizableTable
                        rowKey='id'
                        columns={reportColumns}
                        dataSource={reportItems}
                        loading={isLoading}
                        pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
            </>
        ) : (
            <>
            </>
        )}
      </>
    </DashboardContainer>
  );
};

export default MonthlyReport;
