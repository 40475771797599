import Api from '../utils/Api';
import { Expert as APIExpert, ExpertsListResponse } from '../types/api/Expert';
import { Expert } from '../types/Expert';

class ExpertsService {
  private static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj)
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  static getExpertName(expert: Expert): string {
    const nameSymbol = expert.name ? `${expert.name[0]}.` : '';
    const surnameSymbol = expert.patronymic ? `${expert.patronymic[0]}.` : '';
    return `${expert.surname} ${nameSymbol} ${surnameSymbol}`
  }

  static async getAll(searchParams: any): Promise<ExpertsListResponse> {
    return Api.call('/expert?' + ExpertsService.serialize(searchParams), {
      method: 'GET',
    }).then((response) => {
      return response.data.map((it: APIExpert) => ExpertsService.makeFromApi(it));
    });
  }

  static async addExpert(expert: Expert): Promise<Expert> {
    return Api.call('/expert', {
      data: expert as APIExpert,
      method: 'POST',
    }).then((response) => {
      return ExpertsService.makeFromApi(response.data);
    });
  }

  static async editExpert(expert: Expert, id: string): Promise<Expert> {
    return Api.call('/expert/' + id, {
      data: expert as APIExpert,
      method: 'POST',
    }).then((response) => {
      return ExpertsService.makeFromApi(response.data);
    });
  }

  static async deleteExpert(id?: string): Promise<void> {
    Api.call('/expert/' + id, {
      method: 'DELETE',
    });
  }

  static makeFromApi(expert: APIExpert): Expert {
    return {
      id: expert.id,
      name: expert.name,
      surname: expert.surname,
      patronymic: expert.patronymic,
    } as Expert;
  }
}

export default ExpertsService;
