import {Moment} from "moment/moment";
import moment from "moment";

export const imageTypes = [
  {
    key: 0,
    value: '0',
    name: 'Общий вид'
  },
  {
    key: 1,
    value: '1',
    name: 'Подпись/Клеймо'
  },
  {
    key: 2,
    value: '2',
    name: 'Фрагмент'
  }
]

export type Photo = {
  id: string,
  name: string,
  link: string,
  thumbnailLink: string,
  type: string,
  isInfraRed: boolean,
  date: Moment
};

export const emptyPhoto: Photo = {
  id: "",
  name: "",
  link: "",
  thumbnailLink: "",
  type: "",
  isInfraRed: false,
  date: moment()
};
