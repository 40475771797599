import React from 'react';
import { Link } from 'react-router-dom';

import { authorsDemo, typesDemo } from '../../__mocks__/CommonsMock';
import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import {ReceivingActType} from "../../types/ReceivingActType";
import {ArtObject, ArtObjectType} from "../../types/ArtObject";
import {Select} from "antd";

const authorTransform = (author: ArtObjectAuthor|null) => {
  return `${author?.name}`;
};

export const objectColumns = [
  {
    title: 'Объект',
    dataIndex: 'number',
    render: (number: string | number, artObject: ArtObject) => {
      return <Link to={`/object/${artObject.id}`}>{number}</Link>;
    },
    type: 'number',
    width: 100,
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    type: 'select',
    options: Object.keys(ArtObjectType),
    render: (type: string | number, author: ArtObjectAuthor|null) => {
      // @ts-ignore
      return ArtObjectType[type].toString()
    },
    optionRender: (type: string | number, object: ArtObject) => {
      // @ts-ignore
      return ArtObjectType[type].toString()
    },
    width: 100,
  },
  {
    title: 'Автор',
    dataIndex: 'author',
    render: (author: ArtObjectAuthor|null) => authorTransform(author),
    type: 'select',
    options: authorsDemo.map((author: any) => JSON.stringify(author)),
    optionRender: (author: any) => authorTransform(author),
    width: 200,
  },
  {
    title: 'Наименование Объекта',
    dataIndex: 'name',
    type: 'text',
    width: 500,
  },
];
