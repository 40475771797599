import React, {FC, useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {Table, Button, Col, Row, notification} from 'antd';

import DashboardContainer from '../../components/DashboardContainer';
import Client from './Client';
import Search from '../../widgets/Search';

import {clientListColumns} from './Columns';
import ClientsService from '../../api/ClientsService';
import {defaultPagination, PaginationSettings} from "../../types/PaginationSettings";
import {emptyClientsPage} from "../../types/ClientType";
import AuthService from "../../api/AuthService";
import { useSelector } from 'react-redux';
import {selectUserState} from "../../redux/user/userGetters";
import PageUtils from "../../utils/PageUtils";

const searchData = [
  {name: 'surname', label: 'Фамилия', type: 'text'},
  {name: 'name', label: 'Имя', type: 'text'},
  {name: 'patronymic', label: 'Отчество', type: 'text'},
  {name: 'phone', label: 'Телефон', type: 'text'},
  {name: 'email', label: 'Email', type: 'text'},
];

const Clients: FC = () => {
  const {id} = useParams<any>();
  const location = useLocation<any>();

  const [selectedRowKeys, setSelectedRowKyes] = useState<any>([id]);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<any>(emptyClientsPage);
  const user = useSelector(selectUserState);

  const fetchClients = async (searchParams: any, pagination: PaginationSettings) => {
    if (!AuthService.checkPermission(user.permissions, "CLIENTS")) {
      return
    }

    setIsLoading(true);
    ClientsService.getAll(searchParams, pagination)
      .then((clients) => {
        setClients(clients);
        setSelectedRowKyes([id]);
      })
      .finally(() => setIsLoading(false))
  };

  const {onSearchChange, onPaginationChange, history, form} = PageUtils(location.pathname, fetchClients)

  const onAddNewClient = () => {
    setSelectedRowKyes([]);
  };

  return (
    <DashboardContainer
      title='Клиенты и договоры'
      buttons={[
        <Link key='1' to='/expertise/clients/new'>
          <Button key='1' type='primary' danger onClick={onAddNewClient}
                  disabled={!AuthService.checkPermission(user.permissions, "CLIENTS", "WRITE")}>
            Добавить нового клиента
          </Button>
        </Link>,
      ]}>

      {AuthService.checkPermission(user.permissions, "CLIENTS") ? <>
          <Search fields={searchData} action={onSearchChange} form={form} />

          <Row>
            <Col span={10}>
              <Table
                rowKey='id'
                columns={clientListColumns}
                dataSource={clients.items}
                loading={isLoading}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: selectedRowKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRowKyes(selectedRowKeys);
                    history.push(`/expertise/clients/${selectedRows[0].id}` + location.search);
                  },
                }}
                pagination={{
                  pageSize: clients.page.pageSize,
                  current: clients.page.page,
                  total: clients.page.total,
                  onChange: onPaginationChange,
                }}
              />
            </Col>

            <Col span={12} offset={1}>
              <Client/>
            </Col>
          </Row></>
        : <div>

        </div>}

    </DashboardContainer>
  );
};

export default Clients;
