export type PagedList<T> = {
  items: T[];
  page: {
    page: number;
    pageSize: number;
    total: number;
  };
};

export const defaultPagedList: PagedList<any> = {
  items: [],
  page: {
    page: 1,
    pageSize: 20,
    total: 0,
  }
}
