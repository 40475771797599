import React, {FC, useEffect, useMemo, useState} from 'react';
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space, Table, Typography} from 'antd';

import SelectedModalTable from "../../widgets/SelectedModalTable";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {defaultPagedList, PagedList} from "../../types/PagedList";
import {ContractType} from "../../types/ContractType";
import {ContractSearchType} from "../../types/api/ContractType";
import {defaultPagination, PaginationSettings} from "../../types/PaginationSettings";
import ContractService from "../../api/ContractService";
import {
  contractColumns,
  contractsSearchFields,
  objectColumns,
  priceListItemColumns, selectObjectColumns,
  selectPriceListItemColumns
} from "./Columns";
import ArtObjectService from "../../api/ArtObjectService";
import PriceListService from "../../api/PriceListService";
import {PriceListItem, PriceSelectListItem} from "../../types/PriceListItem";
import FinanceActService from "../../api/FinanceActService";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import uuid from "react-uuid";
import TextArea from "antd/es/input/TextArea";
import {DATE_FORMAT, DATE_TIME_FORMAT} from "../../constants";
import moment from "moment";
import {PriceList} from "../../types/PriceList";
import ExpertiseXRAYService from "../../api/ExpertiseXRAYService";
import {ArtObject, ArtObjectType} from "../../types/ArtObject";
import ExpertiseService from "../../api/ExpertiseService";
import ExpertiseXRAYComparisonService from "../../api/ExpertiseXRAYComparisonService";
import ExpertiseChemicalService from "../../api/ExpertiseChemicalService";
import DashboardContainer from "../../components/DashboardContainer";
import {emptyFinanceAct, FinanceActType} from "../../types/FinanceActType";
import AuthService from "../../api/AuthService";
import {shallowEqual, useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import ArtObjectStatusService from "../../api/ArtObjectStatusService";
import {ArtObjectStatusValue} from "../../types/ArtObjectStatus";

const {Option} = Select;

const {Text} = Typography;

const FinancialAct: FC<any> = () => {
  const { id } = useParams<any>();

  const [financialAct, setFinancialAct] = useState<FinanceActType>(emptyFinanceAct);

  const [form ] = Form.useForm()
  const [modalForm ] = Form.useForm()
  const history = useHistory();
  const user = useSelector(selectUserState);

  const [isModalVisibleContracts, setIsModalVisibleContracts] = useState<boolean>(false);
  const [isModalVisibleActs, setIsModalVisibleActs] = useState<boolean>(false);
  const [isModalVisibleObjects, setIsModalVisibleObjects] = useState<boolean>(false);

  const [contracts, setContracts] = useState<PagedList<ContractType>>(defaultPagedList);
  const [contract, setContract] = useState<any>([]);

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [objects, setObjects] = useState<any>(defaultPagedList);
  const [selectedObjectRows, setSelectedObjectRows] = useState<any>([]);
  const [selectedObjectRowKeys, setSelectedObjectRowKeys] = useState<any>([]);

  const [selectedObjects, setSelectedObjects] = useState<ArtObject[]>([]);

  const [priceItems, setPriceItems] = useState<any>(defaultPagedList);
  const [priceLists, setPriceLists] = useState<PriceList[]>([]);
  const [selectedPriceList, setSelectedPriceList] = useState<PriceList | undefined>(undefined);

  const [selectedActs, setSelectedActs] = useState<PriceSelectListItem[]>([]);
  const [preparedActs, setPreparedActs] = useState<PriceSelectListItem[]>([]);
  const [sum, setSum] = useState<number>(0);

  const [needSave, setNeedSave] = useState<boolean>(false)
  const [changed, setChanged] = useState<boolean>(false)

  const fetchAct = async (id?: string) => {
    if (!id) {
      setFinancialAct(emptyFinanceAct);
      return;
    }
    const act:FinanceActType = await FinanceActService.getById(id);
    setFinancialAct(act);
  };

  useEffect(() => {
    fetchAct(id).then();
  }, [id]);

  const contractButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-52px',
    right: '113px',
  };

  const prepareNewAct = (act: any, item: any, priceList: any) => {
    return { id: uuid(), ...act, number: act.price ?? (item ? item.maxPrice : undefined) ?? 0, multiply: act.count ?? 1, sum: 0, item, priceList }
  }

  useMemo(() => {
    const sum = selectedActs.map((item: any) => item.multiply * item.number)
      .reduce((a: number, b: number) => a + b, 0)

    setSum(sum)
  }, [selectedActs])

  function renderContract(contract: ContractType) {
    const client = contract.client;
    const fio = `${client.surname} ${client.name ? client.name[0] : ''}. ${client.patronymic ? client.patronymic[0] : ''}`
    return `${contract.year}/${contract.number} ${fio}`
  }

  const fetchContracts = (data: ContractSearchType, pagination: PaginationSettings) => {
    ContractService.findBy(data, pagination).then((response) => {
      setContracts(response);
    });
  };

  const fetchObjects = (data: any, pagination: PaginationSettings) => {
    if (!contract || contract.length == 0)
      return

    ArtObjectService.getAll({...data, contractId: contract[0].id}, pagination).then((response) => {
      setObjects(response);
    });
  };

  const setObject = (objects: (prevState: []) => any[]) => {
    const object = objects([])[0];

    const newSelectedObjects = [...(selectedObjects.filter((v: any) => v.id != object.id)), { ...object } ];
    setSelectedObjects(newSelectedObjects)

    tryToSave(true)
  }

  useEffect(() => {
    form.setFieldValue(["act", 'date'], financialAct.date)
    form.setFieldValue(["act", 'remarks'], financialAct.remarks)
    form.setFieldValue(["act", 'number'], financialAct.number)

    if (financialAct.objects.length == 0)
      return

    ArtObjectService.getAll({objectIds: financialAct.objects.map(v => v.id)}, defaultPagination)
      .then(value => setSelectedObjects(value.items))
  }, [financialAct])

  useEffect(() => {
    if (!priceLists || priceLists.length == 0 || !selectedPriceList) return

    const preparedNewActs = financialAct.items.map(act => {
      const priceList = priceLists.find(v => v.items.find(i => i.id === act.priceItem?.id))
      return {...prepareNewAct(act, act.priceItem, priceList ?? selectedPriceList)}
    })

    console.log(preparedNewActs)

    preparedNewActs.forEach(act => {
      form.setFieldValue([act.id, 'id'], act.priceItem?.id)
      form.setFieldValue([act.id, 'name'], act.name)
      form.setFieldValue([act.id, 'number'], act.number)
      form.setFieldValue([act.id, 'multiply'], act.count)
      recalculateSumForItem(act)
    })

    setSelectedActs(preparedNewActs)
  }, [financialAct, priceLists, selectedPriceList])

  const fetchActs = () => {
    PriceListService.getAll().then((response) => {
      setPriceLists(response)
      setSelectedPriceList(response[0])
      setPriceItems({page: {page: 1, pageSize: 10, total:  response[0].items.length}, items: response[0].items});
    });
  };

  const changePriceList = (value: string) => {
    const priceList = priceLists.find((v) => v.id == value)
    if (!priceList) return

    setSelectedPriceList(priceList)
    setPriceItems({page: {page: 1, pageSize: 10, total:  priceList.items.length}, items: priceList.items});
  }

  useEffect(() => {
    if (financialAct === emptyFinanceAct) {
      return
    }

    ContractService.getById(financialAct.contract.id).then((response) => {
      setContract([response]);
    })
  }, [financialAct]);

  useEffect(() => {
    setSelectedObjectRows([])
    setSelectedObjectRowKeys([])

    if (contract.length > 0) {
      fetchActs()
      fetchObjects({}, defaultPagination)
    }
  }, [contract]);

  const updateFinancialAct = () => {
    return form.validateFields().then(async (formData) => {
      return await FinanceActService.upsert(contract[0].id, selectedObjects.map(v => v.id), formData, financialAct.id)
    })
  }

  const commonParams = {
    selectedRows,
    selectedRowKeys,
    setSelectedRows,
    setSelectedRowKeys,
  }

  const tableParams = {
    ...commonParams,
    searchAction: fetchContracts,
  };

  const tableParamsActs = {
    ...commonParams,
    searchAction: () => {},
  };

  const tableParamsObjects = {
    ...commonParams,
    searchAction: fetchObjects,
  };

  const recalculateSumForItem = (priceItem: PriceSelectListItem) => {
    const price = form.getFieldValue([priceItem.id, 'number'])
    const multiply = form.getFieldValue([priceItem.id, 'multiply'])
    form.setFieldValue([priceItem.id, 'sum'], price * multiply)

    if (price && multiply) {
      priceItem.number = price
      priceItem.multiply = multiply
      priceItem.sum = price * multiply
    }
  }

  const changeValueInForm = (priceItem: PriceSelectListItem) => {
    recalculateSumForItem(priceItem)
    setSelectedActs(selectedActs.map((item: PriceSelectListItem) => {if (item.id === priceItem.id) return priceItem; else return item;}))
  }

  const removeItem = (priceItemId: string) => {
    setSelectedActs(selectedActs.filter((item: PriceSelectListItem) => item.id !== priceItemId))
    tryToSave(true)
  }

  const removeObjectItem = (objectId: string) => {
    setSelectedObjects(selectedObjects.filter((item: ArtObject) => item.id !== objectId))
    tryToSave(true)
  }

  const objectToType = (object: ArtObject): string => {
    return object.type ? ArtObjectService.getObjectTypeShort(object.type) : ''
  }

  const createWorkedList = () => {
    const idToObject = new Map<string, ArtObject>()
    selectedObjects.forEach(v => idToObject.set(v.id, v))

    const objectIds = selectedObjects.map(value => value.id);
    if (objectIds.length == 0) {
      return
    }

    Promise.all([
      ExpertiseXRAYService.getAll({objectIds: objectIds}),
      ExpertiseXRAYComparisonService.getAll({objectIds: objectIds}),
      ExpertiseService.getAll({objectIds: objectIds}),
      ExpertiseChemicalService.getAll({objectIds: objectIds}),
      ArtObjectStatusService.getAll({objectIds: objectIds})
    ]).then(([xrays, xraysComparisions, expertise, chemicals, statuses]) => {
      const newActs: any[] = []

      xrays.forEach(xray => {
        const artObject = idToObject.get(xray.object.id)
        // @ts-ignore
        newActs.push({name: `${objectToType(artObject)} ${artObject.number} ${artObject.author.name} рентген`, count: xray.count})
      })

      xraysComparisions.forEach(xray => {
        const artObject = idToObject.get(xray.object.id)
        // @ts-ignore
        newActs.push({name: `${objectToType(artObject)} ${artObject.number} ${artObject.author.name} рентген сравнение ${xray.expertName}`, count: 1})
      })

      expertise.forEach(xray => {
        const artObject = idToObject.get(xray.objectId)
        // @ts-ignore
        newActs.push({name: `${objectToType(artObject)} ${artObject.number} ${artObject.author.name} экспертиза ${xray.number} ${ExpertiseService.expertTransform(xray.expertFull)}`, count: 1})
      })

      chemicals.forEach(chemical => {
        const artObject = idToObject.get(chemical.object.id)
        // @ts-ignore
        newActs.push({name: `${objectToType(artObject)} ${artObject.number} ${artObject.author.name} химическая экспертиза ${chemical.expertName}`, count: 1})
      })

      statuses.forEach(status => {
        if (status.code !== "OTV")
          return

        const artObject = idToObject.get(status.artObjectId ?? '')
        // @ts-ignore
        newActs.push({name: `${objectToType(artObject)} ${artObject.number} ${artObject.author.name} cтатус «отвод»`, count: 1})
      })

      const preparedNewActs = newActs.map(act => prepareNewAct(act, undefined, selectedPriceList));

      const dedupedNewActs: any[] = [];
      preparedNewActs.forEach((item) => {
        let unique = true;
        selectedActs.forEach((act) => {
          if (
            (item.name ?? '') === act.name &&
            (item.number ?? 0) === act.number &&
            (item.multiply ?? 0) === act.multiply &&
            (item.sum ?? 0) === act.sum
          ) {
            unique = false;
          }
        });
        if (unique) dedupedNewActs.push(item);
      });

      dedupedNewActs.forEach(act => {
        form.setFieldValue([act.id, 'name'], act.name)
        form.setFieldValue([act.id, 'multiply'], act.count)
      })

      setSelectedActs([...selectedActs, ...dedupedNewActs])
      setChanged(true)
    })
  }

  const selectPriceItem = (priceItem: PriceSelectListItem, id: string) => {
    const item = priceItem.priceList.items.find(v => v.id === id)
    if (!item) return

    if (priceItem.item !== item) {
      priceItem.item = item
      tryToSave(true)
    }

    form.setFieldValue([priceItem.id, 'id'], id)

    recalculateSumForItem(priceItem)
    const newSelectedActs = selectedActs.map(v => v.id === priceItem.id ? priceItem : v)
    setSelectedActs(newSelectedActs)
  }

  const removeObjectColumn = {
    title: '',
    dataIndex: 'delete',
    render: (value: any, artObject: ArtObject) => {
      return <Button
        shape='circle'
        icon={<DeleteOutlined/>}
        onClick={() => removeObjectItem(artObject.id)}
        disabled={disableEditing}
        danger
      />
    }
  }

  const tryToSave = (force?: boolean) => {
    if (changed || force)
      setNeedSave(true)
  }

  const onFormChange = () => {
    setChanged(true)
  }

  useEffect(() => {
    if (!needSave) {
      return
    }

    updateFinancialAct()
    setChanged(false)
    setNeedSave(false)
  }, [needSave])

  const onActAdd = () => {
    modalForm.validateFields().then(values => {
        const actId = uuid()
        const item = selectedPriceList?.items.find(v => v.id === values.id)

        setSelectedActs([...selectedActs, {...prepareNewAct(values, item, selectedPriceList), id: actId}])
        form.setFieldValue([actId, 'id'], item?.id)
        form.setFieldValue([actId, 'name'], values.name)
        form.setFieldValue([actId, 'number'], values.price)
        form.setFieldValue([actId, 'multiply'], values.count)

        if (!item)
          setChanged(true)
        else
          tryToSave(true)
      }
    ).then(() => {
      modalForm.resetFields()
      setIsModalVisibleActs(false)
    })
  }

  const disableEditing = !AuthService.checkPermission(user.permissions, "FINANCEACTS", "WRITE")

  useMemo(() => {
    if (!selectedPriceList) {
      setPreparedActs(selectedActs)
      return
    }

    const emptyAct = prepareNewAct({empty: true}, undefined, selectedPriceList) as PriceSelectListItem;
    setPreparedActs([...selectedActs, emptyAct])
  }, [selectedActs, selectedPriceList])

  const materializeEmptyRecord = (record: any) => {
    setSelectedActs([...selectedActs, {...record, empty: undefined}])
  }

  const emptyFinanceActCell = (record: any) => {
    return <div className="editable-cell-value-wrap" onClick={() => materializeEmptyRecord(record)}></div>;
  }

  const priceColumns = [
    {
      title: 'Тариф',
      dataIndex: 'displayName',
      editable: false,
      render: (v: any, priceItem: PriceSelectListItem) => {
        if (priceItem.empty)
          return emptyFinanceActCell(priceItem)

        return <Form.Item name={[priceItem.id, 'other']} rules={[
          ({getFieldValue}) => ({
            validator(_, value) {
              if (priceItem.item) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Выберите позицию'));
            },
          })]}>
          <Select style={{width: '400px'}} defaultValue={priceItem.item?.id}
                  onChange={(item: string) => selectPriceItem(priceItem, item)}
                  disabled={disableEditing}>
            {priceItem.priceList.items.map((value: any, index: any) => (
              <Option value={value.id} key={value.id}>
                <Text style={{whiteSpace: "pre-wrap"}}>
                  {`п. ${value.number} Тарифы ${priceItem.priceList.date.format(DATE_FORMAT)} ${value.name} (${value.minPrice} - ${value.maxPrice})`}
                </Text>
              </Option>
            ))}
          </Select>
        </Form.Item>
      }
    },
    {
      title: 'Наименование работы',
      dataIndex: 'name',
      width: '55%',
      render: (text: string | number, priceItem: PriceSelectListItem) => {
        if (priceItem.empty)
          return emptyFinanceActCell(priceItem)

        return <div>
          <Form.Item name={[priceItem.id, 'name']} rules={[{required: true, min: 1, type: 'string', message: 'Введите описание'}]}>
            <TextArea disabled={disableEditing} style={{height: '100%'}} defaultValue={text} onBlur={() => tryToSave()}/>
          </Form.Item>
          <Form.Item name={[priceItem.id, 'id']} initialValue={priceItem.id} hidden={true}>
          </Form.Item>
        </div>
      }
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      editable: false,
      width: '10%',
      render: (number: string | number, priceItem: PriceSelectListItem) => {
        if (priceItem.empty)
          return emptyFinanceActCell(priceItem)

        return <Form.Item name={[priceItem.id, 'number']}
                          rules={[{required: true, message: 'Введите цену'}]}>
          <Input min={1} type={'number'} placeholder={`${priceItem.item?.minPrice ?? 0} - ${priceItem.item?.maxPrice ?? 0}`}
                 onChange={() => changeValueInForm(priceItem)} onBlur={() => tryToSave()}
                 disabled={disableEditing}/>
        </Form.Item>
      }
    },
    {
      title: 'Количество',
      dataIndex: 'multiply',
      width: '10%',
      render: (value: any, priceItem: PriceSelectListItem) => {
        if (priceItem.empty)
          return emptyFinanceActCell(priceItem)

        return <Form.Item name={[priceItem.id, 'multiply']} rules={[{ required: true, message: 'Введите количество' }]}>
          <Input min={1} type={'number'} onChange={() => changeValueInForm(priceItem)} onBlur={() => tryToSave()}
                 disabled={disableEditing}/>
        </Form.Item>
      }
    },
    {
      title: 'Cумма',
      dataIndex: 'sum',
      width: '10%',
      render: (value: any, priceItem: PriceSelectListItem) => {
        if (priceItem.empty)
          return emptyFinanceActCell(priceItem)

        return value
      }
    },
    {
      title: '',
      dataIndex: 'delete',
      render: (value: any, priceItem: PriceSelectListItem) => {
        if (priceItem.empty)
          return emptyFinanceActCell(priceItem)

        return <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          onClick={() => removeItem(priceItem.id)}
          disabled={disableEditing}
          danger
        />
      }
    }
  ]


  const buttons = [
    <Button key={'1'} onClick={() => setIsModalVisibleContracts(true)} disabled={disableEditing}>
      Указать Договор
    </Button>,
    <Link key='2' to={`/expertise/financial-acts/${id}/print`} target={'_blank'}>
      <Button>
        Печать
      </Button>
    </Link>
  ]

  return (
    <DashboardContainer
      title={`Финансовый Акт`}
      buttons={buttons}
      className='text-align-left'>
      <div className='pos-relative'>
        <Row>
          <Form
            form={form}
            onChange={() => onFormChange()}
            layout={"horizontal"}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 5 }}
            style={{width: '80%'}}
          >
            <Form.Item
              label='Договор'
              className='text-align-left'
            >{contract.length > 0 ? (
              <Text>
                <Typography.Link href={`/expertise/contracts/${contract[0].id}`}>
                  {renderContract(contract[0])}
                </Typography.Link>
              </Text>
            ) : (
              <Text type='danger'>Договор не выбран!</Text>
            )}

              {!disableEditing && <SelectedModalTable
                type='radio'
                modalTitle='Договоры'
                modalTableColumns={contractColumns}
                modalTableData={contracts}
                isModalVisible={isModalVisibleContracts}
                setIsModalVisible={setIsModalVisibleContracts}
                setData={setContract}
                searchFields={contractsSearchFields}
                {...tableParams}
              />}
            </Form.Item>
            <Form.Item
              label='Номер'
              name={["act", 'number']}
              className='text-align-left'
              >
              <Input type={'number'} value={financialAct.number} onBlur={() => setNeedSave(true)}/>
            </Form.Item>
            <Form.Item
              label='Дата'
              name={["act", 'date']}
              rules={[{required: true, message: 'Введите дату'}]}>
              <DatePicker className='full-width' format={DATE_FORMAT} onBlur={() => setNeedSave(true)} disabled={disableEditing}/>
            </Form.Item>
            <Form.Item
              label='Примечания'
              name={["act", 'remarks']}
              wrapperCol={{span: 150}}
            >
              <TextArea className='full-width' rows={2} onBlur={() => setNeedSave(true)} disabled={disableEditing}/>
            </Form.Item>
          </Form>
         </Row>

        <Row>
          <Space key={'1'}>
            <Text key={'1'}>Кем создано:</Text>
            <Text key={'2'}>{financialAct.createdBy?.fullName}</Text>
            <Text key={'3'}>{financialAct.createdAt?.format(DATE_TIME_FORMAT)}</Text>
          </Space>
        </Row>
        <Row>
          <Space key={'2'}>
            <Text key={'1'}>Кем измененно:</Text>
            <Text key={'2'}>{financialAct.modifiedBy?.fullName}</Text>
            <Text key={'3'}>{financialAct.modifiedAt?.format(DATE_TIME_FORMAT)}</Text>
          </Space>
        </Row>

        <Divider/>

        <Row>
          <Space className='text-align-left' direction={"vertical"} style={{width: '100%'}}>
            <Row key={'1'} style={{justifyContent: "right", width: '100%'}}>
              <Button icon={<PlusOutlined/>} onClick={() => setIsModalVisibleObjects(true)}>
                Добавить
              </Button>
            </Row>

            {!disableEditing &&<SelectedModalTable
              type='radio'
              modalTitle='Объекты'
              modalTableColumns={objectColumns}
              modalTableData={objects}
              isModalVisible={isModalVisibleObjects}
              setIsModalVisible={setIsModalVisibleObjects}
              setData={setObject}
              searchFields={selectObjectColumns}
              {...tableParamsObjects}
            />}

            <Table
              key={'2'}
              rowKey='id'
              bordered={true}
              columns={[...objectColumns, removeObjectColumn]}
              dataSource={selectedObjects}
            />
          </Space>
        </Row>

        <Divider/>

        <Row>
          <Space className='text-align-left' direction={"vertical"}>
            <Space key={'1'}>
              <Text key={'1'}>Применяемый тариф</Text>
              <Select key={'2'} style={{width: '150px'}} value={selectedPriceList?.id} onChange={changePriceList}>
                {priceLists.map((value: any, index: any) => (
                  <Option value={value.id} key={value.id}>
                    {value.date.format(DATE_FORMAT)}
                  </Option>
                ))}
              </Select>
            </Space>
            <Row key={'2'}>
              <Button onClick={() => createWorkedList()} disabled={disableEditing}>
                Создать список работ
              </Button>
            </Row>
          </Space>

        </Row>

        <Row>
          <Space className='text-align-left' direction={"vertical"} style={{width: '100%'}}>
            {!disableEditing && <Modal
              title={'Добавление новой работы'}
              open={isModalVisibleActs}
              okText='Добавить'
              cancelText='Отменить'
              onOk={onActAdd}
              onCancel={() => setIsModalVisibleActs(false)}
              width={1000}>
              <Form
                form={modalForm}
                layout={"horizontal"}
                style={{width: '100%'}}
                labelCol={{ span: 5 }}
              >
                {selectedPriceList &&
                    <Form.Item label={'Тариф'} name={'id'}>
                        <Select style={{width: '100%'}}>
                          {selectedPriceList.items.map((value: any, index: any) => (
                            <Option value={value.id} key={value.id}>
                              <Text style={{whiteSpace: "pre-wrap"}}>
                                {`п.${value.number} Тарифы ${selectedPriceList.date.format(DATE_FORMAT)} ${value.name} (${value.minPrice} - ${value.maxPrice})`}
                              </Text>
                            </Option>
                          ))}
                        </Select>
                    </Form.Item>
                }
                <Form.Item label={'Наименование работы'} name={'name'}>
                  <Input/>
                </Form.Item>
                <Form.Item label={'Цена'} name={'price'}
                           rules={[{required: true, message: 'Введите цену'}]}>
                  <Input min={1} type={'number'}/>
                </Form.Item>
                <Form.Item label={'Количество'} name={'count'}
                           rules={[{required: true, message: 'Введите количество'}]}>
                  <Input min={1} type={'number'}/>
                </Form.Item>
              </Form>
            </Modal>}

            <div key={'2'} className='std-margin-top'>
              <Form form={form} onChange={() => onFormChange()}>
                <Table
                  className={'centerTable editable-table'}
                  rowKey='actId'
                  bordered={true}
                  columns={priceColumns}
                  dataSource={preparedActs}
                />
              </Form>
            </div>

            <Row key={'3'} style={{justifyContent: "right", width: '100%'}}>
              <Text className='text-align-right'>Общая сумма: {sum}</Text>
            </Row>
          </Space>
        </Row>
      </div>
      <Row justify="center">
        <Button key='1' onClick={() => tryToSave(true)}>
          Сохранить
        </Button>
      </Row>
    </DashboardContainer>

  );
};

export default FinancialAct;
