import React, {FC, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {Permission} from "../../../types/Permission";
import {User as UserType, User} from "../../../types/User";

import {
  Button,
  Card, Divider,
  Select,
  Table, Typography,
} from 'antd';
import RolePermissionService from "../../../api/RolePermissionService";
import RoleService from "../../../api/RoleService";
import {emptyRole, Role as RoleType} from "../../../types/Role";
import UserService from "../../../api/UserService";
import uuid from "react-uuid";

const {Title} = Typography;
const {Option} = Select;

const contractButtonStyle: any = {
  marginTop: "20px"
};

type PermissionsByObject = {
  name: string,
  hash: string,
  permissions: Permission[]
}

type PermissionsByObjects = PermissionsByObject[];

export const rolesPermissionsColumns = [
  {
    title: 'Объект',
    dataIndex: 'name',
    width: "50%",
  },
  {
    title: 'Доступы',
    dataIndex: 'permissions',
    width: "50%",
  },
];

export const usersColumns = [
  {
    title: 'Имя пользователя',
    dataIndex: 'fullName',
  },
];

const Role: FC = () => {
  const {roleId} = useParams<any>();
  const [currentRole, setCurrentRole] = useState<RoleType>(emptyRole)
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [users, setUsers] = useState<User[]>([]);
  const [isLoadingRole, setIsLoadingRole] = useState(true);
  const [isChanged, setIsChanged] = useState(true);

  const [currentRolePermissions, setCurrentRolePermissions] = useState<PermissionsByObject[]>([])
  const [fullPermissions, setFullPermissions] = useState<PermissionsByObject[]>([])

  const preparePermissions = (curPermission: Permission[]) => {
    const permissionsObject: Map<string, Permission[]> = new Map<string, Permission[]>();
    const returnPermissions: PermissionsByObject[] = [];

    permissions.forEach((permission: Permission) => {
      permissionsObject.set(permission.groupName, []);
    })

    curPermission.forEach((permission: Permission) => {
      const list: Permission[] = permissionsObject.get(permission.groupName) || [];
      list.push(permission);
      permissionsObject.set(permission.groupName, list);
    })

    permissionsObject.forEach((value: Permission[], key: string) => {
      returnPermissions.push({name: key, permissions: value.sort((a,b) => a.name.localeCompare(b.name)), hash: uuid()})
    });

    return returnPermissions;
  }

  useMemo(() => {
    setCurrentRolePermissions(preparePermissions(currentRole.permissions))
    setFullPermissions(preparePermissions(permissions))
  }, [currentRole, permissions])

  const onSelect = ((props: any) => {
    setCurrentRole({
      ...currentRole,
      permissions: [...currentRole.permissions, ...permissions.filter((it: Permission) => it.id == props)]
    })
  })

  const onDeselect = ((props: any) => {
    setCurrentRole({
      ...currentRole,
      permissions: currentRole.permissions.filter((it: Permission) => it.id != props)
    })
  })

  const onUpdate = async () => {
    RoleService.update(currentRole).then((response: RoleType) => {})
  };

  const fetch = async () => {
    if (!roleId) {
      return;
    }

    let permissionTask: Promise<any> | undefined
    if (!permissions.length) {
      permissionTask = RolePermissionService.getAll().then((permissions: Permission[]) => {
        setPermissions(permissions);
      })
    }

    RoleService.getById(roleId).then(async (role: RoleType) => {
      if (permissionTask) await permissionTask
      setCurrentRole(role);
    });

    UserService.getByRoleId(roleId).then((users: User[]) => {
      setUsers(users);
    })
  }

  useEffect(() => {
    setIsLoadingRole(true);
    fetch()
      .finally(() => setIsLoadingRole(false))
  }, [roleId]);

  return (
    <>
      {!roleId ? (
        <div className='text-align-left'>
          <Title level={4}>Выберите роль</Title>
        </div>
      ) : (
        <div>
          <Card title='Права доступа' bordered={true}>
            <Table
              key='hash'
              rowKey='hash'
              columns={rolesPermissionsColumns.map((cell: any) => {
                if (cell.dataIndex === 'permissions') {
                  return {
                    ...cell,
                    render: (renderPermissions: Permission[], permissionObject: PermissionsByObject) => (
                      <Select
                        mode="multiple"
                        placeholder={"Доступ запрещен"}
                        style={{width: "100%"}}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        value={renderPermissions.map((permission: Permission) => permission.id)}
                      >
                        {fullPermissions
                          .filter((it: PermissionsByObject) => it.name == permissionObject.name)[0].permissions.map((permission: Permission) =>
                          <Select.Option key={permission.id}
                                         value={permission.id}>{permission.name}</Select.Option>)}
                      </Select>
                    ),
                  };
                } else {
                  return cell;
                }
              })}
              dataSource={[...currentRolePermissions]}
              loading={isLoadingRole}
              pagination={false}
            />
          </Card>
          <Card title='Пользователи в этой роли' bordered={true}>
            <Table
              rowKey='id'
              columns={usersColumns}
              dataSource={users}
              loading={isLoadingRole}
              pagination={false}
            />
          </Card>
        </div>
      )}
      {isChanged && (
        <>
          <Divider/>
          <div className='text-align-right'>
            <Button type='primary' style={contractButtonStyle} onClick={() => onUpdate()}>
              Сохранить
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Role;
