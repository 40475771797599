import { Moment } from 'moment';
import { ClientDoctype } from './api/ClientType';
import {PagedList} from "./PagedList";
import {ArtObject} from "./ArtObject";
import {User} from "./User";

export type ClientType = {
  id: string;
  surname?: string;
  name?: string;
  patronymic?: string;
  phoneNumber?: string;
  email?: string;
  isCompany: boolean;
  addressCountry?: string;
  addressCity?: string;
  address?: string;
  passportType?: ClientDoctype;
  passportSeries?: string;
  passportNumber?: string;
  passportIssueDate?: Moment;
  passportIssueBy?: string;
  passportIssueCode?: string;
  companyName?: string;
  companyDetails?: string;
  companyPersonTitle?: string;
  companyPersonTitle2?: string;
  misc?: string;
  paymentDetails?: string;
  createdAt?: Moment;
  createdBy?: User;
  modifiedAt?: Moment;
  modifiedBy?: User;
};

export const emptyClient: ClientType = {
  id: '',
  isCompany: false,
};

export const emptyClientsPage: PagedList<ArtObject> = {
  items: [],
  page: {
    page: 1,
    pageSize: 20,
    total: 0,
  },
};
