import React, {FC, useEffect} from 'react';
import {Button, Row, Col, Form, Space, InputNumber, DatePicker, Input, Select} from 'antd';
import MaskedInput from 'antd-mask-input';

interface SearchParams {
  fields: any,
  action: any,
  form: any
}

const Search: FC<SearchParams> = ({ fields, action, form }) => {
  const onReset = () => {
    form.resetFields();
    action();
  };

  const onSearch = async () => {
    await action(form.getFieldsValue());
  };

  const onKeyDown = async (data: any) => {
    if (data.code === 'Enter') {
      await action(form.getFieldsValue());
    }
  };

  const renderInput = (type: string, items: any[], width: number | undefined) => {
    switch (type) {
      case 'text':
        return <Input style={{width: width ? `${width}px` : ''}} />;
      case 'number':
        return <InputNumber style={{width: width ? `${width}px` : ''}} />;
      case 'year':
        return <DatePicker style={{width: width ? `${width}px` : ''}} picker='year' format={'YYYY'}/>;
      case 'phone':
        return <Input style={{width: width ? `${width}px` : ''}} />;
        // return <Input mask='+7 (111) 111-11-11' />;
      case 'select':
        return <Select style={{width: width ? `${width}px` : ''}}>
          {items.map(it => <Select.Option key={it.key} value={it.value}>{it.name}</Select.Option>)}
        </Select>;
    }
  };

  return (
    <Row gutter={16}>
      <Col className='text-align-left'>
        <Form form={form} layout='vertical' onKeyDown={onKeyDown}>
          <Space align='end'>
            {fields.map((field: any) => (
              <Form.Item key={field.name} label={field.label} name={field.name}>
                {renderInput(field.type, field.items || [], field.width)}
              </Form.Item>
            ))}

            <Form.Item>
              <Button type='primary' onClick={onSearch}>
                Поиск
              </Button>
            </Form.Item>

            <Form.Item>
              <Button onClick={onReset}>Сбросить</Button>
            </Form.Item>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};

export default Search;
