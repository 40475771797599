import React from "react";
import {Input} from "antd";

interface NumericInputProps {
  style?: React.CSSProperties;
  value?: string;
  autoComplete?: string;
  disabled?: boolean;
  onChange?: (value: string | undefined) => void;
}

const NumericInput = (props: NumericInputProps) => {
  const {value, onChange} = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value: inputValue} = e.target;

    let valueTemp = inputValue;
    valueTemp = valueTemp.replace(',', '.')

    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(valueTemp) || valueTemp === '' || valueTemp === '-') {
      onChange?.(valueTemp);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value?.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange?.(valueTemp?.replace(/0*(\d+)/, '$1'))
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Введите число"
      maxLength={16}
    />
  );
};

export default NumericInput