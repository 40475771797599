import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';
import { DATE_FORMAT } from '../../constants';
import {Expert} from "../../types/Expert";
import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";

export const authorsColumns = (authors: ArtObjectAuthor[]) => {
  return [
    {
      title: 'Автор',
      dataIndex: ['author', 'name'],
      editable: true,
      width: 150,
      type: 'auto-complete',
      options: authors.map(author => {
        return {value: author.name, id: author.id}
      }),
    }
  ]
};

export const contractColumns = [
  {
    title: 'Год договора',
    dataIndex: 'year',
  },
  {
    title: 'Номер договора',
    dataIndex: 'number',
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    render: (client: any) =>
      `${client.surname} ${client.name[0]}. ${client.patronymic ? `${client.patronymic[0]}.` : ''}`,
  },
];

export const acceptActColumns = [
  {
    title: 'Номер Акта',
    dataIndex: 'number',
    render: (number: any, row: any) => <Link to={`/expertise/accept-acts/${row.id}`}>{number}</Link>,
  },
  {
    title: 'Дата приема',
    dataIndex: 'date',
    render: (date: Moment | null) => date?.format(DATE_FORMAT),
  },
];
