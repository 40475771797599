import Api from '../utils/Api';
import moment from 'moment';
import {
  GivingActListResponse,
  GivingActType as APIGivingActType,
  GivingActShortType as APIGivingActShortType,
} from '../types/api/GivingActType';
import {GivingActShortType, GivingActType} from '../types/GivingActType';
import ContractService from "./ContractService";
import {PaginationSettings} from "../types/PaginationSettings";
import {PagedList} from "../types/PagedList";
import {ArtObject} from "../types/api/ArtObject";
import ArtObjectService from "./ArtObjectService";
import RequestUtils from "./RequestUtils";
import {ReceivingActShortType} from "../types/ReceivingActType";
import {ReceivingActShortType as APIReceivingActShortType} from "../types/api/ReceivingActType";

class GivingActService {
  static async saveNewObject(contractId: string, objectIds: string[]) {
    return Api.call(
      `/giving-act`,
      {
        method: 'POST',
        data: {contractId, objectIds},
      },
    ).then((response) => {
      return GivingActService.makeGivingActFromApi(response.data);
    });
  }

  static addObject(actId: string, objectId: string) {
    return Api.call(
      `/giving-act`,
      {
        method: 'POST',
        data: {actId, objectIds: [objectId]},
      },
    ).then((response) => {
      return GivingActService.makeGivingActFromApi(response.data);
    });
  }

  static async getAll(
      searchParams: any,
      pagination: PaginationSettings,
  ): Promise<PagedList<GivingActType>> {
    return Api.call(
        `/giving-act?page=${pagination.pageNumber}&size=${pagination.size}&` +
        RequestUtils.serialize(searchParams),
        {
          method: 'GET',
        },
    ).then((response) => {
      return {
        items: response.data.content.map((response: APIGivingActType) =>
            GivingActService.makeGivingActFromApi(response),
        ),
        page: {
          page: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        },
      } as PagedList<GivingActType>;
    });
  }

  static async getForContract(contractId: string): Promise<GivingActType[]> {
    const response: GivingActListResponse = await Api.call(`/contract/${contractId}/giving-atc`, {
      method: 'GET',
    }).then((response) => {
      return response.data as GivingActListResponse;
    });

    return response.map<GivingActType>((response: APIGivingActType) =>
      GivingActService.makeGivingActFromApi(response),
    );
  }

  static makeGivingActFromApi(act: APIGivingActType): GivingActType {
    return {
      id: act.id,
      number: act.number,
      date: moment(act.date),
      remarks: act.remarks,
      contractId: act.contractId,
      expertId: act.expertId,
      contract: act.contract ? ContractService.makeContractFromApi(act.contract) : undefined,
      experts: [],
      objects: act.objects ? act.objects.map((it: ArtObject) => ArtObjectService.makeObjectFromApi(it)) : undefined,
      objectNumbers: act.objectNumbers ?? []
    } as GivingActType;
  }

  static async getByObjectId(id: string): Promise<GivingActType[]> {
    const response: GivingActListResponse = await Api.call(
        `/art-object/${id}/giving-acts`,
        {
          method: 'GET',
        },
    ).then((response) => {
      return response.data as GivingActListResponse;
    });

    return response.map<GivingActType>((response: APIGivingActType) =>
        GivingActService.makeGivingActFromApi(response),
    );
  }

  static async getById(actId: string): Promise<GivingActType> {
    const response: APIGivingActType = await Api.call(
        `/giving-act/${actId}`,
        {
          method: 'GET',
        },
    ).then((response) => {
      return response.data as APIGivingActType;
    });

    return GivingActService.makeGivingActFromApi(response);
  }

  static async update(actId: any, data: any): Promise<GivingActType> {
    const response: APIGivingActType = await Api.call(
      `/giving-act/${actId}`,
      {
        method: 'POST',
        data: data
      },
    ).then((response) => {
      return response.data as APIGivingActType;
    });

    return GivingActService.makeGivingActFromApi(response);
  }

  static async getByObjectIds(objectIds: string[]): Promise<{ [index: string]: GivingActShortType }> {
    const response = await Api.call(
      `/giving-act/search-for-objects`,
      {
        method: 'POST',
        data: {objectIds: objectIds}
      },
      true
    ).then((response) => {
      return response.data as APIGivingActShortType[]
    });

    const result: { [index: string]: GivingActShortType } = {}
    response.forEach(value => {
      result[value.objectId] = value
    })
    return result
  }

  static async deleteObject(id: any, objectId: any) {
    const response: APIGivingActType = await Api.call(
      `/giving-act/${id}/object/${objectId}`,
      {method: 'DELETE',},
    ).then((response) => {
      return response.data as APIGivingActType;
    });

    return GivingActService.makeGivingActFromApi(response);
  }

  static async delete(id: any) {
    await Api.call(
      `/giving-act/${id}`,
      {method: 'DELETE',},
    ).then((response) => {
      return response.data as APIGivingActType;
    });
  }
}

export default GivingActService;
