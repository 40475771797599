import { useEffect } from "react";

const notClosableClasses = new Set([
  'ant-select-item-option-content',
  'ant-picker-cell-inner',
])

const pickerPanelClasses = new Set([
  'ant-picker-panel-container',
])

function isDatePickerPanel(target: any, count: number): boolean {
  if (count > 10)
    return false

  if (target.parentNode && pickerPanelClasses.has(target.parentNode.className))
    return true

  return target.parentNode ? isDatePickerPanel(target.parentNode, count + 1) : false
}

export function useOutsideClick(ref: any, onClickOut: (target: any) => void, deps: any[] = []){
  useEffect(() => {
    const onClick = ({target}: any) => {
      // console.log(!ref?.current?.contains(target), !notClosableClasses.has(target.className), !isDatePickerPanel(target, 0), ref, target)

      return !ref?.current?.contains(target) && !notClosableClasses.has(target.className)
        && !isDatePickerPanel(target, 0) && onClickOut?.(target)
    }
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, deps);
}