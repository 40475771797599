import {PriceListItem} from "./PriceListItem";
import moment, { Moment } from 'moment';

export type PriceList = {
  id: string,
  date: Moment;
  items: PriceListItem[];
};

export const emptyPriceList: PriceList = {
  id: "",
  date: moment(),
  items: []
};
