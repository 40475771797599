import Api from '../utils/Api';
import {AxiosResponse} from "axios";
import {Role, RoleListResponse} from "../types/Role";

class RoleService {
    static async getAll(): Promise<Role[]> {
        return Api.call(
            '/user-role',
            {
                method: 'GET',
            },
        ).then((response: AxiosResponse<RoleListResponse>) => {
            return response.data;
        });
    }

    static async getById(id: string): Promise<Role> {
        return Api.call(
            '/user-role/' + id,
            {
                method: 'GET',
            },
        ).then((response: any) => {
            return response.data as Role;
        });
    }

    static async update(role: Role): Promise<Role> {
        return Api.call(
            '/user-role/' + role.id,
            {
                method: 'POST',
                data: {
                    id: role.id,
                    permissions: role.permissions.map(it => it.id)
                },
            },
        ).then((response: any) => {
            return response.data as Role;
        });
    }
}

export default RoleService;