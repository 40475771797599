import {ArtObject as APIArtObject, ArtObjectListResponse} from '../types/api/ArtObject';
import {ArtObject, ArtObjectId, ArtObjectType, ArtObjectTypeShort} from '../types/ArtObject';
import Api from '../utils/Api';
import ArtObjectAuthorService from './ArtObjectAuthorsService';
import {PagedList} from '../types/PagedList';
import {PaginationSettings} from '../types/PaginationSettings';
import ContractService from './ContractService';
import {ArtObjectStatus} from "../types/api/ArtObjectStatus";
import ArtObjectStatusService from "./ArtObjectStatusService";
import ArtObjectExpertsHistoryService from "./ArtObjectExpertsHistoryService";
import RequestUtils from "./RequestUtils";
import UserService from "./UserService";
import moment from "moment";

class ArtObjectService {
  static async getAll(
    searchParams: any,
    pagination: PaginationSettings,
  ): Promise<PagedList<ArtObject>> {

    return Api.call(
      `/art-object?page=${pagination.pageNumber}&size=${pagination.size}&` +
        RequestUtils.serialize(searchParams),
      {
        method: 'GET',
      },
    ).then((response) => {
      return {
        items: response.data.content.map((response: APIArtObject) =>
          ArtObjectService.makeObjectFromApi(response),
        ),
        page: {
          page: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        },
      } as PagedList<ArtObject>;
    });
  }

  static async getForContract(contractId: string): Promise<ArtObject[]> {
    const response: ArtObjectListResponse = await Api.call(`/contract/${contractId}/objects`, {
      method: 'GET',
    }).then((response) => {
      return response.data as ArtObjectListResponse;
    });

    return response.map<ArtObject>((response: APIArtObject) =>
      ArtObjectService.makeObjectFromApi(response),
    );
  }

  static async update(id: string, obj: ArtObject): Promise<ArtObject> {
    return Api.call(`/art-object/${id}`, {
      data: obj,
      method: 'PUT',
    }).then((response) => ArtObjectService.makeObjectFromApi(response.data));
  }

  static async add(contractId: string, obj: ArtObject): Promise<ArtObject> {
    return Api.call(`/art-object`, {
      data: {artObject: obj, contractId},
      method: 'POST',
    }).then((response) => ArtObjectService.makeObjectFromApi(response.data));
  }

  static getObjectType(type: ArtObjectType | null) {
    // @ts-ignore
    return type ? `${ArtObjectType[type].toString()}` : '';
  }

  static getObjectTypeShort(type: ArtObjectType | null) {
    // @ts-ignore
    return type ? `${ArtObjectTypeShort[type].toString()}` : '';
  }

  static getObjectNameShort(obj: ArtObject): string {
    return (
      ArtObjectService.getObjectType(obj.type) +
      ` ${obj.number} ${obj.author?.name} ${obj.name} ` +
      ArtObjectService.getObjectSizes(obj)
    );
  }

  static getObjectName(obj: ArtObject): string {
    return (
      `${ArtObjectService.getObjectTypeShort(obj.type)} ` +
      `${obj.number} ${obj.author?.name} ${obj.name} ` +
      ArtObjectService.getObjectMaterials(obj) +
      ' / ' +
      ArtObjectService.getObjectTechniques(obj) +
      ' ' +
      ArtObjectService.getObjectSizes(obj)
    );
  }

  static getObjectNTFSFriendlyName(obj: ArtObject): string {
    const safeName = obj.name.replaceAll('.', ',').replaceAll('/', ';').replaceAll('\\', ';');
    return (
      `${ArtObjectService.getObjectTypeShort(obj.type)} ` +
      `${obj.number} ${obj.author?.name} ${safeName} ` +
      ArtObjectService.getObjectMaterials(obj) +
      ', ' +
      ArtObjectService.getObjectTechniques(obj) +
      ' ' +
      ArtObjectService.getObjectSizes(obj).replaceAll('.', ',')
    );
  }

  static getObjectDisplayName(obj: ArtObject): string {
    return `${obj.author?.name} ${ArtObjectService.getObjectDisplayNameShort(obj)}`;
  }

  static getObjectDisplayNameShort(obj: ArtObject): string {
    return (
      `${obj.name} ${ArtObjectService.getObjectMaterials(obj)}` +
      ' / ' +
      ArtObjectService.getObjectTechniques(obj) +
      ' ' +
      ArtObjectService.getObjectSizes(obj) +
      ' (см)'
    );
  }

  static getObjectListName(obj: ArtObject): string {
    return obj.name;
  }

  static getObjectTechniques(obj: ArtObject): string {
    return obj.techniques.map((it) => it.name).join(', ');
  }

  static getObjectMaterials(obj: ArtObject): string {
    return obj.materials.map((it) => it.name).join(', ');
  }

  static getObjectSizes(obj: ArtObject): string {
    return obj.height + '×' + obj.width + (obj.depth ? '×' + obj.depth : '');
  }

  static async getById(id: string): Promise<ArtObject> {
    const response: APIArtObject = await Api.call(`/art-object/${id}`, {
      method: 'GET',
    }).then((response) => {
      return response.data as APIArtObject;
    });

    return ArtObjectService.makeObjectFromApi(response);
  }

  static async addLinkEntity(id: any, data: any, sourceName?: string): Promise<ArtObject> {
    const response: APIArtObject = await Api.call(`/art-object/${id}/${sourceName}`, {
      method: 'PUT',
      data: data,
    }).then((response) => {
      return response.data as APIArtObject;
    });

    return ArtObjectService.makeObjectFromApi(response);
  }

  static async updateLinkEntity(id: any, data: any, sourceName?: string): Promise<ArtObject> {
    const response: APIArtObject = await Api.call(`/art-object/${id}/${sourceName}`, {
      method: 'POST',
      data: data,
    }).then((response) => {
      return response.data as APIArtObject;
    });

    return ArtObjectService.makeObjectFromApi(response);
  }

  static async deleteLinkEntity(id: any, entityId: any, sourceName?: string): Promise<ArtObject> {
    const response: APIArtObject = await Api.call(`/art-object/${id}/${sourceName}/${entityId}`, {
      method: 'DELETE',
    }).then((response) => {
      return response.data as APIArtObject;
    });

    return ArtObjectService.makeObjectFromApi(response);
  }

  static async findLastNumber() {
     return await Api.call(`/art-object/last-number`, {
      method: 'GET',
    }).then((response) => {
      return response.data as number;
    });
  }

  static async delete(id: any): Promise<void> {
    await Api.call(`/art-object/${id}`, {
      method: 'DELETE',
    }).then((response) => {
      return response.data as APIArtObject;
    });
  }

  static makeObjectIdFromApi(object: APIArtObject): ArtObjectId {
    return {
      id: object.id,
    }
  }

  static makeObjectFromApi(object: APIArtObject): ArtObject {
    return {
      id: object.id,
      contract: object.contract ? ContractService.makeContractFromApi(object.contract) : undefined,
      number: object.number,
      type: object.type ? (object.type as ArtObjectType) : null,
      name: object.name,
      description: object.description,
      damages: object.damages,
      remarks: object.remarks,
      height: object.height,
      width: object.width,
      depth: object.depth,
      displayAuthors: object.displayAuthors,
      expertsHistory: object.experts ? object.experts.map(expert => ArtObjectExpertsHistoryService.makeFromApi(expert)) : [],
      linkedObject: object.linkedObject
        ? ArtObjectService.makeObjectFromApi(object.linkedObject)
        : null,
      author: object.author ? ArtObjectAuthorService.makeObjectFromApi(object.author) : null,
      techniques: object.techniques,
      materials: object.materials,
      statusHistory: object.statusHistory ? object.statusHistory.map((status: ArtObjectStatus) => ArtObjectStatusService.makeFromApi(status)) : [],
      createdBy: (object.createdBy ? UserService.makeUserFromApi(object.createdBy) : undefined),
      createdAt: object.createdAt ? moment(object.createdAt) : undefined,
      modifiedBy: (object.modifiedBy ? UserService.makeUserFromApi(object.modifiedBy) : undefined),
      modifiedAt: object.modifiedAt ? moment(object.modifiedAt) : undefined,
    } as ArtObject;
  }
}

export default ArtObjectService;
