import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';

import { DATE_FORMAT } from '../../constants';

export const issuingActListColumns = [
  {
    title: 'Акт',
    dataIndex: 'number',
    render: (_: any, record: any) => (
      <Link to={`/expertise/issuing-acts/${record.id}`}>
        {`${record.date.year()} | ${record.number}`}
      </Link>
    ),
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
  {
    title: 'Договор',
    dataIndex: 'contract',
  },
  {
    title: 'Эксперт',
    dataIndex: 'experts',
    render: (experts: any) =>
      experts.map((expert: any) => {
        return (
          <>
            <span>{`${expert.surname} ${expert.name[0]}. ${
              expert.patronymic ? `${expert.patronymic[0]}.` : ''
            }`}</span>{' '}
            <br />
          </>
        );
      }),
  },
];
