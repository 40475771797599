import {ExpertiseXRAYComparison} from '../types/ExpertiseXRAYComparison';
import {ExpertiseXRAYComparison as ApiExpertiseXRAYComparison} from '../types/api/ExpertiseXRAYComparison';
import Api from '../utils/Api';
import moment from 'moment';
import ArtObjectService from './ArtObjectService';

class ExpertiseXRAYComparisonService {
  private static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj)
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  static async getAll(searchParams: any, extended = false): Promise<ExpertiseXRAYComparison[]> {
    const api = extended ? 'expertise-xray-comparison/extended' : 'expertise-xray-comparison'
    const response = await Api.call(`/${api}?` + ExpertiseXRAYComparisonService.serialize(searchParams), {
      method: 'GET',
    });

    return response.data.map((it: ApiExpertiseXRAYComparison) => ExpertiseXRAYComparisonService.makeFromApi(it, extended));
  }

  static makeFromApi(expertise: ApiExpertiseXRAYComparison, extended = false): ExpertiseXRAYComparison {
    return {
      id: expertise.id,
      date: moment(expertise.date),
      comparisonResult: expertise.comparisonResult !== null && expertise.comparisonResult !== undefined ? expertise.comparisonResult.toString() : "null",
      expertName: expertise.expertName,
      object: extended ? (expertise.object ? ArtObjectService.makeObjectFromApi(expertise.object) : undefined)
        : (expertise.object ? ArtObjectService.makeObjectIdFromApi(expertise.object) : undefined),
    } as ExpertiseXRAYComparison;
  }
}

export default ExpertiseXRAYComparisonService;
