import Api from '../utils/Api';
import moment from 'moment';
import {FinanceActListResponse, FinanceActType as APIFinanceActType,} from '../types/api/FinanceActType';
import {FinanceActType} from '../types/FinanceActType';
import ContractService from "./ContractService";
import {PaginationSettings} from "../types/PaginationSettings";
import {PagedList} from "../types/PagedList";
import FinanceActItemService from "./FinanceActItemService";
import {FinanceActItemType} from "../types/api/FinanceActItemType";
import {ArtObject} from "../types/api/ArtObject";
import ArtObjectService from "./ArtObjectService";
import RequestUtils from "./RequestUtils";
import UserService from "./UserService";
import {GivingActType} from "../types/GivingActType";
import {GivingActListResponse, GivingActType as APIGivingActType} from "../types/api/GivingActType";

class FinanceActService {
  static async upsert(
    contractId: string,
    objectIds: string[],
    formData: any,
    actId?: string,
  ): Promise<FinanceActType> {
    return Api.call(
      `/finance-act/${actId ? actId : ''}`,
      {
        method: 'POST',
        data: {contractId, objectIds, values: {...formData, act: undefined}, act: formData.act},
      },
    ).then((response) => {
      return FinanceActService.makeFinanceActFromApi(response.data);
    });
  }

  static async getAll(
      searchParams: any,
      pagination: PaginationSettings,
  ): Promise<PagedList<FinanceActType>> {
    return Api.call(
        `/finance-act/?page=${pagination.pageNumber}&size=${pagination.size}&` +
        RequestUtils.serialize(searchParams),
        {
          method: 'GET',
        },
    ).then((response) => {
      return {
        items: response.data.content.map((response: APIFinanceActType) =>
            FinanceActService.makeFinanceActFromApi(response),
        ),
        page: {
          page: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        },
      } as PagedList<FinanceActType>;
    });
  }

  static async getForContract(contractId: string): Promise<FinanceActType[]> {
    const response: FinanceActListResponse = await Api.call(`/contract/${contractId}/finance-atc`, {
      method: 'GET',
    }).then((response) => {
      return response.data as FinanceActListResponse;
    });

    return response.map<FinanceActType>((response: APIFinanceActType) =>
      FinanceActService.makeFinanceActFromApi(response),
    );
  }

  static makeFinanceActFromApi(act: APIFinanceActType): FinanceActType {
    return {
      id: act.id,
      number: act.number,
      date: moment(act.date),
      remarks: act.remarks,
      contract: act.contract ? ContractService.makeContractFromApi(act.contract) : undefined,
      items: act.items ? act.items.map((it: FinanceActItemType) => FinanceActItemService.makeFinanceActItemFromApi(it)) : [],
      objects: act.objects ? act.objects.map((it: ArtObject) => ArtObjectService.makeObjectFromApi(it)) : [],
      createdBy: (act.createdBy ? UserService.makeUserFromApi(act.createdBy) : undefined),
      createdAt: act.createdAt ? moment(act.createdAt) : undefined,
      modifiedBy: (act.modifiedBy ? UserService.makeUserFromApi(act.modifiedBy) : undefined),
      modifiedAt: act.modifiedAt ? moment(act.modifiedAt) : undefined,
    } as FinanceActType;
  }

  static async getById(actId: string): Promise<FinanceActType> {
    const response: APIFinanceActType = await Api.call(
        `/finance-act/${actId}`,
        {
          method: 'GET',
        },
    ).then((response) => {
      return response.data as APIFinanceActType;
    });

    return FinanceActService.makeFinanceActFromApi(response);
  }

  static async update(actId: any, data: any): Promise<FinanceActType> {
    const response: APIFinanceActType = await Api.call(
      `/finance-act/${actId}`,
      {
        method: 'POST',
        data: data
      },
    ).then((response) => {
      return response.data as APIFinanceActType;
    });

    return FinanceActService.makeFinanceActFromApi(response);
  }

  static async delete(actId: any) {
    const response: APIFinanceActType = await Api.call(
      `/finance-act/${actId}`,
      {method: 'DELETE',},
    ).then((response) => {
      return response.data as APIFinanceActType;
    });
  }

  static async getByObjectId(id: string): Promise<FinanceActType[]> {
    const response: FinanceActListResponse = await Api.call(
      `/art-object/${id}/finance-acts`,
      {
        method: 'GET',
      },
    ).then((response) => {
      return response.data as FinanceActListResponse;
    });

    return response.map<FinanceActType>((response: APIFinanceActType) =>
      FinanceActService.makeFinanceActFromApi(response),
    );
  }
}

export default FinanceActService;
