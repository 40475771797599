import React, { FC, useState } from 'react';
import {Form, Row, Col, Space, DatePicker, Select, Button, Card, Table, Image} from 'antd';
import moment from 'moment';

import DashboardContainer from '../../components/DashboardContainer';
import { DATE_FORMAT } from '../../constants';
import WeeklyReportService from "../../api/WeeklyReportService";
import {ReportWeeklyObjectsItem} from "../../types/ReportWeeklyObjectsItem";
import {Expert} from "../../types/Expert";
import {ArtObjectStatus} from "../../types/ArtObjectStatus";
import ArtObjectStatusService from "../../api/ArtObjectStatusService";
import ResizableTable from "../../components/ResizableTable";

const { RangePicker } = DatePicker;
const { Option } = Select;

const departmentsDemo = [
  {
    id: 'OZ',
    designation: 'ОЖ',
    name: 'Отдел живописи',
  },
  {
    id: 'OG',
    designation: 'ОГ',
    name: 'Отдел графики',
  },
  {
    id: 'DPI',
    designation: 'ОИ',
    name: 'Отдел иконописи',
  },
  {
    id: 'OI',
    designation: 'ДПИ',
    name: 'Отдел ДПИ',
  }
];

const WeeklyReport: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [departments] = useState<any>(departmentsDemo);
  const [reportRows, setReportRows] = useState<ReportWeeklyObjectsItem[]>([])

  const reportWeeklyObjectsColumns = [
    {
      title: 'Дата',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.receivingAct.date.format("YYYY-MM-DD")
    },
    {
      title: '№ вещи',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.artObject.number
    },
    {
      title: 'Автор',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.artObject.author?.name
    },
    {
      title: 'Название',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.artObject.name
    },
    {
      title: 'Материал / Техника',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.artObject.techniques.map((it) => it.name).join(", ") + ";"  + reportRow.artObject.materials.map((it) => it.name).join(", ") + ";" + reportRow.artObject.width + "x" + reportRow.artObject.height + (reportRow.artObject.depth ? "x" + reportRow.artObject.depth : "")
    },
    {
      title: 'Номер договора',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.receivingAct.contract.year + "/" + reportRow.receivingAct.contract.number
    },
    {
      title: 'Подписан',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.receivingAct.contract.signedAt.format("YYYY-MM-DD")
    },
    {
      title: 'Клиент',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) =>
          `${reportRow.receivingAct.contract.client.surname}
              ${reportRow.receivingAct.contract.client.name ? reportRow.receivingAct.contract.client.name[0] + '.' : ''}
              ${reportRow.receivingAct.contract.client.patronymic ? reportRow.receivingAct.contract.client.patronymic[0] + '.' : ''}
          `
    },
    {
      title: 'Акт приема',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.receivingAct.number
    },
    {
      title: 'Акт выдачи',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => reportRow.gibingAct?.number
    },
    {
      title: 'Эксперт',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => {
        const expert: Expert | null = reportRow.artObject.expertsHistory[reportRow.artObject.expertsHistory.length - 1]?.expert;
        if (!expert) {
          return "";
        }

        return expert.surname  + ' ' + expert.name[0]  + '.' + expert.patronymic[0] + '.'
      }
    },
    {
      title: 'Результат',
      width: 100,
      render: (reportRow: ReportWeeklyObjectsItem) => {
        const status: ArtObjectStatus | null = reportRow.artObject.statusHistory[reportRow.artObject.statusHistory.length - 1];
        if (!status || !["EZ", "OTV"].includes(status.code)) {
          return "";
        }

        return ArtObjectStatusService.mapStatus(status.code)
      }
    },
  ];

  const fetchReportItems = async (searchParams: any) => {
    return WeeklyReportService.getAll(searchParams)
  };

  const openReport = () => {
    setIsLoading(true)
    setReportRows([])
    form.validateFields().then(() => {
      const formValues = form.getFieldsValue()
      fetchReportItems({
        dateFrom: formValues.dateRange[0].format("YYYY-MM-DD 00:00:00"),
        dateTo: formValues.dateRange[1].format("YYYY-MM-DD 00:00:00"),
        department: formValues.department
      }).then((reportItems: ReportWeeklyObjectsItem[]) => {
        setReportRows(reportItems)
        setIsLoading(false)
      })
    });
  };

  return (
    <DashboardContainer title='Недельный отчёт' className='text-align-left'>
      <Row>
        <Col>
          <Form form={form} name='objectForm' layout='vertical'>
            <Space align='end'>
              <Form.Item
                label='Интервал'
                name='dateRange'
                rules={[{ required: true, message: 'Введите дату' }]}>
                <RangePicker
                  className='full-width'
                  placeholder={['С:', 'По:']}
                  format={DATE_FORMAT}
                  onCalendarChange={(params: any) =>
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      dateRange: [moment(params?.[0]), moment(params?.[0]).add(7, 'days')],
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                label='Отдел'
                name='department'
                rules={[{ required: true, message: 'Выберите отдел' }]}
                style={{ minWidth: '150px' }}>
                <Select placeholder='Выберите отдел'>
                  {departments.map((department: any) => (
                    <Option key={department.id} value={department.id}>
                      {department.designation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button type='primary' className='full-width' onClick={openReport}>
                  Открыть отчёт
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Col>
      </Row>
      <>
        {reportRows.length ? (
            <>
              <Row>
                <Col span={24}>
                  <Card title='Акты приема' bordered={true}>
                    <ResizableTable
                        rowKey='id'
                        columns={reportWeeklyObjectsColumns}
                        dataSource={reportRows}
                        loading={isLoading}
                        pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
            </>
        ) : (
            <>
            </>
        )}
      </>
    </DashboardContainer>
  );
};

export default WeeklyReport;
