import moment, { Moment } from 'moment';
import {PagedList} from "./PagedList";
import {ContractType, emptyContract} from "./ContractType";
import {Expert} from "./Expert";
import {ArtObject} from "./ArtObject";

export type GivingActShortType = {
  id: string;
  number: string;
  objectId: string
};

export type GivingActType = {
  id: string;
  number: string;
  date: Moment;
  remarks: string;
  contractId: string;
  expertId: string;
  contract: ContractType;
  experts: Expert[];
  objects: ArtObject[];
  objectNumbers: string[];
};

export const emptyGivingAct: GivingActType = {
  id: '',
  number: '',
  date: moment(),
  remarks: '',
  contractId: '',
  expertId: '',
  contract: emptyContract,
  experts: [],
  objects: [],
  objectNumbers: [],
};

export const emptyGivingActPage: PagedList<GivingActType> = {
  items: [],
  page: {
    page: 1,
    pageSize: 20,
    total: 0,
  },
};
