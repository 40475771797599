import React, {FC, useEffect, useState} from 'react';
import DashboardContainer from "../../components/DashboardContainer";
import {Col, Row, Table} from "antd";
import {Role as RoleType, RoleListResponse} from "../../types/Role";
import Role from "./Role";
import {useHistory, useParams} from "react-router-dom";
import RoleService from "../../api/RoleService";

export const rolesColumns = [
    {
        title: 'Роль',
        dataIndex: 'name',
    },
];

const Roles: FC = () => {
    const historyRoles = useHistory();
    const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(true);
    const [roles, setRoles] = useState<RoleType[]>([]);
    const { roleId } = useParams<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([roleId]);

    console.log(selectedRowKeys)

    const fetchRoles = async  () => {
        setIsLoadingRoles(true);
        const roles: RoleType[] = await RoleService.getAll();
        setRoles(roles);
        setIsLoadingRoles(false);
    }

    useEffect(() => {
        fetchRoles().then()
    }, []);

    return (
        <DashboardContainer title='Список ролей' className='text-align-left'>
            <Row>
                {roles.length ? (
                    <Col span={10}>
                        <Table
                            rowKey='id'
                            columns={rolesColumns}
                            dataSource={roles}
                            loading={isLoadingRoles}
                            pagination={false}
                            rowSelection={{
                                type: 'radio',
                                selectedRowKeys: selectedRowKeys,
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedRowKeys(selectedRowKeys);
                                    historyRoles.push(`/admin/user-roles/${selectedRows[0].id}`);
                                },
                            }}
                        />
                    </Col>
                ) : (
                    <></>
                )}
                <Col span={12} offset={1}>
                    <Role />
                </Col>
            </Row>
        </DashboardContainer>
    );
};

export default Roles;
