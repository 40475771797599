import React, {FC, useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Table} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import DashboardContainer from '../../components/DashboardContainer';
import Search from '../../widgets/Search';
import {acceptActListColumns} from './Columns';
import {PagedList} from "../../types/PagedList";
import {emptyReceivingActPage, ReceivingActType} from "../../types/ReceivingActType";
import {defaultPagination, PaginationSettings} from "../../types/PaginationSettings";
import ReceivingActService from "../../api/ReceivingActService";
import {ContractType} from "../../types/ContractType";
import AuthService from "../../api/AuthService";
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import PageUtils from "../../utils/PageUtils";

const searchData = [
  {name: 'number', label: 'Акт', type: 'text'},
  {name: 'year', label: 'Год', type: 'year'},
  {name: 'contract', label: 'Договор', type: 'text'},
  {name: 'expert', label: 'Фамилия Эксперта', type: 'text'},
];

const AcceptActList: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [acceptActList, setAcceptActList] = useState<PagedList<ReceivingActType>>(emptyReceivingActPage);
  const user = useSelector(selectUserState);

  const fetchActs = async (searchParams: any, pagination: PaginationSettings) => {
    setIsLoading(true);

    const receivingActs = await ReceivingActService.getAll(searchParams, pagination);

    setAcceptActList(receivingActs);
    setIsLoading(false);
  };

  const {onSearchChange, onPaginationChange, form} = PageUtils('/expertise/accept-acts', fetchActs)

  return (
    <DashboardContainer
      title='Акты Приема'
      buttons={[
        <Link key='1' to={`/expertise/accept-acts/new`}>
          <Button disabled={!AuthService.checkPermission(user.permissions, "RECEIVINGACTS", "WRITE")}
                  icon={<PlusOutlined/>}>Новый Акт Приема</Button>
        </Link>,
      ]}>
      <Search fields={searchData} action={onSearchChange} form={form}/>

      <Table
        rowKey='id'
        pagination={{
          pageSize: acceptActList.page.pageSize,
          current: acceptActList.page.page,
          total: acceptActList.page.total,
          onChange: onPaginationChange,
        }}
        columns={acceptActListColumns.map((cell: any) => {
          if (cell.dataIndex === 'contract') {
            return {
              ...cell,
              render: (contract: ContractType) => (
                <Link target="_blank" to={`/expertise/contracts/${contract.id}`}>
                  {`${contract.number}/${contract.year}`}
                </Link>
              ),
            };
          } else {
            return cell;
          }
        })}
        dataSource={acceptActList.items}
        loading={isLoading}
      />
    </DashboardContainer>
  );
};

export default AcceptActList;
