import React, {FC, useEffect, useMemo, useState} from 'react';
import {Table} from 'antd';
import './styles.scss';
import type {ResizeCallbackData} from 'react-resizable';
import {Resizable} from 'react-resizable';
import type {ColumnsType} from 'antd/es/table';

const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
    width: number;
  },
) => {
  const {onResize, width, ...restProps} = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{enableUserSelectHack: false}}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const ResizableTable: FC<any> = (props) => {
  const [columns, setColumns] = useState<ColumnsType>([]);

  useEffect(() => {
    setColumns(props.columns)
  }, [props.columns])

  const handleResize = (index: number) => (_: React.SyntheticEvent<Element>, {size}: ResizeCallbackData) => {
    const newColumns = [...columns];
    newColumns[index] = {
      ...newColumns[index],
      width: size.width,
    };
    setColumns(newColumns);
  };

  const mergeColumns: ColumnsType<any> = useMemo(() => {
    return columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column: ColumnsType[number]) => {
        return {
          width: column.width,
          onResize: handleResize(index) as React.ReactEventHandler<any>,
        }
      }
    }))
  }, [columns]);

  return (
    <Table {...props}
           components={{
             header: {
               cell: ResizableTitle,
             },
           }}
           columns={mergeColumns}
    />
  );
};

export default ResizableTable;
