import Api from '../utils/Api';

export type MaterialsItem = {
  id?: string;
  name: string;
};
export type MaterialsListResponse = MaterialsItem[];

class MaterialsService {
  static async getAll(): Promise<MaterialsListResponse> {
    return Api.call('/dictionary/material', {
      method: 'GET',
    }).then((response) => {
      return response.data as MaterialsListResponse;
    });
  }

  static async addMaterial(material: MaterialsItem): Promise<MaterialsItem> {
    return Api.call('/dictionary/material', {
      data: material,
      method: 'POST',
    }).then((response) => {
      return response.data as MaterialsItem;
    });
  }

  static async editMaterial(material: MaterialsItem, id?: string): Promise<MaterialsItem> {
    return Api.call('/dictionary/material/' + id, {
      data: material,
      method: 'POST',
    }).then((response) => {
      return response.data as MaterialsItem;
    });
  }

  static async deleteMaterial(id?: string): Promise<void> {
    Api.call('/dictionary/material/' + id, {
      method: 'DELETE',
    });
  }
}

export default MaterialsService;
