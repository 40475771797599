import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import {PriceListItem} from "../../types/PriceListItem";

const preparePrice = (item: PriceListItem): string => {
  return item.minPrice ? `${item.minPrice} - ${item.maxPrice} Руб.` : ''
};

export const priceColumns = [
  {
    title: 'Индекс',
    dataIndex: 'number',
    type: 'text',
    width: 50,
  },
  {
    title: 'Название',
    dataIndex: 'name',
    type: 'text',
    width: 400,
  },
  {
    title: 'Стоимость',
    width: 100,
    render: (column: any, item: PriceListItem) => preparePrice(item),
    type: 'text',
  },
];
