import userSlice from '.';
import {AppThunk} from '../../types';
import {UserData} from "../../api/AuthService";
import jwtDecode from "jwt-decode";

const {eventSetInfo, eventLogin, logout} = userSlice.actions;

export const login = (response: UserData): AppThunk => async (dispatch) => {
  dispatch(eventLogin({
    token: response.token,
    isLoggedIn: true,
    roles: response.roles,
    username: response.fullName,
    permissions: response.permissions
  }))
};

export const readToken = (token?: string): AppThunk => async (dispatch) => {
  const authData: UserData = jwtDecode(token || "");
  return dispatch(eventSetInfo({
    isLoggedIn: true,
    token: token,
    roles: authData.roles,
    username: authData.fullName,
    permissions: authData.permissions
  }));
};

export const logoutAsync = (): AppThunk => async (dispatch) => {
  dispatch(logout());
};
