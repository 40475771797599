import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Form, FormInstance, Popconfirm, Space, Table, Tooltip} from 'antd';
import {CheckOutlined, CloseOutlined, DeleteOutlined} from '@ant-design/icons';
import {v4 as uuid} from 'uuid';
import EditableCell from './EditableCell';
import {useOutsideClick} from "../../utils/useOutsideClick";

interface IEditableTable {
  columns: any[];
  dataSource: any[];
  activeKey: string | number;
  setActiveKey: any;
  dataPattern: any;
  buttonCaption?: string;
  isLoading?: boolean;
  rowKey?: string;
  addAction: (data: any, record: any, sourceName?: string) => void;
  editAction: (data: any, record: any, sourceName?: string) => void;
  deleteAction: (data: any, record: any, sourceName?: string) => void;
  setActiveTable?: any;
  tableName?: string;
  addable?: boolean;
  sourceName?: string;
  disableEdit?: boolean;
  oneEditButton?: boolean;
}

const RowFn: React.FC<any> = ({
    children,
    activeKey,
    data,
    changedRecord,
    handleCancelBtn,
    handleConfirmBtn,
    columns,
    actions,
    isEditing,
    rowRecord,
    ...restProps
}) => {
  const ref: any = useRef();

  useOutsideClick(ref, (target) => {
    if (!ref?.current)
      return

    if (rowRecord.id === activeKey) {
      if (!changedRecord) {
        handleCancelBtn()
      } else {
        handleConfirmBtn(rowRecord)
      }
    } else {
      if (changedRecord && changedRecord.id === rowRecord.id) {
        handleConfirmBtn(rowRecord)
      }
    }
  }, [columns, actions]);

  return (
    <>
      <tr ref={ref} {...restProps}>
        {children}
      </tr>
    </>
  )
}

const EditableTable: React.FC<IEditableTable> = ({
  columns,
  dataSource,
  activeKey,
  setActiveKey,
  dataPattern,
  addAction,
  editAction,
  deleteAction,
  setActiveTable,
  tableName,
  isLoading,
  rowKey = 'id',
  buttonCaption = 'Добавить',
  addable = true,
  sourceName = undefined,
  disableEdit= false,
  oneEditButton= false,
}) => {
  const [form] = Form.useForm();

  const emptyRow = useMemo(() => {return {...dataPattern, empty: true, id: uuid() }}, [dataSource])
  const tableDataSource = addable ? [...dataSource, emptyRow] : dataSource

  const [data, setData] = useState<any[]>(tableDataSource);
  const [changedRecord, setChangedRecord] = useState<any | undefined>(undefined);

  useEffect(() => {
    setData(tableDataSource);
  }, [dataSource]);

  const isEditing = (record: any) => record.id === activeKey;

  const resetActiveKey = () => {
    setActiveKey('');
  };

  const handleEditBtn = (values: any) => {
    setActiveTable?.(tableName);
    form.setFieldsValue({...values});
    setActiveKey(values.id);
  };

  const onEdit = (record: any) => {
    editAction(record.rawForm, record, sourceName);
  };

  const onAdd = (record: any) => {
    addAction(record.rawForm, record, sourceName);
  };

  const handleConfirmBtn = (record: any, forceChanged?: boolean) => {
    form.validateFields().then(() => {
      if (record.empty || !record.id) {
        onAdd(record);
      } else if (changedRecord || forceChanged) {
        onEdit(record);
      }
      form.resetFields();

      resetActiveKey();
      setChangedRecord(undefined);
      record.rawForm = undefined;
    });
  };

  const handleCancelBtn = (record: any) => {
    setData(tableDataSource);

    form.resetFields();

    resetActiveKey();
    setChangedRecord(undefined);
    record.rawForm = undefined;
  };

  const deleteColumn = {
    dataIndex: 'delete',
    width: '0px',
    control: true,
    render: (_: any, record: any) => {
      const editable = isEditing(record);

      return editable && oneEditButton ? (
        <Space>
          <Tooltip title='Принять изменения'>
            <Button
              shape='circle'
              icon={<CheckOutlined/>}
              onClick={() => handleConfirmBtn(record)}
            />
          </Tooltip>

          <Tooltip title='Отменить изменения'>
            <Button shape='circle' icon={<CloseOutlined/>} onClick={() => handleCancelBtn(record)}/>
          </Tooltip>
        </Space>
      ) : <Popconfirm
        title='Вы уверены, что хотите удалить этот объект?'
        placement='topRight'
        okText='Удалить'
        cancelText='Отменить'
        onConfirm={() => deleteAction(form.getFieldsValue(), record, sourceName)}>
        <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          disabled={activeKey !== ''}
          onClick={() => setActiveTable?.(tableName)}
        />
      </Popconfirm>
    },
  };
  const actions = useMemo(
    () => disableEdit ? [] : [deleteColumn],
    [isEditing, handleCancelBtn, activeKey, handleConfirmBtn, handleEditBtn, deleteAction, form],
  );

  const onKeyDown = async (data: any, record: any) => {
    if (data.code === 'Enter') {
      handleConfirmBtn(record);
    }
    if (data.code === 'Escape') {
      handleCancelBtn(record)
    }
  };

  const mergedColumns = useMemo(
    () =>
      [...columns, ...actions].map((col) => {
        if (!col.editable) {
          return {
            ...col,
            onCell: (record: any) => ({
              record,
              control: col.control,
              empty: record.empty,
              editing: isEditing(record),
            }),
          };
        }
        return {
          ...col,
          onCell: (record: any) => ({
            record,
            inputType: col.type,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            options: col?.options,
            empty: record.empty,
            optionRender: col?.optionRender,
            filterOption: col?.filterOption,
            handleEditBtn: () => handleEditBtn(record),
            handleConfirmBtn: (changed: any) => handleConfirmBtn(record, changed),
            handleCancelBtn: () => handleCancelBtn(record),
            onKeyDown: (data: any) => onKeyDown(data, record),
            columnsLength: columns.length
          }),
        };
      }),
    [columns, actions, isEditing],
  );

  const onRow = (rowRecord: any, rowIndex: number | undefined) => {
    return {
      activeKey,
      data,
      changedRecord,
      handleCancelBtn,
      handleConfirmBtn,
      columns,
      actions,
      isEditing,
      rowRecord
    }
  }

  return (
    <>
      <Form form={form} component={false} onValuesChange={(e, e2) => {
        const record = data.filter(v => v.id === activeKey);
        if (record.length > 0) {
          setChangedRecord(record[0])
          record[0].rawForm = form.getFieldsValue()
        }
      }
      }>
        <Table
          loading={isLoading}
          rowKey={rowKey}
          components={{
            body: {
              cell: EditableCell,
              row: RowFn,
            }
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName={() => 'editable-row'}
          pagination={false}
          // @ts-ignore
          onRow={onRow}
        />
      </Form>
    </>
  );
};

export default EditableTable;
