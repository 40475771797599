import {PriceList} from "../types/PriceList";
import {PriceList as ApiPriceList} from "../types/api/PriceList";
import Api from "../utils/Api";
import moment from "moment/moment";
import {PriceListItem} from "../types/api/PriceListItem";
import PriceListItemService from "./PriceListItemService";

class PriceListService {
  static add = () => {
    return Api.call(
      `/price-list`,
      {
        method: 'POST',
      },
    ).then((response) => {
      return PriceListService.makePriceListFromApi(response.data);
    });
  }

  static delete = (selectedRowKey: any) => {
    return Api.call(
      `/price-list/${selectedRowKey}`,
      {
        method: 'DELETE',
      },
    ).then((response) => {
      return PriceListService.makePriceListFromApi(response.data);
    });
  }

  static async getAll(): Promise<PriceList[]> {
    return Api.call(
        `/price-list/all`,
        {
          method: 'GET',
        },
    ).then((response) => {
      return response.data.map((response: ApiPriceList) =>
          PriceListService.makePriceListFromApi(response),
      );
    });
  }

  static async getLast(): Promise<PriceList[]> {
    return Api.call(
      `/price-list/last`,
      {
        method: 'GET',
      },
    ).then((response) => {
      return response.data.map((response: ApiPriceList) =>
        PriceListService.makePriceListFromApi(response),
      );
    });
  }

  static makePriceListFromApi(priceList: ApiPriceList): PriceList {
    return {
      id: priceList.id,
      date: moment(priceList.date),
      items: priceList.items ?
        PriceListItemService.prepareItems(priceList.items.map((it: PriceListItem) => PriceListItemService.makePriceListItemFromApi(it))) :
        [],
    } as PriceList;
  }
}

export default PriceListService;
