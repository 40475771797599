import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {Button, Collapse, DatePicker, Form, Input, Row, Select, Typography} from 'antd';
import {CloseCircleOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {Moment} from "moment";
import {LocationState} from "../../ContractSingle/ContractSingle";
import {ClientType} from "../../../types/ClientType";
import ClientsService from "../../../api/ClientsService";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

interface ContractCreationProps extends React.HTMLAttributes<HTMLElement> {
  client: ClientType;
}

const ContractCreation: React.FC<ContractCreationProps> = ({client, ...restProps}) => {
  const history = useHistory();

  const [contractForm] = Form.useForm();
  const [isContractCreation, setIsContractCreation] = useState(false);

  const showContractCreation = () => {
    setIsContractCreation(true)
  }
  const handleCreation = () => {
    contractForm.validateFields()
      .then(async () => {
        const year = (contractForm.getFieldValue("contractYear") as Moment).year()
        history.push(`/expertise/contracts/new`, {clientId: client.id, year} as LocationState);
      })
  };
  const handleClose = () => {setIsContractCreation(false)}

  return (
    <>
      <Row justify="start" {...restProps}>
        {!isContractCreation ? <Button
            onClick={() => showContractCreation()}
            type='primary'>
            Создать новый договор
          </Button> :
          <>
            <Form form={contractForm} layout="inline">
              <Form.Item
                name='contractYear'
                label='Год'
                rules={[{required: true, message: 'Заполните поле'}]}
              ><DatePicker picker="year"/>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => handleCreation()}
                  type='primary'>
                  Создать
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={() => handleClose()}>
                  <CloseCircleOutlined/>
                </Button>
              </Form.Item>
            </Form>
          </>}
      </Row>
    </>
  );
};

export default ContractCreation;
