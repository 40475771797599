import React, {FC, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Button, Col, Form, Input, Row, Select, Space, Table, Typography,} from 'antd';
import {acceptActColumns, authorsColumns, contractColumns} from './Columns';

import SelectedModalTable from '../../widgets/SelectedModalTable';
import ContractService from "../../api/ContractService";
import {PaginationSettings} from "../../types/PaginationSettings";
import {ContractType} from "../../types/ContractType";
import {defaultPagedList, PagedList} from "../../types/PagedList";
import {ContractSearchType} from "../../types/api/ContractType";
import ArtObjectAuthorService from "../../api/ArtObjectAuthorsService";
import {ArtObjectType} from "../../types/ArtObject";
import EditableTable from "../../widgets/EditableTable";
import {expertColumns, materialColumns, technicColumns} from "../ObjectSingle/Columns";
import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import TechnicsService, {TechnicsItem} from "../../api/TechnicsService";
import MaterialsService, {MaterialsItem} from "../../api/MaterialsService";
import {Expert} from "../../types/Expert";
import ExpertsService from "../../api/ExpertsService";
import {ReceivingActType} from "../../types/ReceivingActType";
import ReceivingActService from "../../api/ReceivingActService";
import ArtObjectService from "../../api/ArtObjectService";
import moment from "moment";
import uuid from "react-uuid";
import DashboardContainer from 'components/DashboardContainer';
import NumericInput from "../../widgets/NumericInput";

const { TextArea } = Input;
const { Text } = Typography;
const { Title } = Typography;
const { Option } = Select;

const searchFields = {
  authors: [
    { name: 'name', label: 'Фамилия', type: 'text' },
    { name: 'surname', label: 'Имя', type: 'text' },
    { name: 'patronymic', label: 'Отчество', type: 'text' },
  ],
  experts: [
    { name: 'name', label: 'Фамилия', type: 'text' },
    { name: 'surname', label: 'Имя', type: 'text' },
    { name: 'patronymic', label: 'Отчество', type: 'text' },
  ],
  materials: [{ name: 'material', label: 'Материал', type: 'text' }],
  technics: [{ name: 'technic', label: 'Техника', type: 'text' }],
  contracts: [
    { name: 'number', label: 'Номер договора', type: 'number' },
    { name: 'clientSurname', label: 'Фамилия клиента', type: 'text' },
  ],
};

const contractButtonStyle: any = {
  position: 'absolute',
  top: '-52px',
  right: '113px',
};

const acceptActPattern: any = {
  id: 'new',
  number: 'new',
  date: null,
};

const AcceptActNew: FC<any> = () => {
  const location = useLocation<any>();
  const searchParams = new URLSearchParams(location.search)
  const contractId = searchParams.get('contractId')

  const [form] = Form.useForm();

  const [activeKey, setActiveKey] = useState<string | number>('');
  const [techniques, setTechniques] = useState<any[]>([]);
  const [materials, setMaterials] = useState<any[]>([]);
  const [experts, setExperts] = useState<any[]>([]);
  const [authors, setAuthors] = useState<any[]>([]);

  const [allAuthors, setAllAuthors] = useState<ArtObjectAuthor[]>([]);
  const [allTechniques, setAllTechniques] = useState<TechnicsItem[]>([]);
  const [allMaterials, setAllMaterials] = useState<MaterialsItem[]>([]);
  const [allExperts, setAllExperts] = useState<Expert[]>([]);

  const [contracts, setContracts] = useState<PagedList<ContractType>>(defaultPagedList);
  const [contract, setContract] = useState<any>([]);
  const [isContractVisible, setIsContractVisible] = useState<boolean>(false);
  const [isModalVisibleContracts, setIsModalVisibleContracts] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [acceptActs, setAcceptActs] = useState<ReceivingActType[]>([]);
  const [currentAcceptAct, setCurrentAcceptAct] = useState<any>({});
  const [acceptActSelectedRows, setAcceptActSelectedRows] = useState<any>([]);
  const [acceptActSelectedRowKeys, setAcceptActSelectedRowKeys] = useState<any>([]);

  const fetchAuthors = async () => {setAllAuthors(await ArtObjectAuthorService.getAll())};
  const fetchMaterials = async () => {setAllMaterials(await MaterialsService.getAll())};
  const fetchTechnics = async () => {setAllTechniques(await TechnicsService.getAll())};
  const fetchExperts = async () => {setAllExperts(await ExpertsService.getAll({}))};

  const fetchAcceptActs = async () => {
    if (contract && contract.length > 0)
      setAcceptActs(await ReceivingActService.getForContract(contract[0].id))
  }

  const fetchLastNumber = async () => {
    const number = await ArtObjectService.findLastNumber();
    form.setFieldValue('number', number + 1)
  };

  const resetAllForms = async () => {
    form.resetFields();

    setAuthors([])
    setTechniques([])
    setMaterials([])
    setExperts([])
  }

  const saveNewObject = (actId?: any) => {
    form.setFieldValue(["author", "name"], authors.map(v => v.author.name.trim()).join(", "))

    form.validateFields().then(() => {
      const data = {
        ...form.getFieldsValue(),
        contractId: contract[0].id,
        materials: materials.map(value => value.material),
        techniques: techniques.map(value => value.technique),
        experts: experts.map(value => value.expert.id),
      }

      ReceivingActService.saveNewObject(actId ?? acceptActSelectedRowKeys[0], data).then((response) => {
        if (currentAcceptAct.id === 'new') {
          setCurrentAcceptAct({})
          setAcceptActSelectedRows([])
          setAcceptActSelectedRowKeys([])
        }

        fetchAcceptActs()
        fetchLastNumber()
      }).then(value => {
        resetAllForms()
      })
    })
  }

  useEffect(() => {
    if (contractId) {
      ContractService.getById(contractId).then((response) => {
        setContract([response]);
      })
    }
    form.setFieldValue('type', 'OZ')
  }, [contractId]);

  useEffect(() => {
    fetchAcceptActs()
    setCurrentAcceptAct({});
    fetchLastNumber()
  }, [contract]);

  useEffect(() => {
    fetchAuthors();
    fetchMaterials();
    fetchTechnics();
    fetchExperts();
    setAllAuthors([]);
  }, [contract, currentAcceptAct]);

  const action = (data: ContractSearchType, pagination: PaginationSettings) => {
    ContractService.findBy(data, pagination).then((response) => {
      setContracts(response);
    });
  };

  const createNewAct = () => {
    setAcceptActs([...Array.from(new Set([acceptActPattern, ...acceptActs]))]);
    setAcceptActSelectedRowKeys(['new']);
    setAcceptActSelectedRows([acceptActPattern]);
    setCurrentAcceptAct(acceptActPattern);
    saveNewObject('new')
  };

  const tableParams = {
    selectedRows,
    selectedRowKeys,
    setSelectedRows,
    setSelectedRowKeys,
    searchAction: action,
  };

  function renderContract(contract: ContractType) {
    const client = contract.client;
    const fio = `${client.surname} ${client.name ? client.name[0] : ''}. ${client.patronymic ? client.patronymic[0] :''}`
    return `${contract.year}/${contract.number} ${fio}`
  }

  const dataParams = (rows: any[], set: any) => {
    return {
      addAction: (data: any, record: any, sourceName?: string) => {
        set([...rows, {id: uuid(), ...data}])
      },
      editAction: (data: any, record: any, sourceName?: string) => {
        set([...rows.filter((expert) => expert.id !== record.id), {id: uuid(), ...data}])
      },
      deleteAction: (data: any, record: any, sourceName?: string) => {
        set(rows.filter((expert) => expert.id !== record.id))
      },
    }
  };

  return (
    <DashboardContainer
      title={`Новый Акт приёма`}
      className='text-align-left'>
      <Space direction={"vertical"} style={{width: '100%'}} size={1}>
        <Row>
          <Space size={10}>
            <SelectedModalTable
              type='radio'
              modalTitle='Договоры'
              okButtonText='Выбрать'
              modalTableColumns={contractColumns}
              modalTableData={contracts}
              isModalVisible={isModalVisibleContracts}
              setIsModalVisible={setIsModalVisibleContracts}
              setData={setContract}
              searchFields={searchFields.contracts}
              {...tableParams}
            />
          </Space>

          <Space direction={"horizontal"}>
            <Button key={'1'} onClick={() => setIsModalVisibleContracts(true)}>
              Указать Договор
            </Button>
            <Link key='2' to={`/expertise/accept-acts/${currentAcceptAct.id}/print`} target={'_blank'}>
              <Button disabled={!(currentAcceptAct && currentAcceptAct.id) || currentAcceptAct === 'new'}>Печать акта</Button>
            </Link>
          </Space>
        </Row>

        <Row style={{marginTop: "10px"}}>
          {contract.length > 0 ? (
            <Text>
              Договор:{' '}
              <Typography.Link href={`/expertise/contracts/${contract[0].id}`}>
                {renderContract(contract[0])}
              </Typography.Link>
            </Text>
          ) : <></>
          }
        </Row>

        <Row>
          <Col span={18}>
            <Form className='std-margin-top' form={form} layout='horizontal'>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label='Тип'
                    name='type'
                    rules={[{required: true, message: 'Введите название'}]}>
                    <Select className='full-width'>
                      {Object.keys(ArtObjectType).map((key: string, index: any) => (
                        <Select.Option value={key} key={index}>
                          {
                            // @ts-ignore
                            ArtObjectType[key].toString()
                          }
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label='Учётное обозначение'
                    name='number'
                    rules={[{required: false, message: 'Введите название'}]}>
                    <Input/>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Form className='std-margin-top' form={form} layout='horizontal' style={{width: '100%'}}>
          <Row>
            <Col span={18}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name={['author', 'name']}
                    rules={[{required: true, message: 'Выберите автора'}]}>
                    <EditableTable
                      addable={true}
                      disableEdit={false}
                      columns={authorsColumns(allAuthors)}
                      dataSource={authors}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      dataPattern={{}}
                      sourceName={'authors'}
                      {...dataParams(authors, setAuthors)}
                    /> </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='experts'>
                    <EditableTable
                      addable={true}
                      disableEdit={false}
                      columns={expertColumns(allExperts)}
                      dataSource={experts}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      dataPattern={{'date': moment()}}
                      sourceName={'experts'}
                      {...dataParams(experts, setExperts)}
                    />
                  </Form.Item>
                </Col>
              </Row>

            </Col>
          </Row>
        </Form>

        <Row>
          <Col span={18}>
            <Form form={form}  layout='vertical' style={{width: '100%'}}>
              <Form.Item
                label='Название'
                name='name'
                rules={[{required: true, message: 'Введите название'}]}>
                <Input/>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row className='std-margin-top'>
          <Col span={12}>
            <Row>
              <Col span={12}>
                <Form  form={form}  layout='vertical'>
                  <Form.Item
                    name='materials'>
                    <EditableTable
                      addable={true}
                      disableEdit={false}
                      columns={materialColumns(allMaterials)}
                      dataSource={materials}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      dataPattern={{}}
                      sourceName={'materials'}
                      {...dataParams(materials, setMaterials)}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}>
                <Form form={form}  layout='vertical'>
                  <Form.Item
                    name='technics'>
                    <EditableTable
                      addable={true}
                      disableEdit={false}
                      columns={technicColumns(allTechniques)}
                      dataSource={techniques}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      dataPattern={{}}
                      sourceName={'technics'}
                      {...dataParams(techniques, setTechniques)}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div className='text-align-left'>
              <div className='std-margin-bottom'>
                <Title level={5}>Выберите Акт Приема или создайте новый</Title>
              </div>

              <Row>
                <Col span={12}>
                  <Table
                    rowKey='id'
                    rowSelection={{
                      type: 'radio',
                      selectedRowKeys: acceptActSelectedRowKeys,
                      onChange: (selectedRowKeys, selectedRows) => {
                        setAcceptActSelectedRows(selectedRows);
                        setAcceptActSelectedRowKeys(selectedRowKeys);
                        setCurrentAcceptAct(selectedRows[0]);
                      },
                    }}
                    bordered={true}
                    columns={acceptActColumns}
                    dataSource={acceptActs}
                  />
                </Col>

                <Col span={11} offset={1}>
                  <Space direction={"horizontal"}>
                    <Button
                      onClick={() => saveNewObject()}
                      disabled={acceptActSelectedRows.length == 0}>
                      Выбрать существ. акт
                    </Button>
                    <Button disabled={contract.length < 1} onClick={createNewAct}>
                      Создать новый акт
                    </Button>
                  </Space>
                </Col>
              </Row>

              <Space>

              </Space>
              <div className='std-margin-top'>

              </div>
            </div>
          </Col>

        </Row>

        <Form form={form}  layout='horizontal'>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={8}>
                  <Form.Item label='Высота (см)' name='height'>
                    <NumericInput autoComplete="off"/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Ширина (см)' name='width'>
                    <NumericInput autoComplete="off"/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Глубина (см)' name='depth'>
                    <NumericInput autoComplete="off"/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Form className='std-margin-top' form={form}  layout='vertical'>
          <Row>
            <Col span={18}>
              <Row>
                <Col span={8}>
                  <Form.Item label='Описание' name='description'>
                    <TextArea rows={2}/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Повреждения' name='damages'>
                    <TextArea rows={2}/>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Примечания' name='remarks'>
                    <TextArea rows={2}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <div className='text-align-right'>

        </div>

      </Space>
    </DashboardContainer>
  );
};

export default AcceptActNew;
