import React, {FC, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Card, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Space, Typography,} from 'antd';

import DashboardContainer from '../../components/DashboardContainer';

import {DATE_FORMAT} from '../../constants';
import {issuingActListDemo} from '../../__mocks__/IssuingActMock';
import {objectColumns} from './Columns';
import {emptyGivingAct, GivingActType} from "../../types/GivingActType";
import GivingActService from "../../api/GivingActService";
import AuthService from "../../api/AuthService";
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import ResizableTable from "../../components/ResizableTable";
import {ArtObject} from "../../types/ArtObject";
import {DeleteOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import SelectedModalTable from "../../widgets/SelectedModalTable";
import ArtObjectService from "../../api/ArtObjectService";
import {defaultPagedList, PagedList} from "../../types/PagedList";

const { TextArea } = Input;

const IssuingAct: FC = () => {
  const { id } = useParams<any>();
  const [form] = Form.useForm();
  const user = useSelector(selectUserState);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [issuingAct, setIssuingAct] = useState<GivingActType>(emptyGivingAct);
  const [issuingActObjects, setIssuingActObjects] = useState<any>(issuingActListDemo[0].objects);
  const [issuingActObjectsActiveKey, setIssuingActObjectsActiveKey] = useState<string | number>('');

  const [objects, setObjects] = useState<PagedList<ArtObject>>(defaultPagedList);
  const [selectedObject, setSelectedObject] = useState<any>([]);
  const [isModalVisibleObjects, setIsModalVisibleObjects] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [isChanged, setIsChanged] = useState<boolean>(false);

  const fetchAct = async (id?: string) => {
    if (!id) {
      setIssuingAct(emptyGivingAct);

      return;
    }
    setIsLoading(true);
    const act:GivingActType = await GivingActService.getById(id);
    setIssuingAct(act);
    setIsLoading(false);
  };

  const fetchObjects = (contractId: string) => {
    ArtObjectService.getForContract(contractId).then((response) => {
      const existObjects = new Set(issuingAct.objects.map(o => o.id))
      const notAddedObjects = response.filter(o => !existObjects.has(o.id))

      setObjects({
        items: notAddedObjects,
        page: {
          page: 1,
          pageSize: defaultPagedList.page.pageSize,
          total: notAddedObjects.length,
        }
      });
    });
  };

  useEffect(() => {
    fetchAct(id).then();
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(issuingAct);
    if (issuingAct.contract?.id) {
      fetchObjects(issuingAct.contract.id)
    }
  }, [form, issuingAct]);

  const addAction = () => {};

  const editAction = (edited: any, record: any) => {
    setIssuingActObjects((prevState: any) =>
      prevState.map((value: any) =>
        value.id === record.id
          ? { ...record, ...edited, author: JSON.parse(edited?.author) }
          : value,
      ),
    );
  };

  const deleteAction = (record: any) => {
    setIssuingActObjects((prevState: any) =>
      prevState.filter((value: any) => value.id !== record.id),
    );
  };

  const dataParams = {
    addAction,
    editAction,
    deleteAction,
    isLoading: false,
  };

  const onSave = () => {
    form.validateFields().then(async () => {
      const newData = await GivingActService.update(id, form.getFieldsValue())
      setIssuingAct(newData);
      setIsChanged(false)
    })
  };

  const removeObject = async (objectId: any) => {
    const updatedData = await GivingActService.deleteObject(id, objectId)
    setIssuingAct(updatedData);
  }

  const tableParams = {
    selectedRows,
    selectedRowKeys,
    setSelectedRows,
    setSelectedRowKeys,
    searchAction: () => {},
  };

  useEffect(() => {
    if (selectedObject.length > 0)
      GivingActService.addObject(id, selectedObject[0].id).then((response) => {
        setIssuingAct(response)
      })
  }, [selectedObject]);

  const hasWritePermissions = AuthService.checkPermission(user.permissions, "GIVINGACTS", "WRITE");
  const saveButton = hasWritePermissions && isChanged ? [
    <Button key='1' type='primary' onClick={() => onSave()}>
      Сохранить
    </Button>] : [];
  const printButton = [<Link key='1' to={`/expertise/issuing-acts/${id}/print`}>
    <Button>Печать</Button>
  </Link>];
  const buttons = [...saveButton, ...printButton];

  const deleteColumn = {
    title: '',
    dataIndex: 'delete',
    width: 100,
    render: (number: string | number, object: ArtObject) => {
      return <Popconfirm
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        title={`Подтвердите удаление объекта из списка.`}
        placement='topRight'
        okText='Удалить'
        cancelText='Отменить'
        okButtonProps={{danger: true, type: "default"}}
        onConfirm={() => removeObject(object.id)}>
        <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          disabled={!hasWritePermissions}
        />
      </Popconfirm>
    }
  }

  const issuingActColumns = [...objectColumns, deleteColumn]

  return (
    <DashboardContainer
      title={`Акт Выдачи ${issuingAct.number}`}
      buttons={buttons}
      className='text-align-left'>
      <>
        <Form
          form={form}
          name='objectForm'
          layout='vertical'
          onFieldsChange={() => setIsChanged(true)}>
          <Row>
            <Col span={10}>
              <Form.Item
                label='Номер Акта'
                name='number'
                rules={[{ required: true, message: 'Введите номер акта' }]}>
                <InputNumber className='full-width' />
              </Form.Item>

              <Form.Item
                label='Дата'
                name='date'
                rules={[{ required: true, message: 'Введите дату' }]}>
                <DatePicker className='full-width' format={DATE_FORMAT} />
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={10} offset={1}>
              <Form.Item>
                <Row className='std-margin-top'>
                  <Col>
                    <Space>
                      <Typography>
                        Договор:
                        <Link to={`/expertise/contracts/${issuingAct.contract.id}`}>
                          {` ${issuingAct.contract.signedAt.year()}/${issuingAct.contract.number}`}
                        </Link>
                      </Typography>

                      <Typography>
                        Клиент:
                        <Link to={`/expertise/clients/${issuingAct.contract.client.id}`}>
                          {`${issuingAct.contract.client.surname}
                                    ${issuingAct.contract.client.name ? issuingAct.contract.client.name[0] + '.' : ''}
                                    ${issuingAct.contract.client.patronymic ? issuingAct.contract.client.patronymic[0] + '.' : ''}
                                    `}
                        </Link>
                      </Typography>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item label='Примечания' name='remarks'>
                <TextArea className='full-width' rows={2} disabled={!hasWritePermissions} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Card title='Объекты экспертизы' bordered={false}>
          <Space className='text-align-left' direction={"vertical"} style={{width: '100%'}}>
            <Row key={'1'} style={{justifyContent: "right", width: '100%'}}>
              <Button
                icon={<PlusOutlined/>}
                onClick={() => setIsModalVisibleObjects(true)}>
                Добавить объект
              </Button>
            </Row>

            <SelectedModalTable
              type='radio'
              modalTitle='Объекты'
              modalTableColumns={objectColumns}
              modalTableData={objects}
              isModalVisible={isModalVisibleObjects}
              setIsModalVisible={setIsModalVisibleObjects}
              setData={setSelectedObject}
              searchFields={[]}
              {...tableParams}
            />

            <div className='std-margin-horizontal__minus'>
              <ResizableTable
                columns={issuingActColumns}
                dataSource={issuingAct.objects}
                dataPattern={{}}
                activeKey={issuingActObjectsActiveKey}
                setActiveKey={setIssuingActObjectsActiveKey}
                {...dataParams}
              />
            </div>
          </Space>
        </Card>
      </>
    </DashboardContainer>
  );
};

export default IssuingAct;
