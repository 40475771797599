import moment from 'moment';
import { objectsListDemo } from './ObjectMock';
import { expertsDemo } from './DirectoryMock';

export const issuingActListDemo = [
  {
    id: 'acc1',
    number: '545',
    date: moment(),
    comment: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae, animi!',

    experts: expertsDemo,

    objects: objectsListDemo.slice(3),

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },
  },

  {
    id: 'acc2',
    number: '356',
    date: moment(),
    comment: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae, animi!',

    experts: expertsDemo,

    objects: objectsListDemo.slice(3),

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },
  },

  {
    id: 'acc3',
    number: '567',
    date: moment(),
    comment: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae, animi!',

    experts: expertsDemo,

    objects: objectsListDemo.slice(3),

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },
  },

  {
    id: 'acc4',
    number: '221',
    date: moment(),
    comment: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae, animi!',

    experts: expertsDemo,

    objects: objectsListDemo.slice(3),

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },
  },

  {
    id: 'acc5',
    number: '443',
    date: moment(),
    comment: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae, animi!',

    experts: expertsDemo,

    objects: objectsListDemo.slice(3),

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },
  },
];
