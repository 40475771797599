import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Input,
  Form,
  Col,
  Row,
  Card,
  Button,
  Typography, Table, Divider,
} from 'antd';
import {emptyUser, User as UserType} from "../../../types/User";
import UserService from "../../../api/UserService";
import RoleService from "../../../api/RoleService";
import {Role} from "../../../types/Role";
import {ClientType} from "../../../types/ClientType";
import {v4 as uuid} from "uuid";
import ClientsService from "../../../api/ClientsService";

const { Title } = Typography;

const contractButtonStyle: any = {
  marginTop: "20px"
};

export const rolesColumns = [
  {
    title: 'Роль',
    dataIndex: 'name',
  },
];

const User: FC<any> = (props: any) => {
  const history = useHistory();
  const { id } = useParams<any>();
  const isNew = id === 'new';
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<UserType>(emptyUser);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKyes] = useState<any>([]);

  useEffect(() => {
    setSelectedRowKyes([])
    setIsChanged(false)
    RoleService.getAll().then((roles: Role[]) => {
      setRoles(roles);
      setIsLoading(false);
    });

    if (isNew || !id) {
      setCurrent(emptyUser);

      return;
    }
    setIsLoading(true);
    UserService.getById(id).then((user: UserType) => {
      setCurrent(user);
      setSelectedRowKyes(user.roles?.map((role: Role) => role.id));
      setIsLoading(false);
    });
  }, [id, isNew]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(current);
    setIsChanged(false);
  }, [form, current]);

  const onUpdate = async () => {
    const changedUser: UserType = { ...form.getFieldsValue(), roles: selectedRowKeys, id: id };
    UserService.update(changedUser).then((response: UserType) => {
      setCurrent(response)
      props.onChange([response.id]);
      history.push(`/admin/user/` + response.id);
    })
  };

  const onAdd = async () => {
    const changedUser: UserType = { ...form.getFieldsValue(), roles: selectedRowKeys, id: uuid() };
    UserService.add(changedUser).then((response: UserType) => {
      setCurrent(response)
      props.onChange([response.id]);
      history.push(`/admin/user/` + response.id);
    })
  };

  return (
    <>
      {!id ? (
        <div className='text-align-left'>
          <Title level={4}>Пользователь не выбран</Title>
        </div>
      ) : (
        <Form
          className='std-margin-horizontal__minus'
          layout='horizontal'
          form={form}
          onFieldsChange={() => setIsChanged(true)}>
          <Card title='Данные пользователя' bordered={true}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='login'
                  label='Логин'
                  rules={[{ required: true, message: 'Заполните поле' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='fullName'
                  label='Полное Имя'
                  rules={[{ required: true, message: 'Заполните поле' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                    name='password'
                    label='Пароль'
                    rules={[{ required: false, message: 'Заполните поле' }]}>
                  <Input size='large' type='password' />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          <Card title='Роли' bordered={true}>
            <Table
                rowKey='id'
                columns={rolesColumns}
                dataSource={roles}
                loading={isLoading}
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRowKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    setIsChanged(true);
                    setSelectedRowKyes(selectedRowKeys);
                  },
                }}
            />
          </Card>

          {isChanged && !isNew && (
            <>
              <Divider />
              <div className='text-align-right'>
                <Button type='primary' style={contractButtonStyle} onClick={() => onUpdate()}>
                  Сохранить
                </Button>
              </div>
            </>
          )}

          {isNew && (
            <>
              <Divider />
              <div className='text-align-right'>
                <Button type='primary' style={contractButtonStyle} onClick={() => onAdd()}>
                  Добавить
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default User;
