import Api from '../utils/Api';
import moment, {isMoment} from 'moment';
import {PaginationSettings} from "../types/PaginationSettings";
import {ArtObjectImage} from "../types/ArtObjectImage";
import {ArtObjectImage as ApiArtObjectImage} from "../types/api/ArtObjectImage";
import {PagedList} from "../types/PagedList";
import ArtObjectService from "./ArtObjectService";
import PhotoService from "./PhotoService";
import RequestUtils from "./RequestUtils";

class ArtObjectImageService {
  static async getAll(
      searchParams: any,
      pagination: PaginationSettings,
  ): Promise<PagedList<ArtObjectImage>> {
    return Api.call(
        `/art-object-image/?page=${pagination.pageNumber}&size=${pagination.size}&` +
        RequestUtils.serialize(searchParams),
        {
          method: 'GET',
        },
    ).then((response) => {
      return {
        items: response.data.content.map((response: ApiArtObjectImage) =>
            ArtObjectImageService.makeImageFromApi(response),
        ),
        page: {
          page: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        },
      } as PagedList<ArtObjectImage>;
    });
  }

  static makeImageFromApi(image: ApiArtObjectImage): ArtObjectImage {
    return {
      id: image.id,
      picture: image.picture,
      name: image.name,
      date: moment(image.date),
      type: image.type,
      isInfraRed: image.isInfraRed,
      artObject: ArtObjectService.makeObjectFromApi(image.artObject),
      imageFile: image.imageFile ? PhotoService.makePhotoFromApi(image.imageFile) : null
    } as ArtObjectImage;
  }
}

export default ArtObjectImageService;
