import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import {ArtObject} from "../../types/ArtObject";
import ArtObjectService from "../../api/ArtObjectService";
import {FinanceActItemType} from "../../types/FinanceActItemType";



export const itemsColumns = [

  {
    title: '№',
    dataIndex: 'index',
    type: 'number',
    width: 200,
  },
  {
    title: 'Наименование работ, услуг',
    dataIndex: 'name',
    type: 'text',
    width: 1000,
  },
  {
    title: 'К-во',
    dataIndex: 'count',
    type: 'text',
    width: 200,
  },
  {
    title: 'Ед.',
    dataIndex: 'empty',
    type: 'text',
    width: 100,
  },
  {
    title: 'Цена',
    dataIndex: 'price',
    type: 'text',
    width: 200,
  },
  {
    title: 'Сумма',
    render: (column: any, item: FinanceActItemType) => item.count * item.price,
    type: 'text',
    width: 200,
  },
]
