import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { STORE_USER_TOKEN } from '../../constants';

import LocalStorage from '../../utils/LocalStorage';

import {UserState} from "../../types/UserState";
import AuthService, {UserData} from "../../api/AuthService";

const token = LocalStorage.get<string>(STORE_USER_TOKEN);
const parsedState: UserData = token && AuthService.verifyToken(token) ? AuthService.readToken() : {} as UserData;

const initialState: UserState = {
  username: parsedState.fullName ?? '',
  isLoggedIn: !!parsedState.id,
  token: parsedState.token ?? '',
  roles: parsedState.roles ?? [],
  permissions: parsedState.permissions
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    eventLogin: (state: UserState, action: PayloadAction<UserState>) => {
      return {
        username: action.payload.username,
        roles: action.payload.roles,
        token: action.payload.token,
        isLoggedIn: true,
        permissions: action.payload.permissions
      }
    },
    eventSetInfo: (state: UserState, action: PayloadAction<UserState>) => {
      return {
        username: action.payload.username,
        roles: action.payload.roles,
        token: action.payload.token,
        isLoggedIn: true,
        permissions: action.payload.permissions
      }
    },
    logout: (state) => {
      state.token = '';
    },
  },
});

export default userSlice;
