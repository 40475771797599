import { ExpertiseXRAY } from '../types/ExpertiseXRAY';
import { ExpertiseXRAY as ApiExpertiseXRAY } from '../types/api/ExpertiseXRAY';
import Api from '../utils/Api';
import moment from 'moment';
import ArtObjectService from './ArtObjectService';
import RequestUtils from "./RequestUtils";

class ExpertiseXRAYService {
  static async getAll(searchParams: any, extended = false): Promise<ExpertiseXRAY[]> {
    const api = extended? 'expertise-xray/extended' : 'expertise-xray'
    const response = await Api.call(`/${api}?` + RequestUtils.serialize(searchParams), {
      method: 'GET',
    });

    return response.data.map((it: ApiExpertiseXRAY) => ExpertiseXRAYService.makeFromApi(it, extended));
  }

  static makeFromApi(expertise: ApiExpertiseXRAY, extended = false): ExpertiseXRAY {
    return {
      id: expertise.id,
      count: expertise.count,
      date: moment(expertise.date),
      object: extended ? (expertise.object ? ArtObjectService.makeObjectFromApi(expertise.object) : undefined)
        : (expertise.object ? ArtObjectService.makeObjectIdFromApi(expertise.object) : undefined),
    } as ExpertiseXRAY;
  }
}

export default ExpertiseXRAYService;
