/* eslint-disable max-len */
import React, {FC, useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {
  Button,
  Card,
  Col, Collapse, DatePicker, Divider,
  Form, Input, InputNumber, Popconfirm, Row, Select, Space, Switch, Table, Tabs, Timeline, Typography,
} from 'antd';

import DashboardContainer from '../../components/DashboardContainer';
import {ArtObject, ArtObjectType, emptyArtObject} from "../../types/ArtObject";
import {ContractType, emptyContract} from "../../types/ContractType";
import ArtObjectService from "../../api/ArtObjectService";
import ContractService from "../../api/ContractService";
import MaskedInput from "antd-mask-input";
import {ClientType, emptyClient} from "../../types/ClientType";
import {
  contractAcceptActColumns,
  contractFinancialActColumns,
  contractIssuingActColumns,
  contractObjectColumns
} from "./Columns";
import {ReceivingActType} from "../../types/ReceivingActType";
import ReceivingActService from "../../api/ReceivingActService";
import {GivingActType} from "../../types/GivingActType";
import {FinanceActType} from "../../types/FinanceActType";
import GivingActService from "../../api/GivingActService";
import FinanceActService from "../../api/FinanceActService";
import ClientsService from "../../api/ClientsService";
import {ClientType as APIClientType} from "../../types/api/ClientType";
import {DATE_FORMAT, DATE_TIME_FORMAT} from "../../constants";
import {ObjectLocationState} from "../ObjectSingle/ObjectSingle";
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import AuthService from "../../api/AuthService";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";

const {Panel} = Collapse;
const {TextArea} = Input;
const {Option} = Select;
const {TabPane} = Tabs;
const {Text} = Typography;

interface ContractProps extends React.HTMLAttributes<HTMLElement> {
}

export interface LocationState {
  clientId: string;
  year: number;
}

const ContractSingle: React.FC<ContractProps> = () => {
  const [form] = Form.useForm();
  const {id} = useParams<any>();
  const history = useHistory();
  const user = useSelector(selectUserState);

  const [isLoading, setIsLoading] = useState(true);
  const [contract, setContract] = useState<ContractType>(emptyContract);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [client, setClient] = useState<ClientType>(emptyClient);
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [contractObjects, setContractObjects] = useState<ArtObject[]>([]);
  const [contractAcceptActs, setContractAcceptActs] = useState<ReceivingActType[]>([]);
  const [contractIssuingActs, setContractIssuingActs] = useState<GivingActType[]>([]);
  const [contractFinancialActs, setContractFinancialActs] = useState<FinanceActType[]>([]);

  const isNew = id === 'new';
  const location = useLocation()
  const newState = location.state as LocationState

  const fetchClient = async (id: string) => {
    const client = await ClientsService.getById(id);
    setClient(client)
  };

  const fetchLastNumber = async (state: LocationState) => {
    const number = await ContractService.findLastNumber(state);
    form.setFieldValue('number', number + 1)
  };

  const fetchContract = async (id: string) => {
    setIsLoading(true);
    const contract = await ContractService.getById(id);
    setContract(contract);
    setClient(contract.client)
  };

  const fetchObjects = async (id: string) => {
    setIsLoading(true);
    const objects = await ArtObjectService.getForContract(id);
    setContractObjects(objects);
    setIsLoading(false);
  };

  const fetchReceivingActs = async (id: string) => {
    setIsLoading(true);
    const acts = await ReceivingActService.getForContract(id);
    setContractAcceptActs(acts);
    setIsLoading(false);
  };

  const fetchGivingActs = async (id: string) => {
    setIsLoading(true);
    const acts = await GivingActService.getForContract(id);
    setContractIssuingActs(acts);
    setIsLoading(false);
  };

  const fetchFinanceActs = async (id: string) => {
    setIsLoading(true);
    const acts = await FinanceActService.getForContract(id);
    setContractFinancialActs(acts);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsCompany(client?.isCompany);
    form.resetFields();
    form.setFieldsValue(client);
    setIsChanged(false);

    if (!isNew) {
      fetchContract(id)
      fetchObjects(id)
      fetchReceivingActs(id)
      fetchGivingActs(id)
      fetchFinanceActs(id)
    } else {
      fetchClient(newState.clientId)
      fetchLastNumber(newState)
    }
  }, [id]);

  const getTitle = () => `Договор ${contract.year}/${contract.number} от ${contract.signedAt.format("YYYY-MM-DD")}`;
  const clientFIO = (client?: ClientType): string => {
    if (!client) return ``
    return `${client.surname} ${client.name} ${client.patronymic}`;
  };

  const submit = () => {
    form.validateFields().then(async (values) => {
      ContractService.add({...form.getFieldsValue(), clientId: newState.clientId, year: newState.year})
        .then((id) => {
          history.push(`/expertise/contracts/${id}`);
        })
    })
  };

  const removeObject = (objectId: any) => {
    ArtObjectService.delete(objectId)
      .then(value => fetchObjects(id))
  }

  const removeAcceptAct = (actId: any) => {
    ReceivingActService.delete(actId)
      .then(value => fetchReceivingActs(id))
  }

  const removeGivingAct = (actId: any) => {
    GivingActService.delete(actId)
      .then(value => fetchGivingActs(id))
  }

  const removeFinanceAct = (actId: any) => {
    FinanceActService.delete(actId)
      .then(value => fetchFinanceActs(id))
  }

  const newReceivingAct = async () => {
    ReceivingActService.saveNew(contract.id).then((response) => {
      history.push(`/expertise/accept-acts/${response.id}`);
    });
  }

  const newGivingAct = async () => {
    GivingActService.saveNewObject(contract.id, []).then((response) => {
      history.push(`/expertise/issuing-acts/${response.id}`);
    });
  }

  const deleteObjectColumn = {
    title: '',
    dataIndex: 'delete',
    render: (v: any, object: ArtObject) => {
      return <Popconfirm
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        title={`Подтвердите удаление объекта ${object.number}. Эта операция необратима, восстановить объект будет нельзя.`}
        placement='topRight'
        okText='Удалить'
        cancelText='Отменить'
        okButtonProps={{danger: true, type: "default"}}
        onConfirm={() => removeObject(object.id)}>
        <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          disabled={!AuthService.checkPermission(user.permissions, "ARTOBJECTS", "WRITE")}
        />
      </Popconfirm>
    }
  }

  const deleteAcceptActColumn = {
    title: '',
    dataIndex: 'delete',
    render: (v: any, receivingAct: ReceivingActType) => {
      return <Popconfirm
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        title={`Подтвердите удаление акта приема ${receivingAct.number} от ${receivingAct.date.format("DD.MM.YYYY")}. Эта операция необратима, восстановить акт будет нельзя.`}
        placement='topRight'
        okText='Удалить'
        cancelText='Отменить'
        okButtonProps={{danger: true, type: "default"}}
        onConfirm={() => removeAcceptAct(receivingAct.id)}>
        <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          disabled={!AuthService.checkPermission(user.permissions, "RECEIVINGACTS", "WRITE")}
        />
      </Popconfirm>
    }
  }

  const deleteGivingActColumn = {
    title: '',
    dataIndex: 'delete',
    render: (v: any, givingtAct: GivingActType) => {
      return <Popconfirm
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        title={`Подтвердите удаление акта выдачи ${givingtAct.number} от ${givingtAct.date.format("DD.MM.YYYY")}. Эта операция необратима, восстановить акт будет нельзя.`}
        placement='topRight'
        okText='Удалить'
        cancelText='Отменить'
        okButtonProps={{danger: true, type: "default"}}
        onConfirm={() => removeGivingAct(givingtAct.id)}>
        <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          disabled={!AuthService.checkPermission(user.permissions, "GIVINGACTS", "WRITE")}
        />
      </Popconfirm>
    }
  }

  const deleteFinanceActColumn = {
    title: '',
    dataIndex: 'delete',
    render: (v: any, financeAct: FinanceActType) => {
      return <Popconfirm
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        title={`Подтвердите удаление финансового акта ${financeAct.number} от ${financeAct.date.format("DD.MM.YYYY")}. Эта операция необратима, восстановить акт будет нельзя.`}
        placement='topRight'
        okText='Удалить'
        cancelText='Отменить'
        okButtonProps={{danger: true, type: "default"}}
        onConfirm={() => removeFinanceAct(financeAct.id)}>
        <Button
          shape='circle'
          icon={<DeleteOutlined/>}
          disabled={!AuthService.checkPermission(user.permissions, "FINANCEACTS", "WRITE")}
        />
      </Popconfirm>
    }
  }

  const newFinanceAct = async () => {
    FinanceActService.upsert(id, [], {}).then((response) => {
      history.push(`/expertise/financial-acts/${response.id}`);
    })
  }

  return (
    <DashboardContainer title={isNew ? 'Создание нового договора' : getTitle()}
                        buttons={!isNew ? [<Link key='1' target="_blank" to={`/expertise/contracts/${id}/print`}>
                          <Button>Печать</Button>
                        </Link>] : []}>
      {isNew && <div style={{textAlign: "left"}}>
          <Form
              form={form}
              layout={"inline"}
          >
              <Space align={"center"}>
                  <Text>Номер договора</Text>
                  <Text>{newState.year}</Text>
                  <Form.Item
                      name="number"
                      rules={[{required: true, message: 'Введите номер договора'}]}>
                      <InputNumber/>
                  </Form.Item>
                  <Text>подписан</Text>
                  <Form.Item
                      name="signedAt"
                      rules={[{required: true, message: 'Введите дату подписания'}]}>
                      <DatePicker/>
                  </Form.Item>
                  <Button type={"primary"} onClick={submit}>Создать</Button>
              </Space>
          </Form>
      </div>}
      <Row>
        <Col span={10}>
          <Divider orientation="left">
            <Link to={`/expertise/clients/${client.id}`}>
              Клиент
            </Link>
          </Divider>
          <div style={{textAlign: "left"}}>
            <div>{client.isCompany ? client.companyName : clientFIO(client)}</div>
            <div>{client.email}</div>
            <div>{client.phoneNumber}</div>
          </div>
          {!isNew && <div style={{textAlign: "left"}}>
              <Divider orientation="left">Информация о договоре</Divider>
              <Row>
                  <Space>
                      <Text>Кем создано:</Text>
                      <Text>{contract.createdBy?.fullName}</Text>
                      <Text>{contract.createdAt?.format(DATE_TIME_FORMAT)}</Text>
                  </Space>
              </Row>
              <Row>
                  <Space>
                      <Text>Кем измененно:</Text>
                      <Text>{contract.modifiedBy?.fullName}</Text>
                      <Text>{contract.modifiedAt?.format(DATE_TIME_FORMAT)}</Text>
                  </Space>
              </Row>
          </div>}
        </Col>
        {!isNew && <Col span={12} offset={1}>
            <Tabs defaultActiveKey='1'>
                <TabPane tab='Объекты экспертизы' key='objects'>
                    <Table
                        rowKey='id'
                        columns={[...contractObjectColumns, deleteObjectColumn]}
                        dataSource={contractObjects}
                    />
                    <Row gutter={16}>
                        <Col>
                            <Link to={`/expertise/accept-acts/new?contractId=${contract.id}`}>
                                <Button disabled={!AuthService.checkPermission(user.permissions, "RECEIVINGACTS", "WRITE")}>
                                    Создать новый объект
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tab='Акты приёма' key='acceptActs'>
                    <Table
                        rowKey='id'
                        columns={[...contractAcceptActColumns, deleteAcceptActColumn]}
                        dataSource={contractAcceptActs}
                    />
                    <Row gutter={16}>
                        <Col>
                            <Button onClick={() => newReceivingAct()}
                                disabled={!AuthService.checkPermission(user.permissions, "RECEIVINGACTS", "WRITE")}>
                                Создать новый акт приёма
                            </Button>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tab='Акты выдачи' key='issuingActs'>
                    <Table
                        rowKey='id'
                        columns={[...contractIssuingActColumns, deleteGivingActColumn]}
                        dataSource={contractIssuingActs}
                    />
                    <Row gutter={16}>
                        <Col>
                            <Button onClick={() => newGivingAct()}
                                disabled={!AuthService.checkPermission(user.permissions, "GIVINGACTS", "WRITE")}>
                                Создать новый акт выдачи
                            </Button>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tab='Финансовые акты' key='financialActs'>
                    <Table
                        rowKey='id'
                        columns={[...contractFinancialActColumns, deleteFinanceActColumn]}
                        dataSource={contractFinancialActs}
                    />
                    <Row gutter={16}>
                        <Col>
                            <Button onClick={() => newFinanceAct()} disabled={!AuthService.checkPermission(user.permissions, "FINANCEACTS", "WRITE")}>
                                Создать новый финансовый акт
                            </Button>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Col>}
      </Row>
    </DashboardContainer>
  );
};

export default ContractSingle;
