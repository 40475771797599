import React, { FC } from 'react';
import { Layout } from 'antd';
import './styles.scss';

import { FOOTER_COPYRIGHT } from '../../constants';

const SystemContainer: FC = (props) => {
  const { Content, Footer } = Layout;
  const { children } = props;

  return (
    <Layout className='root-layout'>
      <Content style={{ padding: '0 50px' }}>
        <Layout className='system-layout' style={{ padding: '24px 0' }}>
          <Content style={{ padding: '0 24px', minHeight: 280 }}>{children}</Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>{FOOTER_COPYRIGHT}</Footer>
    </Layout>
  );
};

export default SystemContainer;
