export type PaginationSettings = {
  pageNumber: number;
  size: number;
  total: number;
};

export const defaultPagination: PaginationSettings = {
  pageNumber: 0,
  size: 20,
  total: 0,
};
