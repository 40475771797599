import {PagedList} from "./PagedList";
import {ArtObject, emptyArtObject} from "./ArtObject";
import moment, {Moment} from "moment";
import {emptyPhoto, Photo} from "./Photo";

export type ArtObjectImage = {
  id: string,
  picture: string,
  name: string,
  date: Moment,
  type: number,
  isInfraRed: boolean,
  artObject: ArtObject,
  imageFile: Photo
};

export const emptyArtObjectImage: ArtObjectImage = {
  id: "",
  picture: "",
  name: "",
  date: moment(),
  type: 0,
  isInfraRed: false,
  artObject: emptyArtObject,
  imageFile: emptyPhoto
};

export const emptyArtObjectImagePage: PagedList<ArtObjectImage> = {
  items: [],
  page: {
    page: 1,
    pageSize: 20,
    total: 0,
  },
};
