import { Moment } from 'moment';
import moment from 'moment/moment';
import { ClientType, emptyClient } from './ClientType';
import {User} from "./User";

export type ContractType = {
  id: string;
  number: number;
  year: string;
  signedAt: Moment;
  client: ClientType;
  createdAt?: Moment;
  createdBy?: User;
  modifiedAt?: Moment;
  modifiedBy?: User;
};

export const emptyContract: ContractType = {
  id: '',
  number: 0,
  year: '2017',
  signedAt: moment(),
  client: emptyClient,
};
