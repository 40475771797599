import Api from '../utils/Api';
import {ReportExpertsObjectsItem as ReportExpertsObjectsItemApi} from "../types/api/ReportExpertsObjectsItem";
import {ReportExpertsObjectsItem} from "../types/ReportExpertsObjectsItem";
import moment from "moment";
import ArtObjectService from "./ArtObjectService";
import ExpertiseChemicalService from "./ExpertiseChemicalService";
import ExpertiseXRAYService from "./ExpertiseXRAYService";
import ExpertiseXRAYComparisonService from "./ExpertiseXRAYComparisonService";
import PhotoService from "./PhotoService";
import ArtObjectStatusService from "./ArtObjectStatusService";

class ReportService {
  private static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj)
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  static async getAll(searchParams: any): Promise<ReportExpertsObjectsItem[]> {
    return Api.call('/report/experts-objects?' + ReportService.serialize(searchParams), {
      method: 'GET',
    }).then((response) => {
      return response.data.map((it: ReportExpertsObjectsItemApi) => ReportService.makeFromApi(it));
    });
  }

  static makeFromApi(reportItem: ReportExpertsObjectsItemApi): ReportExpertsObjectsItem {
    return {
      id: reportItem.id,
      dateReceiving: moment(reportItem.dateReceiving),
      object: ArtObjectService.makeObjectFromApi(reportItem.object),
      status: ArtObjectStatusService.makeFromApi(reportItem.status),
      image: reportItem.image ? PhotoService.makePhotoFromApi(reportItem.image) : undefined,
      expertiseChemical: reportItem.expertiseChemical.map(ExpertiseChemicalService.makeFromApi),
      expertiseXRAY: reportItem.expertiseXRAY.map((value) => ExpertiseXRAYService.makeFromApi(value)),
      expertiseXRAYComparison: reportItem.expertiseXRAYComparison.map((value) => ExpertiseXRAYComparisonService.makeFromApi(value)),
    } as ReportExpertsObjectsItem;
  }
}

export default ReportService;
