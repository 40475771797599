import Api from '../utils/Api';
import {ReportUnclaimedObjectsItem as ReportUnclaimedObjectsItemApi} from "../types/api/ReportUnclaimedObjectsItem";
import {ReportUnclaimedObjectsItem} from "../types/ReportUnclaimedObjectsItem";
import ArtObjectService from "./ArtObjectService";
import ReceivingActService from "./ReceivingActService";
import PhotoService from "./PhotoService";

class ReportUnclaimedObjectService {
  private static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj)
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  static async getAll(searchParams: any): Promise<ReportUnclaimedObjectsItem[]> {
    return Api.call('/report/unclaimed-objects?' + ReportUnclaimedObjectService.serialize(searchParams), {
      method: 'GET',
    }).then((response) => {
      return response.data.map((it: ReportUnclaimedObjectsItemApi) => ReportUnclaimedObjectService.makeFromApi(it));
    });
  }

  static makeFromApi(reportItem: ReportUnclaimedObjectsItemApi): ReportUnclaimedObjectsItem {
    return {
      id: reportItem.id,
      artObject: ArtObjectService.makeObjectFromApi(reportItem.artObject),
      receivingAct: reportItem.receivingAct ? ReceivingActService.makeReceivingActFromApi(reportItem.receivingAct) : null,
      image: reportItem.mainImage ? PhotoService.makePhotoFromApi(reportItem.mainImage) : undefined,
    } as ReportUnclaimedObjectsItem;
  }
}

export default ReportUnclaimedObjectService;
