import { Expertise } from '../types/Expertise';
import { Expertise as APIExpertise } from '../types/api/Expertise';
import Api from '../utils/Api';
import moment from 'moment';
import ArtObjectService from './ArtObjectService';
import ExpertsService from './ExpertsService';
import RequestUtils from "./RequestUtils";
import {Expert} from "../types/Expert";

class ExpertiseService {
  static expertTransform(expert?: Expert) {
    return expert ? `${expert?.surname} ${expert?.name[0]}. ${expert?.patronymic[0]}.` : "";
  }

  static async getAll(searchParams: any): Promise<Expertise[]> {
    const response = await Api.call('/expertise?' + RequestUtils.serialize(searchParams), {
      method: 'GET',
    });

    return response.data.map((it: APIExpertise) => ExpertiseService.makeFromApi(it));
  }

  static makeFromApi(expertise: APIExpertise): Expertise {
    return {
      id: expertise.id,
      number: expertise.number,
      name: expertise.name,
      fileName: expertise.fileName,
      fileHash: expertise.fileHash,
      date: moment(expertise.date),
      objectId: expertise.object ? expertise.object.id : undefined,
      expert: expertise.expert ? ExpertsService.makeFromApi(expertise.expert).id : undefined,
      expertFull: expertise.expert ? ExpertsService.makeFromApi(expertise.expert) : undefined
    } as Expertise;
  }
}

export default ExpertiseService;
