export const authorsDemo = [
  { id: 'author1', surname: 'Иванов', name: 'Иван', patronymic: 'Иванович' },
  { id: 'author2', surname: 'Иванов', name: 'Иван' },
  { id: 'author3', surname: 'Петров', name: 'Иван', patronymic: 'Иванович' },
  { id: 'author4', surname: 'Козлов', name: 'Иван', patronymic: 'Иванович' },
];

export const typesDemo = ['OZ', 'OG', 'DPI', 'OI'];

export const unitsDemo = ['cm', 'dm', 'm', 'inch'];

export const tariffsDemo = ['тариф1', 'тариф2', 'тариф3', 'тариф4'];

export const statusesDemo = [
  'Заключение эксперта',
  'Отвод',
  'В работе',
  'Принято на хранение',
  'Нет эталонного материала',
];

export const resultsDemo = ['Успешно', 'Нейтрально', 'Провально'];

export const analizeTypesDemo = ['type 1', 'type 2', 'type 3'];
