import React, {FC, useEffect, useMemo} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './styles.scss';

import {useDispatch, useSelector} from 'react-redux';
import { routes } from '../../constants/routes';

//expertise
import Clients from '../../pages/Clients';

import AcceptAct from '../../pages/AcceptAct';
import AcceptActList from '../../pages/AcceptActList';

import IssuingAct from '../../pages/IssuingAct';
import IssuingActList from '../../pages/IssuingActList';

import FinancialAct from '../../pages/FinancialAct';
import FinancialActList from '../../pages/FinancialActList';

import ObjectList from '../../pages/ObjectList';
import ObjectSingle from '../../pages/ObjectSingle';
import FindImage from '../../pages/FindImage';

//directory
import Directory from '../../pages/Directory';

//reports
import DailyReport from '../../pages/DailyReport';
import ExpertWork from '../../pages/ExpertWork';
import WeeklyReport from '../../pages/WeeklyReport';
import RentgenReport from '../../pages/RentgenReport';
import MonthlyReport from '../../pages/MonthlyReport';
import RentgenComparison from '../../pages/RentgenComparison';

//login
import Login from '../../pages/Login';
import ContractSingle from '../../pages/ContractSingle';

import PriceLists from "../../pages/PriceLists/PriceLists";
import AuthService from "../../api/AuthService";
import {selectUserState} from "../../redux/user/userGetters";
import Users from "../../pages/Users";
import Roles from "../../pages/Roles";
import DirectorReport from "../../pages/DirectorReport/DirectorReport";
import AcceptActPrint from "../../pages/AcceptActPrint";
import IssuiringActPrint from "../../pages/IssuiringActPrint";
import FinancialActPrint from "../../pages/FinancialActPrint";
import ContractPrint from "../../pages/ContractPrint";
import ObjectForPhotoPrint from "../../pages/ObjectForPhotoPrint";
import ObjectForXrayPrint from "../../pages/ObjectForXrayPrint";
import AppEnvService from "../../api/AppEnvService";
import {updateEnv} from "../../redux/appEnv/appEnvActions";

const App: FC = () => {
  const dispatch = useDispatch();
  const userState = useSelector(selectUserState);

  useEffect(() => {
    AppEnvService.readEnv()
      .then((appEnv) => dispatch(updateEnv(appEnv)));
  }, []);

  const PrivateRoute: FC<any> = ({ children, ...rest }) => {
    return <Route {...rest}>{userState.isLoggedIn ? children : <Redirect to={routes.login} />}</Route>;
  };

  const SystemRoute: FC<any> = ({ children, ...rest }) => {
    return <Route {...rest}>{!userState.isLoggedIn ? children : <Redirect to={routes.clientList} />}</Route>;
  };

  return (
    <Router>
      <Switch>
        <SystemRoute path={routes.login}>
          <Login />
        </SystemRoute>

        {/* expertise */}
        <PrivateRoute exact path={routes.clients}>
          <Clients />
        </PrivateRoute>

        {/* contract */}
        <PrivateRoute exact path={routes.contracts}>
          <ContractSingle />
        </PrivateRoute>

        <PrivateRoute exact path={routes.contractPrint}>
          <ContractPrint />
        </PrivateRoute>

        {/* Accept Act */}
        <PrivateRoute exact path={routes.acceptAct}>
          <AcceptAct />
        </PrivateRoute>

        <PrivateRoute exact path={routes.acceptActPrint}>
          <AcceptActPrint />
        </PrivateRoute>

        <PrivateRoute exact path={routes.acceptActList}>
          <AcceptActList />
        </PrivateRoute>

        <PrivateRoute exact path={routes.issuingAct}>
          <IssuingAct />
        </PrivateRoute>

        <PrivateRoute exact path={routes.issuingActList}>
          <IssuingActList />
        </PrivateRoute>

        <PrivateRoute exact path={routes.issuingActPrint}>
          <IssuiringActPrint />
        </PrivateRoute>

        <PrivateRoute exact path={routes.financialAct}>
          <FinancialAct />
        </PrivateRoute>

        <PrivateRoute exact path={routes.financialActList}>
          <FinancialActList />
        </PrivateRoute>

        <PrivateRoute exact path={routes.financialActPrint}>
          <FinancialActPrint />
        </PrivateRoute>

        <PrivateRoute exact path={routes.objectsList}>
          <ObjectList />
        </PrivateRoute>

        <PrivateRoute exact path={routes.objectSingle}>
          <ObjectSingle />
        </PrivateRoute>

        <PrivateRoute exact path={routes.objectPhotoPrint}>
          <ObjectForPhotoPrint />
        </PrivateRoute>

        <PrivateRoute exact path={routes.objectXrayPrint}>
          <ObjectForXrayPrint />
        </PrivateRoute>

        <PrivateRoute exact path={routes.findImage}>
          <FindImage />
        </PrivateRoute>

        {/* prices */}
        <PrivateRoute exact path={routes.priceLists}>
          <PriceLists />
        </PrivateRoute>

        <PrivateRoute exact path={routes.priceList}>
          <PriceLists />
        </PrivateRoute>

        {/* directory */}
        <PrivateRoute exact path={routes.directory}>
          <Directory />
        </PrivateRoute>

        {/* reports */}
        <PrivateRoute exact path={routes.dailyReport}>
          <DailyReport />
        </PrivateRoute>

        <PrivateRoute exact path={routes.expertWork}>
          <ExpertWork />
        </PrivateRoute>

        <PrivateRoute exact path={routes.weeklyReport}>
          <WeeklyReport />
        </PrivateRoute>

        <PrivateRoute exact path={routes.directorReport}>
          <DirectorReport />
        </PrivateRoute>

        <PrivateRoute exact path={routes.monthlyReport}>
          <MonthlyReport />
        </PrivateRoute>

        <PrivateRoute exact path={routes.rentgenReport}>
          <RentgenReport />
        </PrivateRoute>

        <PrivateRoute exact path={routes.rentgenComparison}>
          <RentgenComparison />
        </PrivateRoute>

        <PrivateRoute exact path={routes.usersList}>
          <Users />
        </PrivateRoute>

        <PrivateRoute exact path={routes.user}>
          <Users />
        </PrivateRoute>

        <PrivateRoute exact path={routes.usersRoles}>
          <Roles />
        </PrivateRoute>

        <PrivateRoute exact path={routes.usersRole}>
          <Roles />
        </PrivateRoute>

        <PrivateRoute>
          <Clients />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;
