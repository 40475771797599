import React, {FC, useEffect, useState} from 'react';
import {Row, Col, Table, Button} from 'antd';
import DashboardContainer from "../../components/DashboardContainer";
import UserService from "../../api/UserService";
import User from "./User";
import {Link, useHistory, useParams} from "react-router-dom";
import {User as UserType} from "../../types/User";

export const usersColumns = [
    {
        title: 'Логин',
        dataIndex: 'login',
    },
    {
        title: 'ФИО',
        dataIndex: 'fullName',
    }
];

const Users: FC = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<any>([]);
    const { id } = useParams<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([id]);

    const handleChange = React.useCallback((newValue) => {
         setSelectedRowKeys(newValue);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        UserService.getAll().then((users) => {
            setUsers(users);
            setIsLoading(false);
        })
    }, [selectedRowKeys]);

    const onAddNewUser = () => {
        setSelectedRowKeys([]);
    };

    return (
        <DashboardContainer
            title='Список пользователей'
            className='text-align-left'
            buttons={[
                <Link key='1' to='/admin/user/new'>
                    <Button key='1' type='primary' danger onClick={onAddNewUser}>
                        Добавить пользователя
                    </Button>
                </Link>,
            ]}
        >
            <Row>
                <Col span={10}>
                    <Table
                        rowKey='id'
                        columns={usersColumns}
                        dataSource={users}
                        loading={isLoading}
                        pagination={false}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRowKeys);
                                history.push(`/admin/user/${selectedRows[0].id}`);
                            },
                        }}
                    />
                </Col>

                <Col span={12} offset={1}>
                    <User onChange={handleChange} />
                </Col>
            </Row>
        </DashboardContainer>
    );
};

export default Users;
