import React, {FC, useEffect, useState} from 'react';
import {Col, Row, Space, Table, Typography} from "antd";
import {useParams} from "react-router-dom";
import {objectColumns} from "./Columns";
import ClientsService from "../../api/ClientsService";
import {ArtObject} from "../../types/ArtObject";
import GivingActService from "../../api/GivingActService";
import {emptyGivingAct} from "../../types/GivingActType";
import '../../assets/print.scss'
import {useSelector} from 'react-redux';
import {selectUserState} from "../../redux/user/userGetters";
import {ReceivingActType} from "../../types/ReceivingActType";

const {Title, Text} = Typography;

const IssuiringActPrint: FC = () => {
  const {id} = useParams<any>();
  const [acceptAct, setAcceptAct] = useState<ReceivingActType>(emptyGivingAct);
  const [objects, setObjects] = useState<ArtObject[]>([]);
  const user = useSelector(selectUserState);

  useEffect(() => {
    GivingActService.getById(id).then((act) => {
      setAcceptAct(act)
      setObjects(act.objects.map((value, index) => { return { ...value, index: index + 1 }} ))

      window.print()
    })
  }, [id]);

  return (
    <div className={'print-page'}>
      <Space direction="vertical" size={30}>
        <Title level={5} style={{textAlign: "center"}}>{`Акт № ${acceptAct.date.format("yyyy")}/${acceptAct.number}`}</Title>
        <Title level={5} style={{textAlign: "center"}}>Выдачи произведений, принятых на временное хранение в «НИНЭ им.
          П.М.Третьякова»</Title>
        <Row>
          <Col span={4}>
            <Text>Москва</Text>
          </Col>
          <Col span={4} offset={12}>
            <Text>{`${acceptAct.date.format('DD.MM.yyyy')}`}</Text>
          </Col>
        </Row>
        <Row>
          {`Настоящий акт составлен представителем «НИНЭ им. П.М.Третьякова» ${user.username}, с одной стороны,
          и лицом, принимающим произведения (ФИО лица, название организации) ${ClientsService.getClientName(acceptAct.contract.client)}, с другой стороны,
          в том, что первый передал, а второй принял находившиеся в «НИНЭ им. П.М.Третьякова» на временном хранении
          следующие произведения:`}
        </Row>
        <Table
          className={"print-table"}
          columns={objectColumns}
          dataSource={objects}
          pagination={false}
        />
      </Space>
      <Space direction={"vertical"} size={15} style={{marginTop: 30, width: '100%'}}>
        <Text style={{textAlign: "left"}}>
          Всего по акту выдано: {objects.length} произведений.
        </Text>
        <Text style={{textAlign: "left"}}>
          {`Основание (договор №_от_): ${acceptAct.contract.year}/${acceptAct.contract.number} от ${acceptAct.contract.signedAt.format("DD.MM.yyyy")}`}
        </Text>
        <Text style={{textAlign: "left"}}>
          Дополнительно к сведениям, указанным в таблице, при выдаче произведений выявлено:
        </Text>
        <div style={{width: '100%', borderBottom: "black 1px solid"}}>&nbsp;</div>
        <Text style={{textAlign: "left"}}>
          {`Акт составлен в двух экземплярах: один экземпляр получен подписавшим его представителем «НИНЭ им. П.М.Третьякова»,
          один экземпляр получен подписавшим его лицом, принимающим произведения ${ClientsService.getClientName(acceptAct.contract.client)}`}
        </Text>
      </Space>
      <Space direction={"vertical"} size={15} style={{marginTop: 30, width: '100%'}}>
        <Row>
          <Col span={10}>
            <Space direction={"vertical"} size={15} style={{width: '100%'}}>
              <Text>От «НИНЭ им. П.М.Третьякова» выдал:</Text>
              <div style={{width: '100%', borderBottom: "black 1px solid"}}>&nbsp;</div>
            </Space>
          </Col>
          <Col span={10} offset={4}>
            <Space direction={"vertical"} size={15} style={{width: '100%'}}>
              <Text>Подпись, ФИО лица, принял:</Text>
              <div style={{width: '100%', borderBottom: "black 1px solid"}}>&nbsp;</div>
            </Space>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default IssuiringActPrint;
