class RequestUtils {
  static serialize(obj: any): string {
    const str: string[] = [];
    for (const p in obj) {
      if (obj[p]) {
        let val = obj[p];
        if (typeof val == 'string') {
          val = val.trim();
        }
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(val));
      }
    }
    return str.join('&');
  }
}

export default RequestUtils;