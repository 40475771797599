/* eslint-disable max-len */
import React, { FC, Fragment, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Table,
  Image,
} from 'antd';
import DashboardContainer from '../../components/DashboardContainer';
import Search from "../../widgets/Search";
import {defaultPagination, PaginationSettings} from "../../types/PaginationSettings";
import {ArtObjectImage, emptyArtObjectImagePage} from "../../types/ArtObjectImage";
import {PagedList} from "../../types/PagedList";
import ArtObjectImageService from "../../api/ArtObjectImageService";
import {imagesListColumns} from "./Columns";
import PageUtils from "../../utils/PageUtils";
import PageUtils2 from "../../utils/PageUtils";

const searchData = [
  { name: 'authorSurname', label: 'Автор', type: 'text' },
  { name: 'objectName', label: 'Объект', type: 'text' },
  { name: 'imageType', label: 'Тип', type: 'select', width: 150, items: [
      {
        key: 0,
        value: '0',
        name: 'Общий вид'
      },
      {
        key: 1,
        value: '1',
        name: 'Подпись/Клеймо'
      },
      {
        key: 2,
        value: '2',
        name: 'Фрагмент'
      }
    ] },
];

const FindImage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [images, setImages] = useState<PagedList<ArtObjectImage>>(emptyArtObjectImagePage);

  const fetchImages = async (searchParams: any, pagination: PaginationSettings) => {
    setIsLoading(true);

    const images = await ArtObjectImageService.getAll(searchParams, pagination);

    setImages(images);
    setIsLoading(false);
  };

  const {onSearchChange, onPaginationChange, form} = PageUtils('/expertise/pictures', fetchImages)

  return (
    <DashboardContainer title='Поиск изображений' style={{ textAlign: 'left' }}>
      <Search fields={searchData} action={onSearchChange} form={form}/>
      <Table
          rowKey='id'
          loading={isLoading}
          pagination={{
            pageSize: images.page.pageSize,
            current: images.page.page,
            total: images.page.total,
            onChange: onPaginationChange,
          }}
          columns={imagesListColumns}
          dataSource={images.items}
      />
    </DashboardContainer>
  );
};

export default FindImage;
