import { expertsDemo } from './DirectoryMock';
import moment from 'moment';
import { authorsDemo } from './CommonsMock';

export const objectsListDemo = [
  {
    id: 'obj1',
    number: '1111',
    type: 'OZ',
    department: 'отдел1',
    name: 'Ясное утро. Берёзовая роща',
    longName: 'ОЖ 8967 Проскурин Ясное утро. Берёзовая роща холст масло 118х78',
    accounting: '7239',
    unit: 'cm',
    width: 100,
    height: 200,
    depth: 300,
    description: 'Описание',
    damage: 'Повреждения',
    comment: 'Примечания',

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },

    author: authorsDemo[0],

    technics: ['Графика', 'Живопись'],
    materials: ['Холст', 'Уголь'],
    statuses: [
      { status: 'В работе', date: moment() },
      { status: 'Отвод', date: moment() },
    ],
    experts: [
      {
        expert: expertsDemo[0],
        date: moment(),
      },
      {
        expert: expertsDemo[1],
        date: moment(),
      },
    ],
    rentgens: [
      { id: 'rentgen1', date: moment(), count: 5 },
      { id: 'rentgen2', date: moment(), count: 2 },
    ],
    rentgenСomparisons: [
      { id: 'rentgenComp1', result: 'Успешно', expert: expertsDemo[0], date: moment() },
      { id: 'rentgenComp2', result: 'Нейтрально', expert: expertsDemo[1], date: moment() },
    ],
    chemicalExpretises: [
      {
        id: '2',
        expert: expertsDemo[0],
        type: 'Тест1',
        date: moment(),
        result: 'Успешно',
      },
      {
        id: '1',
        expert: expertsDemo[1],
        type: 'Тест2',
        date: moment(),
        result: 'Успешно',
      },
    ],
    expretises: [
      {
        id: 'expretise1',
        date: moment(),
        number: '102300',
        expert: expertsDemo[0],
        file: 'https://yandex.ru',
      },
    ],
    photos: [
      {
        id: 'img1',
        image:
          'https://img02.rl0.ru/afisha/-x-i/daily.afisha.ru/uploads/images/b/4d/b4dfea5a34d642938888cb7687f36cee.jpg',
        type: 'Общий вид',
        infr: false,
        date: moment(),
      },
      {
        id: 'img2',
        image:
          'https://img02.rl0.ru/afisha/-x-i/daily.afisha.ru/uploads/images/b/4d/b4dfea5a34d642938888cb7687f36cee.jpg',
        type: 'Общий вид',
        infr: true,
        date: moment(),
      },
    ],
    acceptActs: [{ id: 'acc1', date: moment(), number: 3232 }],
    issuingActs: [{ id: 'iss1', date: moment(), number: 9191 }],
  },
  {
    id: 'obj2',
    number: '2222',
    type: 'Иконопись',
    department: 'отдел1',
    name: 'name2',

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },

    author: authorsDemo[0],
    experts: [
      {
        expert: expertsDemo[0],
        date: moment(),
      },
      {
        expert: expertsDemo[1],
        date: moment(),
      },
    ],
    statuses: [
      { id: 'status1', status: 'В работе', date: moment() },
      { id: 'status2', status: 'Отвод', date: moment() },
    ],
  },
  {
    id: 'obj3',
    number: '3333',
    type: 'Тушь',
    department: 'отдел3',
    name: 'name3',

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },

    author: authorsDemo[0],
    experts: [
      {
        expert: expertsDemo[0],
        date: moment(),
      },
      {
        expert: expertsDemo[1],
        date: moment(),
      },
    ],
    statuses: [
      { id: 'status1', status: 'В работе', date: moment() },
      { id: 'status2', status: 'Отвод', date: moment() },
    ],
  },
  {
    id: 'obj4',
    number: '4444',
    type: 'OZ',
    department: 'отдел1',
    name: 'name4',

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },

    author: authorsDemo[0],
    experts: [
      {
        expert: expertsDemo[0],
        date: moment(),
      },
      {
        expert: expertsDemo[1],
        date: moment(),
      },
    ],
  },
  {
    id: 'obj5',
    number: '5555',
    type: 'Пастель',
    department: 'отдел2',
    name: 'name5',

    client: { id: 'client1', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
    contract: { id: 'contract1', number: '545', date: moment() },

    author: authorsDemo[0],
    experts: [
      {
        expert: expertsDemo[0],
        date: moment(),
      },
      {
        expert: expertsDemo[1],
        date: moment(),
      },
    ],
    statuses: [
      { id: 'status1', status: 'В работе', date: moment() },
      { id: 'status2', status: 'Отвод', date: moment() },
    ],
  },
];
