export const routes = {
  //Expertise
  clientList: '/expertise/clients',
  clients: '/expertise/clients/:id',
  contracts: '/expertise/contracts/:id',
  contractPrint: '/expertise/contracts/:id/print',
  acceptAct: '/expertise/accept-acts/:id',
  acceptActPrint: '/expertise/accept-acts/:id/print',
  acceptActList: '/expertise/accept-acts',
  acceptActNew: '/expertise/accept-acts/new',
  issuingAct: '/expertise/issuing-acts/:id',
  issuingActList: '/expertise/issuing-acts',
  issuingActPrint: '/expertise/issuing-acts/:id/print',
  financialAct: '/expertise/financial-acts/:id',
  financialActList: '/expertise/financial-acts',
  financialActPrint: '/expertise/financial-acts/:id/print',
  findImage: '/expertise/pictures',
  uploadImage: '/expertise/pictures/upload',
  //Objects
  objectSingle: '/object/:id',
  objectPhotoPrint: '/object/:id/photo-print',
  objectXrayPrint: '/object/:id/xray-print',
  objectsList: '/object',
  //Dictionary
  directory: '/dictionary/main',
  priceLists: '/dictionary/prices',
  priceList: '/dictionary/prices/:id',
  //report
  dailyReport: '/report/daily-report',
  expertWork: '/report/expert-work',
  weeklyReport: '/report/weekly-report',
  monthlyReport: '/report/monthly-report',
  rentgenReport: '/report/rentgen-report',
  rentgenComparison: '/report/rentgen-comparison',
  directorReport: '/report/director-report',
  //Roles
  usersList: '/admin/user',
  user: '/admin/user/:id',
  usersRoles: '/admin/user-roles/',
  usersRole: '/admin/user-roles/:roleId',

  login: '/login',
};
