import React, {FC, useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Divider, Form, Row, Space, Table} from 'antd';

import DashboardContainer from '../../components/DashboardContainer';

import {DATE_FORMAT} from '../../constants';
import {Moment} from "moment";
import ReceivingActService from "../../api/ReceivingActService";
import {PagedList} from "../../types/PagedList";
import {ReceivingActType} from "../../types/ReceivingActType";
import {GivingActType} from "../../types/GivingActType";
import GivingActService from "../../api/GivingActService";
import {ContractType} from "../../types/ContractType";
import ResizableTable from "../../components/ResizableTable";

const receivingActColumns = [
  {
    title: 'Акт приема №',
    dataIndex: 'number',
    width: 100,
    render: (number: string, act: ReceivingActType) => number + "/" + act.date.format("YYYY")
  },
  {
    title: 'Клиент',
    dataIndex: 'contract',
    width: 450,
    render: (contract: ContractType) => contract.client.surname + " " + contract.client.name + " " + contract.client.patronymic
  },
  {
    title: 'Учетное обозначение',
    dataIndex: 'objectNumbers',
    width: 450,
    render: (objects: string[]) => objects.join(", ")
  },
];

const givingActColumns = [
  {
    title: 'Акт выдачи №',
    dataIndex: 'number',
    width: 100,
    render: (number: string, act: GivingActType) => number + "/" + act.date.format("YYYY")
  },
  {
    title: 'Клиент',
    dataIndex: 'contract',
    width: 450,
    render: (contract: ContractType) => contract.client.surname + " " + contract.client.name + " " + contract.client.patronymic
  },
  {
    title: 'Учетное обозначение',
    dataIndex: 'objectNumbers',
    width: 450,
    render: (objects: string[]) => objects.join(", ")
  },
];

const DailyReport: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [date, setDate] = useState<Moment|undefined>()
  const [receivingActs, setReceivingActs] = useState<ReceivingActType[]>([])
  const [givingActs, setGivingActs] = useState<GivingActType[]>([])

  useEffect(() => {
    if (date) {
      ReceivingActService.getAll({
        date: date.format("YYYY-MM-DD"),
        withObjectNumbers: true
      }, {pageNumber: 0, size: 10_000, total: 0}).then((actsPage: PagedList<ReceivingActType> ) => {
        setReceivingActs(actsPage.items)
      })
      GivingActService.getAll({
        date: date.format("YYYY-MM-DD"),
        withObjectNumbers: true
      }, {pageNumber: 0, size: 10_000, total: 0}).then((actsPage: PagedList<GivingActType> ) => {
        setGivingActs(actsPage.items)
      })
    }
  }, [date]);

  const openReport = () => {
    form.validateFields().then(() => setDate(form.getFieldsValue().date)).catch((e: any) => {});
  };

  return (
    <DashboardContainer title='Ежедневный отчёт' className='text-align-left'>
      <Row>
        <Col>
          <Form form={form} name='objectForm' layout='horizontal'>
            <Space align='end'>
              <Form.Item
                label='Дата'
                name='date'
                rules={[{ required: true, message: 'Введите дату' }]}>
                <DatePicker className='full-width' format={DATE_FORMAT} />
              </Form.Item>

              <Form.Item>
                <Button type='primary' className='full-width' onClick={openReport}>
                  Открыть отчёт
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Col>
      </Row>
      <Divider />
      <>
        {date ? (
            <>
              <Row>
                <Col span={24}>
                  <Card title='Акты приема' bordered={true}>
                    <ResizableTable
                        rowKey='id'
                        columns={receivingActColumns}
                        dataSource={receivingActs}
                        loading={isLoading}
                        pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24}>
                  <Card title='Акты выдачи' bordered={true}>
                    <ResizableTable
                        rowKey='id'
                        columns={givingActColumns}
                        dataSource={givingActs}
                        loading={isLoading}
                        pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
            </>
        ) : (
            <></>
        )}
      </>
    </DashboardContainer>
  );
};

export default DailyReport;
