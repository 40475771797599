import Api from '../utils/Api';
import {AxiosResponse} from "axios";
import {Permission, RolePermissionListResponse} from "../types/Permission";

class RolePermissionService {
    static async getAll(): Promise<Permission[]> {
        return Api.call(
            '/user-role-permission',
            {
                method: 'GET',
            },
        ).then((response: AxiosResponse<RolePermissionListResponse>) => {
            return response.data;
        });
    }
}

export default RolePermissionService;