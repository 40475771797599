import React, { FC, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import DashboardContainer from '../../components/DashboardContainer';
import EditableTable from '../../widgets/EditableTable';
import TechnicsService, {TechnicsItem, TechnicsListResponse} from '../../api/TechnicsService';
import MaterialsService, {MaterialsItem, MaterialsListResponse} from '../../api/MaterialsService';
import ExpertsService from '../../api/ExpertsService';
import { Expert } from '../../types/Expert';
import { v4 as uuid } from 'uuid';
import {ExpertsListResponse} from "../../types/api/Expert";
import {useSelector} from "react-redux";
import {selectUserState} from "../../redux/user/userGetters";
import AuthService from "../../api/AuthService";

const { TabPane } = Tabs;

const technicColumns = [{ title: 'Техники', dataIndex: 'name', editable: true, type: 'text' }];

const materialColumns = [
  { title: 'Материал', dataIndex: 'name', editable: true, type: 'text' },
];

const expertColumns = [
  { title: 'Фамилия', dataIndex: 'surname', editable: true, type: 'text' },
  { title: 'Имя', dataIndex: 'name', editable: true, type: 'text' },
  { title: 'Отчество', dataIndex: 'patronymic', editable: true, type: 'text' },
];

type TechnicRecord = { id?: string; name: string };
type MaterialRecord = { id?: string; name: string };
type ExpertRecord = { id: string; name: string; surname: string; patronymic: string };

const dataPatterns: any = {
  technics: {
    name: '',
  },
  materials: {
    name: '',
  },
  experts: {
    name: '',
    surname: '',
    patronymic: '',
  },
};

const Directory: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [technics, setTechnics] = useState<any>([]);
  const [materials, setMaterials] = useState<any>([]);
  const [experts, setExperts] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('technics');
  const user = useSelector(selectUserState);

  const editDisabled = !AuthService.checkPermission(user.permissions, "DICTIONARIES", "WRITE")

  const fetchTechnics = () => {
    setIsLoading(true);
    TechnicsService.getAll().then((resp: TechnicsListResponse) => {
      setTechnics(
          resp.map(
              (technicsResponse) => technicsResponse as TechnicsItem,
          )
      );
      setIsLoading(false);
    });
  };

  const fetchMaterials = () => {
    setIsLoading(true);
    MaterialsService.getAll().then((resp: MaterialsListResponse) => {
      setMaterials(
          resp.map(
              (materialsResponse) => materialsResponse as MaterialsItem,
          ),
      );
      setIsLoading(false);
    });
  };

  const fetchExperts = () => {
    setIsLoading(true);
    ExpertsService.getAll({}).then((resp: ExpertsListResponse) => {
      setExperts(
          resp.map(
              (expertResponse: Expert) =>
                  new Object({
                    id: expertResponse.id,
                    name: expertResponse.name,
                    surname: expertResponse.surname,
                    patronymic: expertResponse.patronymic,
                  }),
          ),
      );
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchTechnics()
  }, []);

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    fetchExperts();
  }, []);

  const addTechnicAction = (added: TechnicRecord, record: TechnicRecord) => {
    setIsLoading(true);
    TechnicsService.addTechnic({
      name: added.name,
      id: uuid()
    }).then((response: TechnicsItem) => {
      const technicRecord: TechnicRecord = response;
      setIsLoading(false);
      setTechnics((prevState: any) => [technicRecord, ...prevState]);
    });
  };

  const editTechnicAction = async (edited: TechnicRecord, record: TechnicRecord) => {
    setIsLoading(true);
    await TechnicsService.editTechnic(
      {
        name: edited.name,
        id: record.id,
      },
      record.id,
    ).then((response: TechnicsItem) => {
      const technicRecord: TechnicRecord = response;
      setIsLoading(false);
      setTechnics((prevState: any) =>
        prevState.map((value: any) =>
          value.id === record.id ? { ...record, ...edited, ...technicRecord } : value,
        ),
      );
    });
  };

  const deleteTechnicAction = async (record: any) => {
    setIsLoading(true);
    await TechnicsService.deleteTechnic(record.id).then((response: void) => {
      setIsLoading(false);
      setTechnics((prevState: any) => prevState.filter((value: any) => value.id !== record.id));
    });
  };

  const addMaterialAction = async (added: MaterialRecord, record: MaterialRecord) => {
    setIsLoading(true);
    await MaterialsService.addMaterial({
      id: uuid(),
      name: added.name,
    }).then((response: MaterialsItem) => {
      const materialRecord: MaterialRecord = response;
      setIsLoading(false);
      setMaterials((prevState: any) => [materialRecord, ...prevState]);
    });
  };

  const editMaterialAction = async (edited: MaterialRecord, record: MaterialRecord) => {
    setIsLoading(true);
    await MaterialsService.editMaterial(
      {
        name: edited.name,
        id: record.id,
      },
      record.id,
    ).then((response: MaterialsItem) => {
      const materialRecord: MaterialRecord = response;
      setIsLoading(false);
      setMaterials((prevState: any) =>
        prevState.map((value: any) =>
          value.id === record.id ? { ...record, ...edited, ...materialRecord } : value,
        ),
      );
    });
  };

  const deleteMaterialAction = async (record: MaterialRecord) => {
    setIsLoading(true);
    await MaterialsService.deleteMaterial(record.id).then((response: void) => {
      setIsLoading(false);
      setMaterials((prevState: any) => prevState.filter((value: any) => value.id !== record.id));
    });
  };

  const addExpertsAction = async (added: ExpertRecord, record: ExpertRecord) => {
    setIsLoading(true);
    await ExpertsService.addExpert({
      id: uuid(),
      name: added.name,
      surname: added.surname,
      patronymic: added.patronymic,
    } as Expert).then((response: Expert) => {
      const expertRecord: Expert = {
        id: response.id,
        name: response.name,
        surname: response.surname,
        patronymic: response.patronymic,
      };
      setIsLoading(false);
      setExperts((prevState: any) => [expertRecord, ...prevState]);
    });
  };

  const editExpertsAction = async (edited: ExpertRecord, record: ExpertRecord) => {
    setIsLoading(true);
    await ExpertsService.editExpert(
      {
        id: record.id,
        name: edited.name,
        surname: edited.surname,
        patronymic: edited.patronymic,
      },
      record.id,
    ).then((response: Expert) => {
      const expertRecord: ExpertRecord = {
        id: response.id,
        name: response.name,
        surname: response.surname,
        patronymic: response.patronymic,
      };
      setIsLoading(false);
      setExperts((prevState: any) =>
        prevState.map((value: any) =>
          value.id === record.id ? { ...record, ...edited, ...expertRecord } : value,
        ),
      );
    });
  };

  const deleteExpertsAction = async (record: ExpertRecord) => {
    setIsLoading(true);
    await ExpertsService.deleteExpert(record.id).then((response: void) => {
      setIsLoading(false);
      setExperts((prevState: any) => prevState.filter((value: any) => value.id !== record.id));
    });
  };

  return (
    <DashboardContainer title='Общие справочники'>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={(tab: string) => {
          setActiveTab(tab);
          setActiveKey('');
        }}>
        <TabPane tab='Техники' key='technics'>
          <EditableTable
            disableEdit={editDisabled}
            addable={!editDisabled}
            columns={technicColumns}
            dataSource={technics}
            dataPattern={dataPatterns.technics}
            rowKey={'id'}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            addAction={addTechnicAction}
            editAction={editTechnicAction}
            deleteAction={deleteTechnicAction}
            isLoading={isLoading}
          />
        </TabPane>

        <TabPane tab='Материалы' key='materials'>
          <EditableTable
            disableEdit={editDisabled}
            addable={!editDisabled}
            columns={materialColumns}
            dataSource={materials}
            dataPattern={dataPatterns.materials}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            addAction={addMaterialAction}
            editAction={editMaterialAction}
            deleteAction={deleteMaterialAction}
            isLoading={isLoading}
          />
        </TabPane>

        <TabPane tab='Эксперты' key='experts'>
          <EditableTable
            disableEdit={editDisabled}
            addable={!editDisabled}
            columns={expertColumns}
            dataSource={experts}
            dataPattern={dataPatterns.experts}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            addAction={addExpertsAction}
            editAction={editExpertsAction}
            deleteAction={deleteExpertsAction}
            isLoading={isLoading}
          />
        </TabPane>
      </Tabs>
    </DashboardContainer>
  );
};

export default Directory;
