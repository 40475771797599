import Api from '../utils/Api';
import {ContractType} from '../types/ContractType';
import {
  ContractAddType,
  ContractListResponse,
  ContractSearchType,
  ContractType as APIContractType
} from '../types/api/ContractType';
import moment from 'moment';
import ClientsService from './ClientsService';
import UserService from "./UserService";
import {LocationState} from "../pages/ContractSingle/ContractSingle";
import {PaginationSettings} from "../types/PaginationSettings";
import {PagedList} from "../types/PagedList";
import RequestUtils from "./RequestUtils";

class ContractService {
  static async findBy(searchParams: ContractSearchType, pagination: PaginationSettings): Promise<PagedList<ContractType>> {
    return await Api.call(`/contract/find?page=${pagination.pageNumber}&size=${pagination.size}&` +
      RequestUtils.serialize(searchParams), {
      method: 'GET',
    }).then((response) => {
      return {
        items: response.data.content.map((response: APIContractType) =>
          ContractService.makeContractFromApi(response),
        ),
        page: {
          page: response.data.number + 1,
          pageSize: response.data.size,
          total: response.data.totalElements,
        },
      } as PagedList<ContractType>;
    });
  }

  static async getForClient(clientId: string): Promise<ContractType[]> {
    const response: ContractListResponse = await Api.call(`/client/${clientId}/contract`, {
      method: 'GET',
    }).then((response) => {
      return response.data as ContractListResponse;
    });

    return response.map<ContractType>((response: APIContractType) =>
      ContractService.makeContractFromApi(response),
    );
  }

  static async getById(id: string): Promise<ContractType> {
    const response = await Api.call(`/contract/${id}`, {
      method: 'GET',
    });

    const clientType = ContractService.makeContractFromApi(response.data.contract);
    return {
      ...clientType,
      createdBy: (response.data.createdBy ? UserService.makeUserFromApi(response.data.createdBy) : undefined),
      modifiedBy: (response.data.modifiedBy ? UserService.makeUserFromApi(response.data.modifiedBy) : undefined)
    };
  }

  static async getByIds(ids: string[]): Promise<ContractType[]> {
    const response = await Api.call(`/contract/?ids=${ids.join(',')}`, {
      method: 'GET',
    }).then((response) => {
      return response.data as ContractListResponse;
    });

    return response.map<ContractType>((response: APIContractType) =>
      ContractService.makeContractFromApi(response),
    );
  }

  static async add(contract: ContractAddType): Promise<string> {
    return await Api.call(`/contract`, {
      data: contract,
      method: 'POST',
    }).then((response) => {
      return ContractService.makeContractFromApi(response.data).id
    });
  }

  static async findLastNumber(state: LocationState) {
    return await Api.call(`/contract/last-number?clientId=${state.clientId}&year=${state.year}`, {
      method: 'GET',
    }).then((response) => {
      return response.data as number
    });
  }

  static makeContractFromApi(contract: APIContractType): ContractType {
    return {
      id: contract.id,
      number: contract.number,
      year: contract.year,
      signedAt: moment(contract.signedAt),
      client: contract.client ? ClientsService.makeClientFromApi(contract.client) : undefined,
      modifiedAt: moment(contract.modifiedAt),
      createdAt: moment(contract.createdAt),
    } as ContractType;
  }
}

export default ContractService;
