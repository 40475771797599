import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppEnv} from "../../types/AppEnv";

const initialState: AppEnv = {
  env: "production",
  production: true,
};

const appEnvSlice = createSlice({
  name: 'appEnv',
  initialState,
  reducers: {
    updateEnvReducer: (state: AppEnv, action: PayloadAction<AppEnv>) => {
      console.log('update', action.payload)
      return {
        env: action.payload.env,
        production: action.payload.production
      }
    },
  },
});

export default appEnvSlice;
