import {Permission} from "./Permission";
import {User} from "./User";

export type Role = {
    id: string,
    name: string,
    code: string,
    permissions: Permission[]
};

export type RoleListResponse = Role[]

export const emptyRole: Role = {
    id: "",
    name: "",
    code: "",
    permissions: []
};