import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';

import { DATE_FORMAT } from '../../constants';
import { ArtObject } from '../../types/ArtObject';
import { ArtObjectAuthor } from '../../types/ArtObjectAuthor';
import { ReceivingActType } from '../../types/ReceivingActType';
import { GivingActType } from '../../types/GivingActType';
import { FinanceActType } from '../../types/FinanceActType';
import ArtObjectService from "../../api/ArtObjectService";

export const contractObjectColumns = [
  {
    title: 'Тип',
    dataIndex: 'type',
    type: 'text',
    render: (number: number, artObject: any | ArtObject) => {
      return artObject?.type ? ArtObjectService.getObjectType(artObject.type) : ''
    },
  },
  {
    title: 'Номер',
    dataIndex: 'number',
    sorter: {
      compare: (a: ArtObject, b: ArtObject): number => parseInt(a.number) - parseInt(b.number),
      multiple: 1,
    },
    render: (number: number, artObject: any | ArtObject) => {
      return <Link target="_blank" to={`/object/${artObject.id}`}>{number}</Link>;
    },
    type: 'number',
  },
  {
    title: 'Автор',
    dataIndex: 'author',
    render: (author: any | ArtObjectAuthor) => (author ? author.name : ''),
  },
  {
    title: 'Название Объекта',
    dataIndex: 'name',
    type: 'text',
  },
];

export const contractAcceptActColumns = [
  {
    title: 'Акт',
    dataIndex: 'number',
    render: (number: string, act: ReceivingActType) => (
      <Link target="_blank" to={`/expertise/accept-acts/${act.id}`}>{number}</Link>
    ),
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
];

export const contractIssuingActColumns = [
  {
    title: 'Акт',
    dataIndex: 'number',
    render: (number: string, act: GivingActType) => (
      <Link target="_blank" to={`/expertise/issuing-acts/${act.id}`}>{number}</Link>
    ),
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
];

export const contractFinancialActColumns = [
  {
    title: 'Акт',
    dataIndex: 'number',
    render: (number: string, act: FinanceActType) => (
      <Link target="_blank" to={`/expertise/financial-acts/${act.id}`}>{number}</Link>
    ),
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
];
