import moment, { Moment } from 'moment';
import {ContractType, emptyContract} from "./ContractType";
import {PagedList} from "./PagedList";
import {FinanceActItemType} from "./FinanceActItemType";
import {ArtObject} from "./ArtObject";
import {User} from "./User";

export type FinanceActType = {
  id: string;
  number: string;
  date: Moment;
  remarks: string;
  contract: ContractType;
  items: FinanceActItemType[];
  objects: ArtObject[];
  createdAt?: Moment;
  createdBy?: User;
  modifiedAt?: Moment;
  modifiedBy?: User;
};

export const emptyFinanceAct: FinanceActType = {
  id: '',
  number: '',
  date: moment(),
  remarks: '',
  contract: emptyContract,
  items: [],
  objects: []
};

export const emptyFinanceActPage: PagedList<FinanceActType> = {
  items: [],
  page: {
    page: 1,
    pageSize: 20,
    total: 0,
  },
};
