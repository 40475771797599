import React, {FC, useEffect, useState} from 'react';
import {Col, Row, Space, Table, Typography} from "antd";
// @ts-ignore
import Rubbles from 'rubles';
import {useParams} from "react-router-dom";
import {itemsColumns} from "./Columns";
import FinanceActService from "../../api/FinanceActService";
import {emptyFinanceAct, FinanceActType} from "../../types/FinanceActType";
import {FinanceActItemType} from "../../types/FinanceActItemType";
import ClientsService from "../../api/ClientsService";
import '../../assets/print.scss'
import AuthService from "../../api/AuthService";
import PriceListService from "../../api/PriceListService";
import {PriceListItem} from "../../types/PriceListItem";
import {PriceList} from "../../types/PriceList";
import {DATE_FORMAT} from "../../constants";

const {Title, Text} = Typography;

const FinancialActPrint: FC = () => {
  const {id} = useParams<any>();
  const [act, setAct] = useState<FinanceActType>(emptyFinanceAct);
  const [items, setItems] = useState<FinanceActItemType[]>([]);
  const [sum, setSum] = useState<string>("0.00");

  useEffect(() => {
    Promise.all([
      FinanceActService.getById(id),
      PriceListService.getAll()
    ]).then(([act, priceList]) => {
      const prices = new Map<string, PriceList>()
      priceList.forEach((list) => {
        list.items.forEach(item => prices.set(item.id, list))
      })

      setAct(act)
      setItems(act.items.map((value, index) => {
        const priceList = prices.get(value.priceItem.id)
        const preparedName = `п. ${value.priceItem.number} Тарифы ${priceList?.date.format('YYYY')} г. ${value.name}`

        return {...value, index: index + 1, name: preparedName}
      }))
      const total = act.items.map((value) => value.count * value.price).reduce((left, right) => left + right);
      setSum(total.toFixed(2))
      window.print()
    })
  }, [id]);

  const formatSum = (sum: string): string => {
    const textSum = Rubbles.rubles(sum);
    if (!textSum)
      return ""

    return textSum[0].toUpperCase() + textSum.slice(1)
  }

  return (
    <div className={'print-page'} >
      <Title level={5} style={{
        width: "100%",
        borderBottom: "black 1px solid"
      }}>{`Акт № ${act.number}`} от {act.date.format('DD.MM.yyyy')}</Title>
      <table style={{width: "100%"}}>
        <tbody>
        <tr>
          <td style={{paddingRight: "5px"}}>Исполнитель</td>
          <td><b>Общество с ограниченной ответственностью «Научно- исследовательская независимая экспертиза им.
            П.М.Третьякова»</b>
          </td>
        </tr>
        <tr>
          <td>Заказчик:</td>
          <td>
            <b>{ClientsService.getClientName(act.contract.client)} Договор {act.contract.year}/{act.contract.number} от {act.contract.signedAt.format("DD.MM.yyyy")}</b>
          </td>
        </tr>
        </tbody>
      </table>
      <Space direction="vertical" size={30} style={{width: "100%"}}>
        <Table
          className={"print-table"}
          columns={itemsColumns}
          dataSource={items}
          pagination={false}
        />
        <Title level={5} style={{textAlign: "right"}}>Итого: {sum}</Title>
      </Space>
      <Space direction={"vertical"} size={1} style={{marginTop: 30, width: '100%'}}>
        <Text style={{textAlign: "left"}}>
          Без налога (НДС)
        </Text>
        <Text style={{textAlign: "left"}}>
          Всего оказано услуг {act.items.length} на сумму {sum} руб.
        </Text>
        <Text style={{textAlign: "left"}}>
          {formatSum(sum)}
        </Text>
      </Space>
      <Space direction={"vertical"} style={{marginTop: 30, width: '100%'}}>
        <Text style={{textAlign: "left"}}>
          Вышеперечисленные услуги выполнены полностью. Заказчик претензий по объему, качеству и срокам оказания услуг
          не имеет.
        </Text>
        <div style={{width: '100%', fontSize: 0.1, borderBottom: "black 1px solid"}}>&nbsp;</div>
      </Space>
      <Space direction={"vertical"} size={15} style={{marginTop: 30, width: '100%'}}>
        <Row>
          <Col span={10}>
            <div style={{display: "table-cell", width: "fit-content"}}>Исполнитель</div>
            <div style={{display: "table-cell", width: "100%", borderBottom: "black 1px solid"}}>&nbsp;</div>
          </Col>
          <Col span={10} offset={4}>
            <div style={{display: "table-cell", width: "fit-content"}}>Заказчик</div>
            <div style={{display: "table-cell", width: "100%", borderBottom: "black 1px solid"}}>&nbsp;</div>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default FinancialActPrint;
