import React, {FC, useEffect, useState} from 'react';
import {Row, Space, Typography} from "antd";
import {useParams} from "react-router-dom";
import {ArtObject, ArtObjectType, ArtObjectTypeShort, emptyArtObject} from "../../types/ArtObject";
import ArtObjectService from "../../api/ArtObjectService";

const {Title, Text} = Typography;

const ObjectForPhotoPrint: FC = () => {
  const { id } = useParams<any>();

  const [artObject, setArtObject] = useState<ArtObject | undefined>(undefined);

  useEffect(() => {
    ArtObjectService.getById(id)
      .then((res) => {
        setArtObject(res)

        window.print()
      })
  }, [id])

  function objectNumber(artObject: ArtObject) {
    // @ts-ignore
    return ArtObjectTypeShort[artObject.type].toString();
  }

  return (
    artObject ?
    <div className={'print-page'} style={{fontSize: '40px'}}>
      <Space direction="vertical" size={10} style={{width: "100%"}}>
        <Row>
          <span>{ `${objectNumber(artObject)} ${artObject.number}` }</span>
        </Row>
        <Row>
          <span>{ artObject.author?.name }</span>
        </Row>
        <Row>
          <span>{ artObject.name }</span>
        </Row>
        <Row>
          <span>{ArtObjectService.getObjectTechniques(artObject) + ', ' + ArtObjectService.getObjectMaterials(artObject) + ' ' +
            ArtObjectService.getObjectSizes(artObject)}
          </span>
        </Row>
      </Space>

    </div> : <></>
  )
}

export default ObjectForPhotoPrint;

