import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {defaultPagination, PaginationSettings} from "../types/PaginationSettings";
import RequestUtils from "../api/RequestUtils";
import {Form} from "antd";
import moment from "moment";

export default (url: string, searchAction: ((a: any, settings: PaginationSettings) => any)) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const location = useLocation<any>();
  const [currentPath, setCurrentPath] = useState<string | undefined>(undefined);

  const [pagination, setPagination] = useState<PaginationSettings>(defaultPagination);
  const [searchParams, setSearchParams] = useState<any>({});
  const [page, setPage] = useState<any>(0);
  const [size, setSize] = useState<any>(defaultPagination.size);

  const [init, setInit] = useState(false);

  useEffect(() => {
    setCurrentPath(location.search);
  }, [location]);

  useEffect(() => {
    if (currentPath === undefined)
      return

    const urlLocationParams = new URLSearchParams(currentPath)

    const pageRaw = urlLocationParams.get('page')
    setPage((pageRaw ? parseInt(pageRaw) - 1 : 0))

    const sizeRaw = urlLocationParams.get('size')
    setSize(sizeRaw ? parseInt(sizeRaw) : defaultPagination.size)

    const params = Object.fromEntries(urlLocationParams);
    const baseParams = {...params, page: null};

    setSearchParams({...baseParams})
    form.setFieldsValue({...baseParams, year: params.year ? moment(`${params.year}-01-01`) : undefined})

    setInit(true)
  }, [currentPath]);

  const onSearchChange = async (searchParams: any) => {
    const params = RequestUtils.serialize({...searchParams, page: 1, size: size, year : searchParams.year ? searchParams.year.year() : undefined})

    history.push(`${url}?${params}`);
  };

  const onPaginationChange = async (page: number, size?: number) => {
    const localSize = size ?? pagination.size;
    setPagination({
      pageNumber: page - 1,
      size: localSize,
      total: pagination.total,
    });
    const params = RequestUtils.serialize({...searchParams, page: page, size: localSize})

    history.push(`${url}?${params}`);
  };

  useEffect(() => {
    if (!init)
      return

    const localPagination = {
      pageNumber: page,
      size: size,
      total: pagination.total,
    };

    searchAction(searchParams, localPagination).then();
  }, [searchParams, page]);

  return {onSearchChange, onPaginationChange, history, form}
}

