import Api from "../utils/Api";
import {AppEnv} from "types/AppEnv";
import {updateEnv} from "../redux/appEnv/appEnvActions";

class AppEnvService {
  static async readEnv(): Promise<AppEnv> {
    return Api.systemClient(
      '/app-env',
      {
        method: 'GET',
      },
    ).then((response) => {
      const env = response.data

      return {
        env: env,
        production: env === 'production'
      } as AppEnv;
    });
  }
}

export default AppEnvService;
