import Api from '../utils/Api';
import uuid from "react-uuid";

export type TechnicsItem = {
  id?: string;
  name: string;
};
export type TechnicsListResponse = TechnicsItem[];

class TechnicsService {
  static async getAll(): Promise<TechnicsListResponse> {
    return Api.call('/dictionary/technic', {
      method: 'GET',
    }).then((response) => {
      return response.data as TechnicsListResponse;
    });
  }

  static async addTechnic(technic: TechnicsItem): Promise<TechnicsItem> {
    return Api.call('/dictionary/technic', {
      data: technic,
      method: 'POST',
    }).then((response) => {
      return response.data as TechnicsItem;
    });
  }

  static async editTechnic(technic: TechnicsItem, id?: string): Promise<TechnicsItem> {
    return Api.call('/dictionary/technic/' + id, {
      data: technic,
      method: 'POST',
    }).then((response) => {
      return response.data as TechnicsItem;
    });
  }

  static async deleteTechnic(id?: string): Promise<void> {
    Api.call('/dictionary/technic/' + id, {
      method: 'DELETE',
    });
  }
}

export default TechnicsService;
