import {ArtObjectAuthor} from "../../types/ArtObjectAuthor";
import {ArtObject} from "../../types/ArtObject";
import ArtObjectService from "../../api/ArtObjectService";

const authorTransform = (author: ArtObjectAuthor | null) => {
  return `${author?.name}`;
};

const objectTransform = (artObject: ArtObject) => {
  return ArtObjectService.getObjectDisplayName(artObject);
};

export enum ArtObjectStatusPrintValue {
  ETA = "нет эталонного материала",
  STR = "принято на хранение",
  WOR = "в работе",
  OTV = "отвод",
  EZ = "экспертное заключение"
}

export const objectColumns = [
  {
    title: '№ п/п',
    dataIndex: 'index',
    type: 'number',
    width: 100,
  },
  {
    title: 'Наименование и краткое описание. Материал, техника, размер',
    render: (column: any, artObject: ArtObject) => objectTransform(artObject),
    type: 'text',
    width: 300,
  },
  {
    title: 'Учётное обозн.',
    dataIndex: 'number',
    type: 'text',
    width: 100,
  },
  {
    title: 'Статус',
    render: (column: any, artObject: ArtObject) => {
      const statuses = artObject.statusHistory.sort((a, b) => b.date.unix() - a.date.unix());
      if (statuses.length > 0) {
        // @ts-ignore
        return ArtObjectStatusPrintValue[statuses[0].code].toString()
      } else {
        return ''
      }
    },
    type: 'text',
    width: 50,
  },
];

