import React, {FC, useMemo, useState} from 'react';

import DashboardContainer from '../../components/DashboardContainer';

import {objectListColumns} from './Columns';
import {ArtObject, emptyArtObjectPage} from '../../types/ArtObject';
import ArtObjectService from '../../api/ArtObjectService';
import {PagedList} from '../../types/PagedList';
import {PaginationSettings} from '../../types/PaginationSettings';
import {ContractType} from '../../types/ContractType';
import {Link} from 'react-router-dom';
import {ArtObjectExpertsHistory} from "../../types/ArtObjectExpertsHistory";
import ResizableTable from "../../components/ResizableTable";
import ClientsService from "../../api/ClientsService";
import PageUtils from "../../utils/PageUtils";
import {GivingActShortType} from "../../types/GivingActType";
import {ReceivingActShortType} from "../../types/ReceivingActType";
import ReceivingActService from "../../api/ReceivingActService";
import GivingActService from "../../api/GivingActService";
import ExpertsService from "../../api/ExpertsService";
import Search from "../../widgets/Search/Search";

const searchData = [
  {name: 'number', label: 'Номер объекта', type: 'text'},
  {name: 'authorSurname', label: 'Автор', type: 'text'},
  {name: 'clientSurname', label: 'Клиент', type: 'text'},
  {name: 'expertSurname', label: 'Эксперт', type: 'text'},
  {name: 'name', label: 'Название', type: 'text'},
];

const ObjectList: FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [objects, setObjects] = useState<PagedList<ArtObject>>(emptyArtObjectPage);
  const [givingActs, setGivingActs] = useState<{ [index: string]: GivingActShortType }>({});
  const [receivingActs, setReceivingActs] = useState<{ [index: string]: ReceivingActShortType }>({});

  const fetchActs = async (artObjects: PagedList<ArtObject>) => {
    const objectIds = artObjects.items.map(value => value.id);
    const givingActsAsync = GivingActService.getByObjectIds(objectIds);
    setReceivingActs(await ReceivingActService.getByObjectIds(objectIds))
    setGivingActs(await givingActsAsync)
  }

  const fetchObjects = async (searchParams: any, pagination: PaginationSettings) => {
    setIsLoading(true);

    ArtObjectService.getAll(searchParams, pagination)
      .then(async artObjects => {
        await fetchActs(artObjects)
        setObjects(artObjects);
      })
      .finally(() => setIsLoading(false))
  };

  const {onSearchChange, onPaginationChange, form} = PageUtils("/object", fetchObjects)

  const mergedColumns = useMemo(() => {
    return objectListColumns.map((col: any) => {
      if (col.dataIndex === 'contract') {
        return {
          ...col,
          render: (contract: ContractType) => (
            <Link target="_blank" to={`/expertise/contracts/${contract.id}`}>
              {`${contract.number}/${contract.year}`}
            </Link>
          ),
        };
      } else if (col.dataIndex === 'client') {
        return {
          ...col,
          render: (client: any, artObject: ArtObject) => (
            <Link target="_blank" to={`/expertise/clients/${artObject.contract.client.id}`}>
              {ClientsService.getClientName(artObject.contract.client)}
            </Link>
          ),
        };
      } else if (col.dataIndex === 'experts') {
        return {
          title: 'Эксперты',
          render: (artObject: ArtObject) => {
            const objectExperts = artObject.expertsHistory;
            return objectExperts.length ? (
              <div>
                {objectExperts.map((expert: ArtObjectExpertsHistory) => {
                  return <div
                    key={expert.expert.id}>{ExpertsService.getExpertName(expert.expert)}</div>
                })}
              </div>
              // <>{objectExperts[0].expert.surname + ' ' + objectExperts[0].expert.name[0] + '. ' + objectExperts[0].expert.patronymic[0] + '.'}</>
            ) : (
              <></>
            )
          }
        };
      } else if (col.dataIndex === 'receivingAct') {
        return {
          ...col,
          render: (value: any, artObject: ArtObject) => {
            const act = receivingActs[artObject.id]
            return act ? <Link target="_blank" to={`/expertise/accept-acts/${act.id}`}>{act.number}</Link> : <></>
          }
        };
      } else if (col.dataIndex === 'givingAct') {
        return {
          ...col,
          render: (value: any, artObject: ArtObject) => {
            const act = givingActs[artObject.id]
            return act ? <Link target="_blank" to={`/expertise/issuing-acts/${act.id}`}>{act.number}</Link> : <></>
          }
        };
      } else {
        return col;
      }
    })
  }, [receivingActs, givingActs]);

  return (
    <DashboardContainer title='Объекты экспертизы' style={{textAlign: 'left'}}>
      <Search fields={searchData} action={onSearchChange} form={form}/>
      <ResizableTable
        rowKey='id'
        pagination={{
          pageSize: objects.page.pageSize,
          current: objects.page.page,
          total: objects.page.total,
          onChange: onPaginationChange,
        }}
        columns={mergedColumns}
        dataSource={objects.items}
        loading={isLoading}
      />
    </DashboardContainer>
  );

};


export default ObjectList;
