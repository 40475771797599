import QueryString from 'query-string';
import axios, {AxiosError, AxiosPromise, AxiosRequestConfig} from 'axios';

import LocalStorage from './LocalStorage';
import { STORE_USER_TOKEN } from '../constants';
import Notifications from "./Notifications";

const baseURL = process.env.REACT_APP_BASE_URL || '';

export const paramsSerializer = (params: Record<string, any>) => {
  return QueryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
  });
};

class Api {
  private static client = Api.createClient(baseURL);
  static systemClient = Api.createClient('/');

  static getBaseUrl(): string {
    return baseURL;
  }

  static call = (url: string, config?: AxiosRequestConfig, disableSuccessNotification?: boolean): AxiosPromise => this.client(url, config)
    .catch((error) => {
      Notifications.notifyError(error);
      throw error
    }).then((value) => {
      if (!disableSuccessNotification && config && (config.method == "POST" || config.method == "PUT" || config.method == "DELETE")) {
        Notifications.notifySuccess()
      }
      return value
    });

  static recreateClient() {
    this.client = Api.createClient(baseURL);
  }

  static createClient(clientBaseURL: string | undefined) {
    const client = axios.create({
      baseURL: clientBaseURL,
      paramsSerializer,
      responseType: 'json',
    });

    client.interceptors.request.use(
        (config) => {
          config.headers = {
            'Authorization': 'Bearer ' + LocalStorage.get(STORE_USER_TOKEN),
            // 'Access-Control-Allow-Origin' : '*',
            // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          };

          return config;
        },
        (error) => {
          console.log("AxiosError!!!!!!")
          return Promise.reject(error);
        }
    )

    return client;
  }
}

export default Api;
