import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  Table,
  Input,
  InputNumber,
  Form,
  Col,
  Row,
  Collapse,
  Switch,
  Card,
  Select,
  DatePicker,
  Timeline,
  Button,
  Typography, notification, Empty,
  Modal, Space
} from 'antd';
import MaskedInput from 'antd-mask-input';
import moment, { Moment } from 'moment';
import { clientContractColumns } from '../Columns';
import {DATE_FORMAT, DATE_TIME_FORMAT} from '../../../constants';
import ClientsService from '../../../api/ClientsService';
import ContractService from '../../../api/ContractService';
import { ContractType } from '../../../types/ContractType';
import { ClientType, emptyClient } from '../../../types/ClientType';
import ContractCreation from "./ContractCreation";
import AuthService from "../../../api/AuthService";
import {useSelector} from "react-redux";
import {selectUserState} from "../../../redux/user/userGetters";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const { Title, Text } = Typography;

const contractButtonStyle: CSSProperties = {
  position: 'relative',
  bottom: '48px',
  left: '8px'
};

const contractButtonInnerStyle: CSSProperties = {
  position: 'absolute',
  bottom: '0',
  top: '0'
}

const Client: FC = () => {
  const history = useHistory();
  const { id } = useParams<any>();
  const isNew = id === 'new';
  const [form] = Form.useForm();
  const user = useSelector(selectUserState);

  const [current, setCurrent] = useState<ClientType>(emptyClient);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [contracts, setContracts] = useState<ContractType[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [currentContractId, setCurrentContractId] = useState<string | number>('');
  const [isContractVisible, setIsContractVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isNew || !id) {
      setCurrent(emptyClient);

      return;
    }

    form.resetFields();
    setIsChanged(false);

    setIsLoading(true);
    Promise.all([
      ClientsService.getById(id)
        .then((client: ClientType) => {
          setIsCompany(client.isCompany);
          setCurrent(client);
          form.setFieldsValue(client);
        }),
      ContractService.getForClient(id)
        .then((contracts: ContractType[]) => {
          setContracts(contracts);
          if (contracts.length > 0) {
            setIsContractVisible(true)
          } else {
            setIsContractVisible(false)
          }
        })
    ]).finally(() => setIsLoading(false));
  }, [id, isNew]);

  const onSave = async () => {
    form.validateFields().then(async () => {
      const values = form.getFieldsValue();
      const changedClient: ClientType = { ...current, ...values, "passportIssueDate": values.passportIssueDate?.format("YYYY-MM-DD") };
      const client = await ClientsService.update(changedClient);
      setCurrent(client);
      setIsChanged(false);
    })
  };

  const onAdd = async () => {
    form.validateFields().then(async () => {
      const changedClient: ClientType = { ...form.getFieldsValue(), ...{ id: uuid() } };
      const client = await ClientsService.add(changedClient);
      history.push(`/expertise/clients/${client.id}`);
    })
  };

  return (
    <>
      {!id ? (
        <div className='text-align-left'>
          <Empty description='Клиент не выбран' />
        </div>
      ) : (
        <Form
          className='std-margin-horizontal__minus'
          layout='horizontal'
          form={form}
          requiredMark={false}
          onFieldsChange={() => setIsChanged(true)}>
          <Card loading={isLoading} title={isNew ? 'Контактные данные (новый клиент)' : 'Контактные данные'} bordered={true}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='surname'
                  label='Фамилия'
                  rules={[{ required: true, message: 'Заполните поле' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='name'
                  label='Имя'
                  rules={[{ required: true, message: 'Заполните поле' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='patronymic'
                  label='Отчество'
                  rules={[{ required: true, message: 'Заполните поле' }]}>
                  <Input size='large' />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='phoneNumber'
                  label='Телефон'
                  rules={[{ required: true, message: 'Заполните поле' }]}>
                  <Input size='large' />
                  {/*<MaskedInput mask='+7 (111) 111-11-11' size='large' />*/}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='email'
                  label='E-mail'>
                  <Input size='large' />
                </Form.Item>
              </Col>

              <Col span={12} className='text-align-left'>
                <Form.Item name='isCompany' valuePropName='checked' label='Является юр. лицом'>
                  <Switch
                    onChange={() => {
                      setIsCompany(form.getFieldValue('isCompany'));
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          {!isNew && (
            <>
              <Card loading={isLoading} title='Договоры' bordered={true}>
                {isContractVisible ? <Table
                  className='pos-relative'
                  rowKey='id'
                  columns={[
                    ...clientContractColumns.map((cell: any) =>
                      cell.dataIndex === 'number'
                        ? {
                          ...cell,
                          render: (_: any, record: ContractType) => (
                            <Link target={"_blank"} to={`/expertise/contracts/${record.id}`}>
                              {` ${record.year}/${record.number}`}
                            </Link>
                          ),
                        }
                        : cell,
                    ),
                  ]}
                  dataSource={contracts}
                /> : <Empty description='Нет договоров'/>}
                <div style={contractButtonStyle}>
                  <ContractCreation style={contractButtonInnerStyle} client={current} />
                </div>
              </Card>
            </>
          )}

          {/*<Divider />*/}

          {!isLoading ? <Collapse defaultActiveKey={['1']}>
            <Panel header={`Реквизиты ${isCompany ? 'юридического' : 'частного'} лица`} key='1'>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                      name='addressCountry'
                      label='Страна'
                      rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                      name='addressCity'
                      label='Город'
                      rules={[{ required: true, message: 'Заполните поле' }]}>
                    <Input size='large' />
                  </Form.Item>
                </Col>
              </Row>

              {isCompany ? (
                  <>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                            name='address'
                            label='Фактический адрес'
                            rules={[{ required: true, message: 'Заполните поле' }]}>
                          <TextArea size='large' />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                            name='companyDetails'
                            label='Юридический адрес'
                            rules={[{ required: true, message: 'Заполните поле' }]}>
                          <TextArea size='large' />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                            name='companyName'
                            label='Название юридического лица'
                            rules={[{ required: true, message: 'Заполните поле' }]}>
                          <Input size='large' />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                            name='companyPersonTitle'
                            label='ИНН'
                            rules={[{ required: true, message: 'Заполните поле' }]}>
                          <InputNumber size='large' className='full-width' />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item name='companyPersonTitle2' label='ОГРН'>
                          <InputNumber size='large' className='full-width' />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name='paymentDetails' label='Платежные реквизиты'>
                          <TextArea size='large' />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item name='misc' label='Комментарии'>
                          <TextArea size='large' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
              ) : (
                  <>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                            name='address'
                            label='Адрес'
                            rules={[{ required: true, message: 'Заполните поле' }]}>
                          <TextArea size='large' />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item name='misc' label='Комментарии'>
                          <TextArea size='large' />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Collapse defaultActiveKey={['1']}>
                      <Panel header='Документ' key='1'>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                                name='passportType'
                                label='Тип документа'
                                rules={[{ required: true, message: 'Заполните поле' }]}>
                              <Select
                                  showSearch
                                  placeholder='Тип документа'
                                  optionFilterProp='children'
                                  filterOption={(input, option: any) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }>
                                <Option value='PASSPORT_INTERNAL'>Паспорт РФ</Option>
                                <Option value='OTHER'>Иное</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                                name='passportSeries'
                                label='Серия'
                                rules={[{ required: true, message: 'Заполните поле' }]}>
                              <Input />
                              {/*<MaskedInput mask='1111' size='large' />*/}
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                                name='passportNumber'
                                label='Номер'
                                rules={[{ required: true, message: 'Заполните поле' }]}>
                              <Input />
                              {/*<MaskedInput mask='111111' size='large' />*/}
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                                name='passportIssueDate'
                                label='Дата выдачи'
                                rules={[{ required: true, message: 'Заполните поле' }]}>
                              <DatePicker size='large' style={{ width: '100%' }} format='DD.MM.YYYY' />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                                name='passportIssueBy'
                                label='Кем выдан'
                                rules={[{ required: true, message: 'Заполните поле' }]}>
                              <Input placeholder='Отделом УМФС России...' size='large' />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                                name='passportIssueCode'
                                label='Код подразделения'
                                rules={[{ required: true, message: 'Заполните поле' }]}>
                                <Input />
                              {/*<MaskedInput mask='111-111' size='large' />*/}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </>
              )}
            </Panel>
          </Collapse> : <></>}

          {!isNew && (
            <>
              {/*<Divider />*/}

              <Card title='Информация о клиенте' bordered={true}>
                <Row>
                  <Space>
                    <Text>Кем создано:</Text>
                    <Text>{current.createdBy?.fullName}</Text>
                    <Text code>{current.createdAt?.format(DATE_TIME_FORMAT)}</Text>
                  </Space>
                </Row>
                <Row>
                  <Space>
                    <Text>Кем измененно:</Text>
                    <Text>{current.modifiedBy?.fullName}</Text>
                    <Text code>{current.modifiedAt?.format(DATE_TIME_FORMAT)}</Text>
                  </Space>
                </Row>
              </Card>
            </>
          )}

          {AuthService.checkPermission(user.permissions, "CLIENTS", "WRITE") ?
            <>
              {isChanged && !isNew && (
                <>
                  {/*<Divider />*/}
                  <div className='text-align-right'>
                    <Button type='primary' onClick={() => onSave()}>
                      Сохранить
                    </Button>
                  </div>
                </>
              )}

              {isNew && (
                <>
                  {/*<Divider />*/}
                  <div className='text-align-right'>
                    <Button type='primary' onClick={() => onAdd()}>
                      Добавить
                    </Button>
                  </div>
                </>
              )}
            </> : <></>}
        </Form>
      )}
    </>
  );
};

export default Client;
