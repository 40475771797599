import {ArtObjectExpertsHistory as ApiArtObjectExpertsHistory} from "../types/api/ArtObjectExpertsHistory";
import {ArtObjectExpertsHistory} from "../types/ArtObjectExpertsHistory";
import moment from "moment";
import ExpertsService from "./ExpertsService";


class ArtObjectExpertsHistoryService {
    static makeFromApi(object: ApiArtObjectExpertsHistory): ArtObjectExpertsHistory {
        return {
            id: object.id,
            expert: ExpertsService.makeFromApi(object.expert),
            date: moment(object.date)
        } as ArtObjectExpertsHistory;
    }
}

export default ArtObjectExpertsHistoryService;