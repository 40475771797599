import React, {forwardRef, useEffect, useState} from 'react';
import {
  Input,
  InputNumber,
  Form,
  Select,
  DatePicker,
  Upload,
  Button,
  UploadProps,
  Checkbox,
  Switch,
  AutoComplete
} from 'antd';
import {DATE_FORMAT} from '../../constants';
import {UploadOutlined} from "@ant-design/icons";
import {UploadFile} from "antd/lib/upload/interface";
import {delay} from "@reduxjs/toolkit/dist/utils";

const {Option} = Select;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text' | 'select' | 'selectObject' | 'date' | 'image' | 'checkbox' | 'auto-complete';
  record: any;
  index: number;
  children: React.ReactNode;
  editableRender: any;
  options?: any;
  optionRender?: any;
  filterOption?: any;
  control: boolean;
  handleEditBtn?: any,
  handleConfirmBtn?: any,
  handleCancelBtn?: any,
  onKeyDown?: any,
  empty?: any,
  columnsLength: number,
  dataSourceSize: number,
}

const ExtendedInputNode = forwardRef((props: any, ref: any) => {
  const {
    inputType,
    onKeyDown,
    filterOption,
    showSearch,
    options,
    optionRender,
    record,
    handleConfirmBtn,
    columnsLength,
    ...restProps
  } = props;

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const uploadProps: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  const fileProps = {...restProps, ...uploadProps}

  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(true)
  }, [props])

  const openExtensions = columnsLength === 1 ? {
    open: open,
    onChange: (value: any, value2: any) => {
      setOpen(false)
      restProps?.onChange(value, value2)
      handleConfirmBtn(true)
    },
    autoFocus: true
  } : {}
  const resultProps = {...restProps, ...openExtensions}

  switch (inputType) {
    case 'number':
      return <InputNumber ref={ref} min={0} onKeyDown={onKeyDown} {...restProps}/>;
    case 'text':
      return <Input ref={ref} onKeyDown={onKeyDown} {...restProps}/>;
    case 'checkbox':
      return <Switch ref={ref} {...restProps}/>;
    case 'select':
      return (
        <Select autoFocus={true} ref={ref} showSearch={showSearch} filterOption={filterOption}
                onKeyDown={onKeyDown} {...resultProps}>
          {options.map((value: any, index: any) => (
            <Option value={value} key={index}>
              {optionRender ? optionRender?.(value, record) : value}
            </Option>
          ))}
        </Select>
      );
    case 'date':
      return <DatePicker ref={ref} format={DATE_FORMAT} onKeyDown={onKeyDown} {...restProps}/>;
    case 'image':
      return <Upload ref={ref} name="objectImage" listType="picture" {...fileProps} maxCount={1}>
        <Button icon={<UploadOutlined/>}>Загрузить изображение</Button>
      </Upload>;
    case 'auto-complete':
      return <AutoComplete
        ref={ref}
        className='full-width'
        options={options}
        onKeyDown={onKeyDown}
        filterOption={(inputValue, option: any) =>
          option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        {...restProps}
      />
    default:
      return null;
  }
});

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  editableRender,
  children,
  options,
  optionRender,
  filterOption,
  control,
  handleEditBtn,
  handleConfirmBtn,
  handleCancelBtn,
  onKeyDown,
  empty,
  columnsLength,
  ...restProps
}) => {
  const showSearch = !!filterOption;

  const props: UploadProps = {
    beforeUpload: (file) => {
      return false;
    }
  }

  return (
    <td {...restProps}>
      {editing && !control ? (
          <Form.Item
            name={dataIndex}
            style={{margin: 0}}
            rules={[
              {
                required: true,
                message: `Пожалуйста, введите ${title}!`,
              },
            ]}>
            <ExtendedInputNode
              inputType={inputType}
              onKeyDown={onKeyDown}
              filterOption={filterOption}
              showSearch={showSearch}
              options={options}
              optionRender={optionRender}
              record={record}
              handleConfirmBtn={handleConfirmBtn}
              columnsLength={columnsLength}
            />
          </Form.Item>
        ) :
        control ?
          (empty && !editing ? <></> : children) :
          <div className="editable-cell-value-wrap" onClick={() => handleEditBtn()}>
            {empty ? <></> : children}
          </div>
      }
    </td>
  );
};

export default EditableCell;
