export const technicsDemo = ['Мазки', 'Графика', 'Живопись'];

export const materialsDemo = ['Холст', 'Дерево', 'Фанера'];

export const expertsDemo = [
  { id: 'exp1', surname: 'Иванов', name: 'Иван', patronymic: 'Иванович' },
  { id: 'exp2', surname: 'Сидоров', name: 'Михаил', patronymic: 'Юрьевич' },
  { id: 'exp3', surname: 'Воронина', name: 'Ольга', patronymic: 'Александровна' },
];

export const departmentsDemo = [
  { id: 'dep1', designation: 'ОЖ', name: 'Отдел живописи' },
  { id: 'dep2', designation: 'ОГ', name: 'Отдел графики' },
  { id: 'dep3', designation: 'ОИ', name: 'Отдел иконописи' },
];
