import { createLogger } from 'redux-logger';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import userSlice from './user';
import appEnvSlice from './appEnv';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    appEnv: appEnvSlice.reducer,
  },
  middleware: [
    ...getDefaultMiddleware(),
    createLogger({
      collapsed: true,
      logErrors: true,
    }),
  ],
});

export default store;
