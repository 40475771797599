import moment, { Moment } from 'moment';
import { ArtObject, emptyArtObject } from './ArtObject';

export enum ChemicalTypeValue {
  CNS = "Связующие",
  CON = "Консультация",
  PGM = "Пигменты",
}

export type ExpertiseChemical = {
  id: string;
  date: Moment;
  result: string;
  type: string;
  expertName: string;
  object: ArtObject;
};

export const emptyExpertiseChemical: ExpertiseChemical = {
  id: '',
  date: moment(),
  result: '',
  type: '',
  expertName: '',
  object: emptyArtObject,
};
