import { ArtObjectAuthor, emptyArtObjectAuthor } from './ArtObjectAuthor';
import { PagedList } from './PagedList';
import { ContractType, emptyContract } from './ContractType';
import {MaterialsItem} from "../api/MaterialsService";
import {TechnicsItem} from "../api/TechnicsService";
import {ArtObjectStatus} from "./ArtObjectStatus";
import {ArtObjectExpertsHistory} from "./ArtObjectExpertsHistory";
import {Moment} from "moment/moment";
import {User} from "./User";

export enum ArtObjectType {
  OZ = 'Живопись',
  OG = 'Графика',
  DPI = 'ОДПИ',
  OI = 'Икона',
}

export enum ArtObjectTypeShort {
  OZ = 'ОЖ',
  OG = 'ОГ',
  DPI = 'ОДПИ',
  OI = 'ОИ',
}


export type ArtObject = {
  id: string,
  contract: ContractType,
  number: string,
  type: ArtObjectType | null,
  name: string,
  description: string,
  damages: string,
  remarks: string,
  height: number,
  width: number,
  depth: number,
  displayAuthors: string,
  expertsHistory: ArtObjectExpertsHistory[],
  linkedObject: ArtObject | null,
  author: ArtObjectAuthor | null,
  materials: MaterialsItem[],
  techniques: TechnicsItem[],
  statusHistory: ArtObjectStatus[],
  createdAt?: Moment;
  createdBy?: User;
  modifiedAt?: Moment;
  modifiedBy?: User;
};

export type ArtObjectId = {
  id: string,
}

export const emptyArtObject: ArtObject = {
  id: '',
  contract: emptyContract,
  number: '',
  type: null,
  name: '',
  description: '',
  damages: '',
  remarks: '',
  height: 0,
  width: 0,
  depth: 0,
  displayAuthors: '',
  linkedObject: null,
  author: emptyArtObjectAuthor,
  expertsHistory: [],
  materials: [],
  techniques: [],
  statusHistory: [],
};

export const emptyArtObjectPage: PagedList<ArtObject> = {
  items: [],
  page: {
    page: 1,
    pageSize: 20,
    total: 0,
  },
};
