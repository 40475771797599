import {Moment} from "moment";
import moment from "moment/moment";

export enum ArtObjectStatusValue {
    ETA = "Нет эталонного материала",
    STR = "Принято на хранение",
    WOR = "В работе",
    OTV = "Отвод",
    EZ = "ЭЗ"
}

export type ArtObjectStatus = {
    id: string,
    code: string,
    date: Moment,
    artObjectId?: string
};

export const emptyArtObjectStatus: ArtObjectStatus = {
    id: '',
    code: '',
    date: moment(),
    artObjectId: ''
};
