import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';

import { DATE_FORMAT } from '../../constants';

export const clientListColumns = [
  {
    title: 'Фамилия',
    dataIndex: 'surname',
  },
  {
    title: 'Имя',
    dataIndex: 'name',
  },
  {
    title: 'Отчество',
    dataIndex: 'patronymic',
    render: (patronymic: string) => (patronymic ? patronymic : ''),
  },
  {
    title: 'Контактный телефон',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
  },
];

export const clientContractColumns = [
  {
    title: 'Договор',
    dataIndex: 'number',
    render: (number: string) => <Link to={`/#`}>{number}</Link>,
  },
  {
    title: 'Дата',
    dataIndex: 'signedAt',
    render: (date: Moment) => date.format(DATE_FORMAT),
  },
];
