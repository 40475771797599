import 'reset-css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'moment/locale/ru';
import ruRU from 'antd/lib/locale/ru_RU';

import 'antd/dist/antd.min.css';
import './assets/styles.scss';

import store from './redux/';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import App from './components/App';

const root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider locale={ruRU}>
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  root,
);

serviceWorker.unregister();
reportWebVitals();
